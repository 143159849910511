/**
 *
 * @file NotFound.js
 * @created_date Thursday, December 22, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @description This component is 404 errors, when the user try to reach url which not exist will redirect to this page.
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @module NotFound
 *
 **/
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link>
  </div>
);

export default NotFound;
