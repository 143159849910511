/**
 * @file WorkflowLibraryNavItem.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component represents an individual item in the navigation bar of the workflow library. It consists of a list item with an associated label, and its appearance can be dynamically adjusted based on whether it is active or being hovered over. This component is used by the WorkflowLibraryNav component to create a list of selectable navigation items.
 *<br/>Breakdown of the code - {@tutorial WorkflowLibraryNavItem}
 * @module WorkflowLibraryNavItem
 *
 **/
import React from "react";
import { ElementLayoutHelpers } from 'helper-functions-package';


class WorkflowLibraryNavItem extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hover: false,
      activeItem: props.activeItem,
      isOverflowing: false
    };
    this.workflowLibraryItemHeadline = React.createRef();
  }

  componentDidMount() {
    this.setState({
      isOverflowing: ElementLayoutHelpers.checkOverflow(
        this.workflowLibraryItemHeadline
      ),
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeItem !== this.props.activeItem) {
      this.setState({ activeItem: this.props.activeItem });
    }
  }

  render() {
    let itemIcon = "workflow-library-item-list-icon"
    return (
      <li
        className={`workflow-library-nav-item-block workflow-library-nav-item-block_transition ${this.state.activeItem ? "active" : ""
          }`}
        onMouseOver={() => {
          this.setState({ hover: true });
        }}
        onMouseOut={() => {
          this.setState({ hover: false });
        }}
        onClick={() => {
          this.props.onClick();
        }}
      >
        {this.state.isOverflowing && this.state.hover ? (

          <span className="workflow-library-item-tooltip">{this.props.label}</span>
        ) : ('')}
        <span
          className={`${itemIcon} ${this.state.activeItem && !this.state.hover ? "active" : ""
            }`}
        ></span>
        <span
          className={`workflow-library-item-headline ${this.state.activeItem && !this.state.hover ? "active" : ""
            }`}
          ref={this.workflowLibraryItemHeadline}
        >
          {this.props.label}
        </span>
      </li>
    );
  }
}

export default WorkflowLibraryNavItem;
