/**
 *
 * @file PersongroupService.js
 * @created_date Friday, Feb 29, 2024
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The provided JavaScript file de
 * <br/>Example use age of code - {@tutorial PersongroupService}
 * @module PersongroupService
 *
 **/

import { MiscFunctions } from 'helper-functions-package';

/** Class for handling the PersongroupService. */
class PersongroupService {
    constructor(json) {
        Object.keys(json).map((key) => {
            this[key] = json[key];
        });
    }
    //

    //
    /**
     * @description Getter-Function to retrieve the ID. Returns the ID of the resource.
     * @return {id}.
     */
    get getID() {
        return !MiscFunctions.isUndefined(this.id.id) ? this.id.id : this.id;
    }

    get getDescription() {
        return !MiscFunctions.isUndefined(this.description) ? this.description : null;
    }

    get getLabel() {
        return !MiscFunctions.isUndefined(this.label) ? this.label : null;
    }
}

export default PersongroupService;
