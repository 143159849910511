/**
 *
 * @file EmailExist.js
 * @created_date Wednesday, January 18, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This is a React functional component called EmailExist, which represents a message displayed when an email already exists in the system during a password reset process.
 * <br/>Breakdown of the code - {@tutorial EmailExist}
 * @module EmailExist
 *
 **/

import React from "react";
import { useTranslation } from "react-i18next";

// This method is for reseting password.
function EmailExist(props) {
  const { t } = useTranslation();

  return (
    <>
      <h4
        className="text-xl font-medium text-gray-900 dark:text-white text-center"
        id="label_login_header"
      >
        {t('messages.email_alrealy_exist')}
      </h4>
      <h5
        className="text-xl font-medium text-gray-900 dark:text-white text-center"
        id="label_sign-up_description"
      >
        <span className="boldOnly">{t('login_register_page.account_already_exist')}.</span>
      </h5>
      <div className="reset_passwor_modal_content">
        <p id="label_verfication_state-description">
          {t('login_register_page.account_already_exist_message')}
        </p>
      </div>
    </>
  );
}

export default EmailExist;
