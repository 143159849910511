const TOKEN_KEY = "auth_access_obejct";
// const REFRESH_TOKEN_KEY = 'refresh_token';

export const getAuthAccessObject = () => JSON.parse(localStorage.getItem(TOKEN_KEY));
export const setAuthAccessObject = (token) => localStorage.setItem(TOKEN_KEY, token);
export const removeAuthAccessObject = () => localStorage.removeItem(TOKEN_KEY);

// export const getRefreshToken = () => JSON.parse(localStorage.getItem(REFRESH_TOKEN_KEY));
// export const setRefreshToken = (token) => localStorage.setItem(REFRESH_TOKEN_KEY, token);
// export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_KEY);
