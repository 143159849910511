/**
 * @file Keyword_InputField.js
 * @created_date Saturday, August 19, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description The code defines a React component called Keyword_InputField that represents an input field for keywords.
 * @module Keyword_InputField
 *
 **/

import React from "react";

class SearchField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // showHideSummaryBlock: false,
      // hier können Zustandsvariablen definiert werden
      icon_select: false,
      inputValue: '',
    };
  }

  // componentDidUpdate(prevProps, prevStates) {
  //   if (prevStates.icon_select !== this.state.icon_select) {
  //     console.log(this.state.icon_select);
  //     this.setState({ icon_select: this.state.icon_select });
  //   }
  // }

  onChangeInput(input) {
    this.setState({ inputValue: input });
  }

  render() {
    return (
      <div className="sidebar-search-box">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="relative">
            <div
              className={`absolute inset-y-0 ${this.props.iconDirection}-0 flex items-center pointer-events-none`}
              style={
                this.props.iconDirection === "right"
                  ? { marginRight: "7px" }
                  : { marginLeft: "7px" }
              }
            >
              <span
                className={
                  this.state.icon_select
                    ? "search_icon_box_select"
                    : "search_icon_box"
                }
              ></span>
            </div>
            <input
              type="search"
              id="search"
              className="w-full p-2 text-sm text-gray-900 border border-none border-round-0 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              style={
                this.props.iconDirection === "right"
                  ? { paddingRight: "30px" }
                  : { paddingLeft: "30px" }
              }
              value={this.state.inputValue}
              // onKeyUp={(e) => {
              //   this.props.stateHandler(e.target.value);
              // }}
              onFocus={() => {
                this.setState({ icon_select: true });
              }}
              onBlur={() => {
                this.setState({ icon_select: false });
              }}
              onChange={(e) => {
                this.props.stateHandler(e.target.value);
                this.onChangeInput(e.target.value)
              }}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default SearchField;
