/**
 * @file SegmentInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This component manages input and manipulation of phase and task data in a project management system. It handles user interactions, supports media uploads, and dynamically adapts its UI based on the segment type and mode (change or insert). The component demonstrates modularization for enhanced maintainability and features a user-friendly interface for entering text, selecting options, and uploading media. Overall, `PhaseInput` serves as a versatile input tool for creating and modifying workflow segments.
 *<br/>Breakdown of the code - {@tutorial SegmentInput}
 * @module SegmentInput
 *
 **/

import React from "react";
import Segment from "../../services/SegmentServices";
import TimeStamp from "../../services/TimeStampServices";
import segmentAPI from "../../api/segmentAPI";
import timestampAPI from "../../api/timestampAPI";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";

import { MiscFunctions } from 'helper-functions-package';
import StringInput from "./StringInput";
import ListInput from "./ListInput";
import TextInput from "./TextInput";
import MediaUploadUI from "./MediaUploadUI";
import ressourceAPI from "../../api/ressourceAPI";
import { Card } from "flowbite-react";
import Ressources from "../../services/RessourcesServices";
import Ressource from "../../services/RessourceServices";
import { withTranslation } from "react-i18next";
import ToastMessages from "../../helpers/ToastMessages";

class PhaseInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      name: "",
      description: "",
      anatomical: "",
      start: "",
      end: "",
      sterility: null,
      parent: null,
      thumbnail: null,
      picture: null,
      segment: null,
      segments: null,
      mode: "",
      timestamp: null,
      level: null,
      parentLevel: null,
      persons: null,
      person: null,
      rooms: null,
      room: null,
      tools: null,
      tool: null,
      equipments: null,
      equipment: null,
      pharmaceuticals: null,
      pharmaceutical: null,
      sendSuccess: null,
      saveData: false,
      responseText: "",
      uploadedFileName: "",
      swithElement: false,
    };

    this.availLevels = this.getLevels(this.props.segmenttype);
  }

  emptyValue = {
    label: "",
    tag: "empty",
  };

  handlePicture = (elem) => {
    this.setState({ picture: elem });
  };
  handleThumbnail = (elem) => {
    this.setState({ thumbnail: elem });
  };
  handleParent = (elem) => {
    this.setState({ parent: elem });
  };
  handleName = (string) => {
    this.setState({ name: string });
  };
  handleAnatomical = (string) => {
    this.setState({ anatomical: string });
  };
  handleSterility = (elem) => {
    this.setState({ sterility: elem });
  };
  handleDescription = (string) => {
    this.setState({ description: string });
  };
  handleStart = (string) => {
    this.setState({ start: string });
  };
  handleEnd = (string) => {
    this.setState({ end: string });
  };
  handleSegment = (elem) => {
    this.setState({
      segment: elem,
      tool: null,
      equipment: null,
      pharmaceutical: null
    });
  };
  handleLevel = (elem) => {
    this.setState({ 
      level: elem.tag,
      sterility: null
     });
  };
  handleResponsetext = (string) => {
    this.setState({ responseText: string });
  }
  handlePerson = (elem) => {
    this.setState({ person: elem });
  };
  handleTool = (elem) => {
    this.setState({ tool: elem });
  };
  handleEquipment = (elem) => {
    this.setState({ equipment: elem });
  };
  handlePharmaceutical = (elem) => {
    this.setState({ pharmaceutical: elem });
  };
  handleRoom = (elem) => {
    this.setState({ room: elem });
  };

  handleSendSuccess = (e) => {
    this.setState({ sendSuccess: e });
  };

  getLevels = (segmenttype) => {
    let levels = [];
    switch (segmenttype) {
      case "TaskDto":
        levels = [
          { label: this.props.t('general.task'), tag: "taskgroup" },
          { label: this.props.t('general.subtask'), tag: "maintask" },
        ];
        break;
      default:
        levels = [
          { label: this.props.t('general.phase'), tag: "phase" },
          { label: this.props.t('general.subphase'), tag: "subphase" },
        ];
    }
    return levels;
  };

  getParentLevel = (segmenttype, level) => {
    let Parentlevel = "";
    switch (segmenttype) {
      case "TaskDto":
        switch (level) {
          case "maintask":
            Parentlevel = "taskgroup";
            break;
          case "subtask":
            Parentlevel = "maintask";
            break;
        }
        break;
      default:
        Parentlevel = "phase";
    }
    return Parentlevel;
  };

  handleSubmit = async () => {
    console.log(this.state.parent);
    let name = this.state.name;
    let start = MiscFunctions.DateString2Seconds(this.state.start);
    let end = MiscFunctions.DateString2Seconds(this.state.end);

    let process = null;
    if (this.state.mode === "insert") {
      process = new Segment(name, start, end, this.props.workflow.getVideoID);
    } else {
      console.log(this.state.segment);
      process = new Segment();
      process.setLabel = this.state.name;
      process.timestamp = null;
      process.setDuration = end - start;
      process.setID = this.state.segment.getID;
      process.setVideo = this.props.workflow.getVideoID;
    }

    process.setLevel = this.props.segmenttype === "PhaseDto" ? "phase" : this.state.level;
    process.setType = this.props.segmenttype;

    if (this.props.segmenttype === "TaskDto" && this.state.level !== "taskgroup") {
      process.setAnatomical = this.state.anatomical;
      process.setSterility = this.state.sterility.tag;
    }
    // Add Person to the task
    if (this.props.segmenttype === "TaskDto" && !MiscFunctions.isNull(this.state.person)) {
      process.addRessource = this.state.person;
    }
    // Add Tool to the task
    if (this.props.segmenttype === "TaskDto") {
      if (!MiscFunctions.isNull(this.state.tool)) {
        process.addRessource = this.state.tool;
      }
      if (!MiscFunctions.isNull(this.state.equipment)) {
        process.addRessource = this.state.equipment;
      }
      if (!MiscFunctions.isNull(this.state.pharmaceutical)) {
        process.addRessource = this.state.pharmaceutical;
      }
    }

    // Add Room to the task
    if (this.props.segmenttype === "TaskDto" && !MiscFunctions.isNull(this.state.room)) {
      process.addRessource = this.state.room;
    }

    if (!MiscFunctions.isNull(this.state.picture)) {
      process.setPicture = this.state.picture.url;
    } else if (MiscFunctions.isNull(this.state.picture) && this.state.mode === "insert") {
      process.setPicture = "logo_mediMESH_original_medium.png";
    }

    if (!MiscFunctions.isNull(this.state.thumbnail)) {
      process.setThumbnail = this.state.thumbnail.url;
    } else if (MiscFunctions.isNull(this.state.thumbnail) && this.state.mode === "insert") {
      process.setThumbnail = "logo_mediMESH_original_medium.png";
    }

    process.setDescription = this.state.description;
    if (!MiscFunctions.isNull(this.state.parent)) {
      process.setParent = this.state.parent;
    }

    if (this.state.mode === "insert") {
      this.setState({ saveData: true });
      const res = await segmentAPI.postSegment({
        segment: process.json,
        timestamp: process.getTimestamp,
      });
    if(!MiscFunctions.isUndefined(res)){
      if (res.status === 201) {
        this.setState({
          saveData: false,
          name: "",
          description: "",
          anatomical: "",
          start: "",
          end: "",
          sterility: null,
          parent: null,
          thumbnail: null,
          picture: null,
          segment: null,
          timestamp: null,
          level: null,
          parentLevel: null,
          person: null,
          room: null,
          tool: null,
          equipment: null,
          pharmaceutical: null,
          sendSuccess: true,
          responseText: "Object successfully created.",
        });
        this.props.UpdateSegments();
      ToastMessages.successMessage("Object successfully created.");
      }else {
        this.handleSendSuccess(false);
        if (res.status === 409) {
          this.handleResponsetext("This Object already exists.");
          ToastMessages.erorrMessage("This Object already exists.");

        } else {
          this.handleResponsetext("Something went wrong creating the object.");
          ToastMessages.erorrMessage("Something went wrong creating the object.");
        }
      }
    }
    }
    if (this.state.mode === "change") {
      console.log(process);
      console.log(process.json);
      const tres = timestampAPI.postTimestamp(this.props.captchaValue,
        {
          starttime: MiscFunctions.DateString2Seconds(this.state.start)
        });
      tres
        .then((response) => {
          process.timestamp = { id: response.id.id, tag: "datetimestamp" };
          const res = segmentAPI.changeSegment(
            this.props.captchaValue,
            process.json
          );
          res
            .then((data) => {
              if (!MiscFunctions.isUndefined(data)) {
                this.setState({
                  saveData: false,
                  name: "",
                  description: "",
                  anatomical: "",
                  start: "",
                  end: "",
                  sterility: null,
                  parent: null,
                  thumbnail: null,
                  picture: null,
                  segment: null,
                  timestamp: null,
                  level: null,
                  parentLevel: null,
                  room: null,
                  tool: null,
                  equipment: null,
                  pharmaceutical: null,
                  sendSuccess: true,
                  responseText: "Object successfully created.",
                });
                this.props.UpdateSegments();
              } else {
                this.handleSendSuccess(false);
              }
            });
        });
    }
  };


  onClickOutsideListener = () => { };

  getWorkflowRessource = async(id) => {
    if(this.props.segmenttype === "TaskDto"){
    const data = await ressourceAPI.getRessources(id);
    let dummy = data;
    let resources = new Ressources(dummy);
    let persons = resources.getPersons;
    persons.push(this.emptyValue);
    let tools = resources.getTools;
    tools.push(this.emptyValue);
    let equipments = resources.getEquipment;
    equipments.push(this.emptyValue);
    let pharmaceuticals = resources.getPharmaceutical;
    pharmaceuticals.push(this.emptyValue);
    let rooms = resources.getRooms;
    rooms.push(this.emptyValue);
    this.setState({
      persons: persons,
      tools: tools,
      equipments: equipments,
      pharmaceuticals: pharmaceuticals,
      rooms: rooms,
    });
    }
  }
  componentDidMount() {
    this.getWorkflowRessource(this.props.workflow.getVideoID);
    // const res = segmentAPI.getSegmentByVideoID(this.props.workflow.getVideoID);
    // res.then(async (response) => {
    //   let Phases = new Segments(JSON.parse(await response));
    //   console.log(Phases)
    // })
  }

  setSegmentWithRessource = async(id) => {
    const dataSegment = await segmentAPI.getSegmentByID(id);
    let segment = new Segment();
    segment.assignData = dataSegment;
    const dataTime = await timestampAPI.getTimestampByID(segment.timestamp.id);
    let timestamp = new TimeStamp(dataTime);
    this.setState({
      timestamp: timestamp.getTimeStampString,
      start: timestamp.getTimeStampString,
      end: MiscFunctions.secondsMinuteFormat(
        timestamp.getTimeStampSeconds + segment.getDuration
      ),
    });
    if (segment.segmenttype === "TaskDto") {
      Promise.all(segment.ressources.map(async(elem) => {
        const dataRessource = await ressourceAPI.getRessourcesByID(elem.id);
        let resource = new Ressource(dataRessource);
        if (resource.getReferenceType === "PersonDto") {
          console.log("Change State");
          this.setState({
            person: resource,
          });
        }
        if (resource.getReferenceType === "ToolDto") {
          console.log("Change State");
          if (resource.getReferenceCategory === "tool") {
            this.setState({
              tool: resource
            });
          }
          if (resource.getReferenceCategory === "equipment") {
            this.setState({
              equipment: resource
            });
          }
          if (resource.getReferenceCategory === "pharmaceutical") {
            this.setState({
              pharmaceutical: resource
            });
          }

        }
        if (resource.getReferenceType === "RoomDto") {
          console.log("Change State");
          this.setState({
            room: resource,
          });
        }
      }))
    }
    if (segment.getLevel !== "taskgroup" && segment.getLevel !== "phase") {
      this.setState({
        parent: this.props.segments.findParentById(segment.parent.id),
        name: segment.getLabel,
        description: segment.getDescription,
        level: segment.getLevel,
        sterility: MiscFunctions.isUndefined(segment.getSterility) ? null : segment.getSterility ? { "label": "Steril", "tag": "Steril" } : { "label": "Nicht Steril", "tag": "Nicht Steril" },
        anatomical: segment.getAnatomical
      });
    } else {
      this.setState({
        name: segment.getLabel,
        description: segment.getDescription,
        level: segment.getLevel,
        sterility: MiscFunctions.isUndefined(segment.getSterility) ? null : segment.getSterility ? { "label": "Steril", "tag": "Steril" } : { "label": "Nicht Steril", "tag": "Nicht Steril" },
        anatomical: segment.getAnatomical
      });
    }
  }

  onChangeFileName = (value) => {
    this.setState({uploadedFileName: value})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.segment !== this.state.segment && !MiscFunctions.isNull(this.state.segment)) {
      this.setSegmentWithRessource(this.state.segment.getID);
    }
    if (
      prevState.mode !== this.state.mode ||
      prevState.person !== this.state.person
    ) {
      if (this.props.segmenttype === "PhaseDto") {
        this.setState({
          segments: this.props.segments,
        });
      } else if (this.props.segmenttype === "TaskDto") {
        if (
          this.state.mode === "change" &&
          prevState.person !== this.state.person
        ) {
          this.setState({
            segments: this.props.segments.getPersonTasks(this.state.person),
          });
        } else if (this.state.mode === "insert") {
          this.setState({
            segments: this.props.segments,
          });
        }
      }
    }
    if (
      prevState.level !== this.state.level ||
      prevProps.segmenttype !== this.props.segmenttype ||
      prevState.person !== this.state.person
    ) {
      if (!MiscFunctions.isNull(this.state.person) && !MiscFunctions.isUndefined(this.props.segments)) {
        this.setState({
          parentLevel: this.props.segments
            .getPersonTasks(this.state.person)
            .getElementsOfLevel(
              this.getParentLevel(this.props.segmenttype, this.state.level)
            ).Elements,
        });
      }
    }

    if (
      prevState.description !== this.state.description ||
      prevState.start !== this.state.start ||
      prevState.end !== this.state.end ||
      prevState.parent !== this.state.parent ||
      prevState.thumbnail !== this.state.thumbnail ||
      prevState.picture !== this.state.picture ||
      prevState.segment !== this.state.segment ||
      prevState.mode !== this.state.mode ||
      prevState.level !== this.state.level ||
      prevState.person !== this.state.person ||
      prevState.room !== this.state.room ||
      prevState.tool !== this.state.tool
    ) {
      //console.log(this.state.SendSuccess);
      this.setState({ SendSuccess: false });
    }

    // console.log("Segments: ")
    // console.log(this.state.segments)
    // console.log("Mode: " + this.state.mode)
    // console.log("Type: " + this.props.segmenttype)
  }
  componentWillUnmount() { }

  render() {
    return (
      <Card className="max-w-full mt-2">
        <div className="">
          {this.state.mode === "" && (
            <div className="flex flex-row mb-5">
              <div
                class="button_sign_up"
                style={{ minWidth: "200px", margin: "4px" }}
              >
                <button
                  onClick={() => {
                    this.setState({ mode: "change" });
                  }}
                  className="w-full text-white bg-hightlight-700 hover:bg-hightlight-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700 control_button_sign_in_up"
                  disabled={MiscFunctions.isUndefined(this.props.segments) ? true : false}
                >
                  {this.props.t('general.change')}
                </button>
              </div>
              <div
                class="button_sign_up"
                style={{ minWidth: "200px", margin: "4px" }}
              >
                <button
                  onClick={() => {
                    this.setState({ mode: "insert" });
                  }}
                  className="w-full text-white bg-hightlight-700 hover:bg-hightlight-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700 control_button_sign_in_up"
                >
                  {this.props.t('general.create_new')}
                </button>
              </div>
            </div>
          )}

          {this.state.mode !== "" && (
            <div>
              <div className="flex flex-row mb-5">
                <div className="input_label mr-6">
                  {this.props.segmenttype === "PhaseDto"
                    ? this.props.t('general.phase')
                    : this.props.t('general.task') + "-Object"}
                </div>
              </div>
              <div
                className="flex flex-row mb-5"
                style={{
                  "justify-content": "space-between",
                  "align-items": "center",
                }}
              >
                <ListInput
                  label={
                    this.state.mode === "insert"
                      ? this.props.t('general.template')
                      : this.props.segmenttype === "PhaseDto"
                        ? this.props.t('general.phase')
                        : this.props.t('general.task')
                  }
                  Elements={
                    !MiscFunctions.isNull(this.state.segments) && !MiscFunctions.isUndefined(this.state.segments)
                      ? this.state.segments.Elements
                      : null
                  }
                  selectHandler={this.handleSegment}
                  Reset={this.state.reset}
                  preSelection={
                    !MiscFunctions.isNull(this.state.segment)
                      ? this.state.segment
                      : !MiscFunctions.isNull(this.state.segments)
                        ? this.emptyValue
                        : { label: this.props.t('messages.please_selecte_person'), tag: "default" }
                  }
                  Ready={true}
                />
                {this.props.segmenttype === "TaskDto" && (
                  <ListInput
                    label={this.props.t('general.level')}
                    Elements={this.availLevels}
                    selectHandler={this.handleLevel}
                    preSelection={
                      !MiscFunctions.isNull(this.state.level)
                        ? this.availLevels.find((elem) => {
                          if (this.state.level === elem.tag) {
                            return elem;
                          }
                        })
                        : this.emptyValue
                    }
                    Ready={!MiscFunctions.isUndefined(this.availLevels)}
                  />
                )}
              </div>
              <StringInput
                label={this.props.t('general.name')}
                setValue={this.handleName}
                value={this.state.name}
              />
              {this.props.segmenttype === "TaskDto" && !MiscFunctions.isNull(this.state.level) && this.state.level !== "taskgroup" && (
                <StringInput
                  label={this.props.t('general.anatomical_region')}
                  setValue={this.handleAnatomical}
                  value={this.state.anatomical}
                />)}
              {this.props.segmenttype === "TaskDto" && !MiscFunctions.isNull(this.state.level) && (
                <ListInput
                  label={this.props.t('general.sterility')}
                  Elements={[{ "label": this.props.t('general.steril'), "tag": "Steril" },
                  { "label": this.props.t('general.not_steril'), "tag": "Nicht Steril" }]}
                  selectHandler={this.handleSterility}
                  preSelection={!MiscFunctions.isNull(this.state.sterility) ? this.state.sterility : this.emptyValue}
                  Ready={true}
                />)}
              <TextInput
                label={this.props.t('general.description')}
                rows="9"
                setValue={this.handleDescription}
                value={this.state.description}
              />
              <div className="flex flex-row">
                <StringInput
                  label={this.props.t('general.start')}
                  setValue={this.handleStart}
                  value={this.state.start}
                />
                <StringInput
                  label={this.props.t('general.end')}
                  setValue={this.handleEnd}
                  value={this.state.end}
                />
              </div>

              {this.props.segmenttype !== "PhaseDto" && (
                <ListInput
                  label={this.props.t('general.person')}
                  Elements={this.state.persons}
                  selectHandler={this.handlePerson}
                  preSelection={
                    !MiscFunctions.isNull(this.state.person)
                      ? this.state.person
                      : this.emptyValue
                  }
                  Ready={!MiscFunctions.isNull(this.state.persons)}
                />
              )}
              {this.props.segmenttype !== "PhaseDto" && (
                <ListInput
                  label={this.props.t('general.equipment')}
                  Elements={this.state.equipments}
                  selectHandler={this.handleEquipment}
                  preSelection={
                    !MiscFunctions.isNull(this.state.equipment) ? this.state.equipment : this.emptyValue
                  }
                  Ready={!MiscFunctions.isNull(this.state.equipments)}
                />
              )}
              {this.props.segmenttype !== "PhaseDto" && (
                <ListInput
                  label={this.props.t('general.tool')}
                  Elements={this.state.tools}
                  selectHandler={this.handleTool}
                  preSelection={
                    !MiscFunctions.isNull(this.state.tool) ? this.state.tool : this.emptyValue
                  }
                  Ready={!MiscFunctions.isNull(this.state.tools)}
                />
              )}
              {this.props.segmenttype !== "PhaseDto" && (
                <ListInput
                  label={this.props.t('general.pharmaceuticals')}
                  Elements={this.state.pharmaceuticals}
                  selectHandler={this.handlePharmaceutical}
                  preSelection={
                    !MiscFunctions.isNull(this.state.pharmaceutical) ? this.state.pharmaceutical : this.emptyValue
                  }
                  Ready={!MiscFunctions.isNull(this.state.pharmaceuticals)}
                />
              )}
              {this.props.segmenttype !== "PhaseDto" && (
                <ListInput
                  label={this.props.t('general.room')}
                  Elements={this.state.rooms}
                  selectHandler={this.handleRoom}
                  preSelection={
                    !MiscFunctions.isNull(this.state.room) ? this.state.room : this.emptyValue
                  }
                  Ready={!MiscFunctions.isNull(this.state.rooms)}
                />
              )}

              {!MiscFunctions.isNull(this.state.level) &&
                this.state.level !== "taskgroup" &&
                this.state.level !== "phase" && (
                  <ListInput
                    label={this.props.t('general.parent')}
                    Elements={
                      !MiscFunctions.isNull(this.state.segments)
                        ? this.state.parentLevel
                        : null
                    }
                    selectHandler={this.handleParent}
                    preSelection={
                      !MiscFunctions.isNull(this.state.parent)
                        ? this.state.parent
                        : this.emptyValue
                    }
                    Ready={!MiscFunctions.isNull(this.state.segments)}
                  />
                )}

              <button
                onClick={() => {
                  this.handleSubmit();
                }}
                className={
                  "content-admin-save-btn" +
                  (this.state.sendSuccess ? "-success" : "")
                }
                disabled={
                  !this.state.name ||
                    !this.state.description ||
                    !this.state.start ||
                    !this.state.end ||
                    (!this.state.level && this.props.segmenttype === "TaskDto") ||
                    (!this.state.person && this.props.segmenttype === "TaskDto")
                    ? true
                    : false
                }
              >
                {this.state.mode === "insert" ? this.props.t('general.enter') : this.props.t('general.change')}
              </button>
              {this.state.sendSuccess === true && (
                <CheckBadgeIcon className="h-6 w-6" />
              )}
              {this.state.sendSuccess === false && (
                <XCircleIcon className="h-6 w-6" />
              )}
              <b>{this.state.responseText}</b>
            </div>
          )}
        </div>
      </Card>
    );
  }
}

export default withTranslation()(PhaseInput);
