/**
 *
 * @file SegmentServices.js
 * @created_date Friday, July 28, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This class is for handling of the Phase-Data-Objects, it encapsulates various functions for handling and retrieving information related to segments, which could represent phases or tasks.
 * <br/>Example use age of code - {@tutorial SegmentServices}
 * @module SegmentServices
 *
 **/

import no_thumbnail from "../assets/img/player/thumbnail-not-available.svg";
import MiscFunctionsLocal from "../helpers/MiscFunctions";
import { MiscFunctions } from 'helper-functions-package';

/** Class for handling the Segments-Object. */
class Segment {
  constructor(label, start, end, videoID) {
    this.label = label;
    this.duration = end - start;
    this.videoID = videoID;
    this.segmenttype = "PhaseDto";
    this.level = "phase";
    this.timestamp = { timestamp: start, tag: "datetimestamp" };
    // this.thumbnail="64e467caed29d700373c72c8";
    // this.picture="64e467caed29d700373c72c8";
    this.description = "";
    this.parent = null;
    this.ressources = [];
  }

  //
  /**
   * @description Setter-Function to create the object from a json. These will directly assigned to it.
   * @param {json} json - The JSON-Object containing all values of the object.
   **/
  set assignData(json) {
    // Assigns a JSON-Object to the Data-Object.
    Object.assign(this, json);
  }

  //
  /**
   * @description Getter-Function to determine if object is a Phase. Returns a boolean value if it is a phase or not.
   * @return {boolean}.
   */
  get isPhase() {
    if (this.segmenttype === "PhaseDto") {
      return 1;
    } else {
      return 0;
    }
  }

  //
  /**
   * @description Getter-Function to determine if object is a task. Returns a boolean value if it is a task or not.
   * @return {boolean}.
   */
  get isTask() {
    if (this.segmenttype === "TaskDto") {
      return 1;
    } else {
      return 0;
    }
  }

  //
  /**
   * @description Getter-Function to retrieve the tools from the ressource-List.
   * @return {string}.
   */
  get getTools() {
    return this.ressources.filter((el) => {
      if (el.reference.category === "tool") {
        return el;
      }
    });
  }

  //
  /**
   * @description Getter-Function to retrieve the timestamp. Returns the actual timestamp of the object as a string (format: HH:MM:SS).
   * @return {string}.
   */
  get getTimestamp() {
    return this.getTimeStampString;
  }

  get getLevel() {
    return this.level;
  }

  get getParent() {
    return this.parent;
  }

  get getAnatomical() {
    return this.anatomicalRegion;
  }

  get getSterility() {
    return this.sterility;
  }

  //
  /**
   * @description Getter-Function to retrieve the timestamp. Returns the actual timestamp of the object as a string (format: HH:MM:SS).
   * @return {string}.
   */
  get getTimeStampString() {
    return this.timestamp.timestamp;
  }

  //
  /**
   * @description Getter-Function to retrieve the timestamp. Returns the actual timestamp of the object in seconds.
   * @return {number}.
   */
  get getTimeStampSeconds() {
    return MiscFunctions.DateString2Seconds(this.getTimeStampString);
  }

  //
  /**
   * @description Getter-Function to retrieve the duration. Returns the duration of task/phase in seconds.
   * @return {integer}.
   */
  get getDuration() {
    return this.duration;
  }

  //
  /**
   * @description Getter-Function to retrieve the thumbnail. Returns an url to the thumbnail of the task/phase. If attribute is empty, it will return a default object (assets/img/player/thumbnail-not-available.svg)
   * @return {integer}.
   */
  get getThumbnail() {
    return !MiscFunctions.isNull(this.thumbnail) && !MiscFunctions.isUndefined(this.thumbnail)
      ? this.thumbnail.url
      : no_thumbnail;
  }

  get getPicture() {
    return this.picture !== "null" && !MiscFunctions.isUndefined(this.picture)
      ? this.picture.url
      : no_thumbnail;
  }

  //
  /**
   * @description Getter-Function to retrieve the ID. Returns the ID of the task/phase.
   * @return {id}.
   */
  get getID() {
    return this.id;
  }

  //
  /**
   * @description Getter-Function to retrieve the label of the task/phase. Returns the label of the task/phase. If empty it will return "not found"
   * @return {label}.
   */
  get getLabel() {
    return !MiscFunctions.isUndefined(this.label)
      ? MiscFunctionsLocal.formatText(this.label)
      : "not found";
  }
  //
  /**
   * @description Getter-Function to retrieve the description of the task/phase. Returns the label of the task/phase. If empty it will return "not found"
   * @return {description}.
   */
  get getDescription() {
    return !MiscFunctions.isUndefined(this.description)
      ? MiscFunctionsLocal.formatText(this.description)
      : "not found";
  }

  // Getter-Function to retrieve the json required for Post-request
  get json() {
    let process = {
      label: MiscFunctionsLocal.checkCommentText(this.label),
      segmenttype: this.segmenttype,
      description: MiscFunctionsLocal.checkCommentText(this.description),
      video: {
        id: this.videoID,
        tag: "video",
      },
      id: {
        id: this.id,
        tag: "segment",
      },
      thumbnail: this.thumbnail,
      picture: this.picture,
      duration: this.duration,
      timestamp: this.timestamp,
      level: this.level,
    };

    if (!MiscFunctions.isNull(this.sterility)) {
      process["sterility"] = this.sterility;
    }
    if (!MiscFunctions.isNull(this.anatomical)) {
      process["anatomicalRegion"] = this.anatomicalRegion;
    }

    if (!MiscFunctions.isNull(this.parent)) {
      process["parent"] = { id: this.parent, tag: "segment" };
    }
    if (this.ressources.length !== 0) {
      process["ressources"] = this.ressources;
    }
    // if (this.pos !== undefined){ comment["position"]=this.pos}
    // if (this.parent !== undefined){ comment["parent"]={"id": this.parent,"tag": "comment"}}
    // if (this.previous !== undefined){ comment["previous"]={"id": this.previous,"tag": "comment"}}

    return process;
  }

  //
  /**
   * @description Setter-Function to assign a media-Object to thumbnail.
   * @param {string} input - ID of the media-object.
   **/
  set setThumbnail(input) {
    this.thumbnail = input;
  }

  //
  /**
   * @description Setter-Function to assign a media-Object to picture.
   * @param {string} input - ID of the media-object.
   **/
  set setPicture(input) {
    this.picture = input;
  }

  //
  /**
   * @description Setter-Function to assign a parent-object to this segment.
   * @param {segment} input - segment-object
   **/
  set setParent(input) {
    this.parent = input.getID;
    // if (this.segmenttype==="PhaseDto"){
    //   this.parent=input.getID;
    //   this.level="subphase"
    // }
    // if (this.segmenttype==="TaskDto"){
    //   this.parent=input.getID;
    //   // this.parent=input.getID;
    //   // if (this.getLevel==="taskgroup") {
    //   //   this.level="maintask"
    //   // }
    //   // if (this.getLevel==="maintask") {
    //   //   this.level="subtask"
    //   // }

    // }
  }

  /**
   * @description Setter-Function to assign a text as description to the object.
   * @param {string} input - text
   **/
  set setDescription(input) {
    this.description = input;
  }

  /**
   * @description Setter-Function to assign a name to the object.
   * @param {string} input - string
   **/
  set setLabel(input) {
    this.label = input;
  }

  set setAnatomical(input) {
    this.anatomicalRegion = input;
  }

  set setSterility(input) {
    if (input === "Steril") {
      this.sterility = true;
    } else {
      this.sterility = false;
    }
  }

  /**
   * @description Setter-Function to set the segmenttype of the object.
   * @param {string} input - string
   **/
  set setType(input) {
    if (input === "PhaseDto") {
      this.segmenttype = "PhaseDto";
      // this.level="phase";
    } else {
      this.segmenttype = "TaskDto";
      //   this.level="taskgroup";
    }
  }

  /**
   * @description Setter-Function to set the timestamp (format: HH:MM:SS).
   * @return {string}.
   */
  set setTimeStampString(input) {
    this.timestamp = { timestamp: input, tag: "datetimestamp" };
  }

  /**
   * @description Setter-Function to set the level of the object.
   * @param {string} input - string
   **/
  set setLevel(input) {
    this.level = input;
  }

  /**
   * @description Setter-Function to set the timestamp (format: HH:MM:SS).
   * @return {string}.
   */
  set setTimeStampID(input) {
    this.timestamp = { id: input, tag: "datetimestamp" };
  }

  /**
   * @description Setter-Function to set the Duration of the segment in seconds.
   * @return {string}.
   */
  set setDuration(input) {
    this.duration = input;
  }

  /**
   * @description Setter-Function to set the VideoID of the segment.
   * @return {string}.
   */
  set setVideo(input) {
    this.videoID = input;
  }
  /**
   * @description Setter-Function to set the VideoID of the segment.
   * @return {string}.
   */
  set setID(input) {
    this.id = input;
  }

  set addRessource(input) {
    this.ressources.push({ id: input.id.id, tag: "ressource" });
  }
}

export default Segment;
