/**
 * @file RessourceInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This component serves as an interactive interface for selecting and managing different types of resource objects, with the ability to input names and select parent IDs. The selected resource element dynamically influences the appearance of the buttons and triggers the rendering of a corresponding input card.
 *<br/>Breakdown of the code - {@tutorial RessourceInput}
 * @module RessourceInput
 *
 **/

import React from "react";
import Ressource_InputCard from "./Ressource_InputCard";
import { Card } from "flowbite-react";
import { withTranslation } from "react-i18next";

class RessourceInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      selectedElement: "",
      name: "",
      parent: null,
    };
  }

  handleParent = (id) => {
    this.setState({ parent: id });
  };
  handleName = (string) => {
    this.setState({ name: string });
  };

  onClickOutsideListener = () => { };

  componentWillUnmount() { }

  render() {
    return (
      <Card className="max-w-full mt-2">
        <div className="">
          <div className="input_label mb-2">{this.props.t('general.resource')}-Object</div>
          <div className="flex flex-row">
            <div
              style={{
                "background-color":
                  this.state.selectedElement === "person"
                    ? "hsl(180, 100%, 60%)"
                    : "hsl(180, 100%, 40%)",
              }}
              className="contet-admin-button-resource-block"
            >
              <button
                onClick={() => {
                  this.setState({ selectedElement: "person" });
                }}
                className={
                  "w-full text-white hover:bg-hightlight-800 font-medium text-md px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700"
                }
              >
                {" "}
                {this.props.t('general.person')}{" "}
              </button>
            </div>
            <div
              style={{
                "background-color":
                  this.state.selectedElement === "room"
                    ? "hsl(180, 100%, 60%)"
                    : "hsl(180, 100%, 40%)",
              }}
              className="contet-admin-button-resource-block"
            >
              <button
                onClick={() => {
                  this.setState({ selectedElement: "room" });
                }}
                className={
                  "w-full text-white hover:bg-hightlight-800 font-medium text-md px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700"
                }
              >
                {" "}
                {this.props.t('general.room')}{" "}
              </button>
            </div>
            <div
              style={{
                "background-color":
                  this.state.selectedElement === "tool"
                    ? "hsl(180, 100%, 60%)"
                    : "hsl(180, 100%, 40%)",
                "width": "170px",
              }}
              className="contet-admin-button-resource-block"
            >
              <button
                onClick={() => {
                  this.setState({ selectedElement: "tool" });
                }}
                className={
                  "w-full text-white hover:bg-hightlight-800 font-medium text-md px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700"
                }
              >
                {" "}
                {this.props.t('general.equipment')}/{this.props.t('general.tool')}/{this.props.t('general.pharmaceuticals')}{" "}
              </button>
            </div>
          </div>

          {this.state.selectedElement !== "" && (
            <Ressource_InputCard selectedElement={this.state.selectedElement} />
          )}
        </div>
      </Card>
    );
  }
}

export default withTranslation()(RessourceInput);
