/**
 *
 * @file RessourceService.js
 * @created_date Friday, Dec 08, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The provided JavaScript file defines a class called Ressource intended for handling and encapsulating resource data. The class constructor takes a JSON object as input and dynamically assigns its key-value pairs to the class instance properties. The class offers a range of getter functions, such as getID, getContext, getDescription, getLabel, getPicture, getPosition, getThumbnail, getTime, getReference, getReferenceId, getReferenceLabel, getReferenceDescription, and getReferenceType.
 * <br/>Example use age of code - {@tutorial Ressource}
 * @module Ressource
 *
 **/

import { MiscFunctions } from 'helper-functions-package';

/** Class for handling the Ressource-Object. */
class Ressource {
  constructor(json) {
    Object.keys(json).map((key) => {
      this[key] = json[key];
    });
  }
  //

  //
  /**
   * @description Getter-Function to retrieve the ID. Returns the ID of the resource.
   * @return {id}.
   */
  get getID() {
    return !MiscFunctions.isUndefined(this.id.id) ? this.id.id : this.id;
  }

  get getContext() {
    return !MiscFunctions.isUndefined(this.context) ? this.context : null;
  }

  get getDescription() {
    return !MiscFunctions.isUndefined(this.reference) ? this.reference.description : null;
  }

  get getLabel() {
    return !MiscFunctions.isUndefined(this.label) ? this.label : null;
  }

  get getPicture() {
    return !MiscFunctions.isUndefined(this.picture) ? this.picture : null;
  }

  get getPosition() {
    return !MiscFunctions.isUndefined(this.position) ? this.position : null;
  }

  get getThumbnail() {
    return !MiscFunctions.isUndefined(this.thumbnail) ? this.thumbnail : null;
  }
  get getTime() {
    return !MiscFunctions.isUndefined(this.time) ? this.time : null;
  }
  get getQuantity() {
    return !MiscFunctions.isUndefined(this.quantity) ? this.quantity : null;
  }

  get getDisposable() {
    return !MiscFunctions.isUndefined(this.reference.disposable) ? this.reference.disposable : null;
  }

  get getReference() {
    return !MiscFunctions.isUndefined(this.reference) ? this.reference : null;
  }

  get getReferenceId() {
    return !MiscFunctions.isUndefined(this.reference) && !MiscFunctions.isUndefined(this.reference.id.id)
      ? this.reference.id.id
      : this.reference.id;
  }
  get getReferenceLabel() {
    return !MiscFunctions.isUndefined(this.reference) && !MiscFunctions.isUndefined(this.reference.label)
      ? this.reference.label
      : null;
  }
  get getReferenceDescription() {
    return !MiscFunctions.isUndefined(this.reference) &&
      !MiscFunctions.isUndefined(this.reference.description)
      ? this.reference.description
      : null;
  }
  get getReferenceType() {
    return !MiscFunctions.isUndefined(this.reference) && !MiscFunctions.isUndefined(this.reference.type)
      ? this.reference.type
      : null;
  }
  get getReferenceCategory() {
    return !MiscFunctions.isUndefined(this.reference) && !MiscFunctions.isUndefined(this.reference.category)
      ? this.reference.category
      : null;
  }
}

export default Ressource;
