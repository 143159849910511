/**
 *
 * @file ForgetPassword.js
 * @created_date Thurseday, Jan 19, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description ForgetPassword component is responsible for handling the process of resetting a password for a user's account. It includes a form to enter the email address associated with the account, a reCAPTCHA verification for security purposes, and feedback messages to inform the user about the status of the password reset request.
 * <br/>Breakdown of the code - {@tutorial ForgetPassword}
 * @module ForgetPassword
 *
 **/

import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { MiscFunctions } from 'helper-functions-package';
import authAPI from "../api/authAPI";
import ToastMessages from "../helpers/ToastMessages";
import { useTranslation } from "react-i18next";

// This method is for reseting password.
function ForgetPassword(props) {
  // This statement load reCaptchaKey from .env file (application rool dir).
  const reCaptchaKey = process.env.REACT_APP_SITE_KEY;

  // This hook enable/desable reset button on reCaptcha verifiction.
  const [isResetVerified, setIsResetVerified] = useState(false);

  // This hook is to set/get email input.
  const [resetEmail, setResetEmail] = useState("");

  // This hook is to set/get state of email validation.
  const [emailInvalidInput, setEmailInvalidInput] = useState(false);

  // This hook is to set/get recpacha value.
  const [captchaValue, setCaptchaValue] = useState("");

  // This hook is for enable/disable of spinner (Loading).
  const [spinner, setSpinner] = useState(false);

  const { t } = useTranslation();


  // Below are predefined Default/Error/Success classes which is called on it's state for inputs.
  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white input-style";
  const errorClass =
    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 customize-input-style";
  const successClass =
    "bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500 customize-input-style";

  /** 
     * @description This function enables email validation by updating the visual appearance of the email input field based on the validity of the email.
     * 

The `checkValidation` function is an event handler associated with the email input field. It retrieves the email value from the field and passes it to a custom validation function, `MiscFunctions.emailValidation`. If the email is considered invalid, the function updates the class name of the input field to indicate the error state by assigning the `errorClass`. It also sets the `emailInvalidInput` state variable to `true` and stores the label or identifier "errorLabel" in the `emailInvalidLabel` state variable.

On the other hand, if the email is considered valid, the function updates the class name of the input field to indicate the successful state using the `successClass`. It sets the `emailInvalidInput` state variable to `false` and resets the `emailInvalidLabel` state variable to an empty string.
    * @function checkValidation 
    * @return {void}
    * */
  const [emailInvalidLabel, setEmailInvalidLabel] = useState("");
  const checkValidation = (e) => {
    const email = MiscFunctions.emailValidation(resetEmail);
    if (!email) {
      e.target.className = errorClass;
      setEmailInvalidInput(true);
      setEmailInvalidLabel("errorLabel");
    } else {
      e.target.className = successClass;
      setEmailInvalidInput(false);
      setEmailInvalidLabel("");
    }
  };

  /**
   * @description This function handles the submission of password reset request. It performs email validation, initiates the reset request using the authentication service (authService), and updates state variables to control the visibility of form details and email sent details based on the request's success or failure.
   * 

The `handleForgetSubmit` function is an asynchronous event handler that is triggered when a form is submitted for a password reset. Upon submission, it sets the `spinner` state variable to `true`, indicating that a loading spinner should be displayed to indicate an ongoing process.

Next, the function prevents the default form submission behavior using `e.preventDefault()` to prevent the page from reloading.

The value entered in the email input field (`resetEmail`) is then passed to a custom email validation function (`MiscFunctions.emailValidation`) to check its validity. If the email is considered invalid (the validation function returns `false`), the `emailIsInvalid` function is called to display an error toast message. The `spinner` state variable is set to `false` to stop the loading spinner.

If the email is considered valid, the `authAPI.requestResetPassword` method is called, which likely sends a password reset request to the server. The `resetEmail` and `captchaValue` (likely obtained from a reCAPTCHA verification) are passed as parameters.

The function waits for the promise returned by `resetPass` to resolve. If the reset request is successful (the `data` variable holds a truthy value), the `showResetFormDetails` state variable is set to `false`, hiding the reset form details, and the `showResetEmailSent` state variable is set to `true`, showing the reset email sent details. The `spinner` state variable is set to `false` to stop the loading spinner.

In summary, this code handles the submission of a password reset request. It performs email validation, initiates the reset request using the authentication service (`authService`), and updates state variables to control the visibility of form details and email sent details based on the success or failure of the request.
   * @function handleForgetSubmit
   * @return {void}
   * */
  const [showResetFormDetails, setShowResetFormDetails] = useState(true);
  const [showResetEmailSent, setShowResetEmailSent] = useState(false);
  // Below method is for reseting the passowrd.
  const handleForgetSubmit = async (e) => {
    setSpinner(true);
    e.preventDefault();
    const email = MiscFunctions.emailValidation(resetEmail);
    if (!email) {
      ToastMessages.erorrMessage('Opps, Email is invalid!');

      setSpinner(false);
    } else {
      const resetPass = authAPI.requestResetPassword(
        resetEmail,
        captchaValue
      );
      resetPass.then((data) => {
        // In any case redirect the user to reset success page to prevent any possilbe security flaw.
        if (data) {
          setShowResetFormDetails(false);
          setShowResetEmailSent(true);
          setSpinner(false);
        }
      });
      setSpinner(false);
    }
  };

  /**
   * @description This function is responsible for handling the value change event of a reCAPTCHA component related to the forget password functionality. It updates the captchaValue state variable with the provided value and sets the isResetVerified state variable to true.
   *
   * @function recaptchaForgetPassword
   * @return {void}
   * */

  // This method is for captcha value handling.
  const recaptchaForgetPassword = (value) => {
    setCaptchaValue(value);
    setIsResetVerified(true);
  };

  const recaptchaRef = useRef(null);
  useEffect(() => {
    recaptchaRef.current?.reset();
  }, [props]);

  return (
    <>
      {showResetFormDetails && (
        <div>
          <h4
            className="text-xl font-medium text-gray-900 dark:text-white text-center"
            id="label_login_header"
          >
            {t('login_register_page.reset_password')}
          </h4>

          <h5
            className="text-xl font-medium text-gray-900 dark:text-white text-center"
            id="label_sign-up_description"
          >
            {t('login_register_page.reset_password_for')}
            medi
            <span className="boldColored">MESH</span>{" "}
            <span className="boldOnly">{t('general.account')}.</span>
          </h5>
          <div className="reset_passwor_modal_content">
            <p id="label_verfication_state-description">
              {t('login_register_page.please_enter_email')}
            </p>

            <form
              className="space-y-6"
              onSubmit={handleForgetSubmit}
              method="post"
            >
              <label
                htmlFor="reset_password"
                className={
                  emailInvalidLabel +
                  " block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
                }
                id="label_email-reset-password"
              >
                {t('login_register_page.your_email')}
              </label>
              <input
                type="email"
                name="reset_password"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                placeholder="YourmediMESH@mail.com"
                id="reset_password"
                onBlur={checkValidation}
                className={inputClasses}
                required
              />
              {emailInvalidInput ? (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500 default-label-style">
                  {t('messages.enter_valid_email')}
                </p>
              ) : (
                ""
              )}
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={reCaptchaKey}
                onChange={recaptchaForgetPassword}
                hl="en"
                id="recaptcha-forgot-container"
                className="container_recaptcha_forgot"
                onExpired={() => {
                  recaptchaRef.current.reset();
                }}
              />

              <div className="flex flex-col items-center">
                <button
                  type="submit"
                  className="w-full text-white bg-hightlight-700 hover:bg-hightlight-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700 dark:focus:ring-hightlight-800 button_sign_in_up"
                  disabled={!isResetVerified}
                >
                  {spinner ? (
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  ) : (
                    ""
                  )}{" "}
                  {t('general.send')}

                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showResetEmailSent && (
        <>
          <h4
            className="text-xl font-medium text-gray-900 dark:text-white text-center"
            id="label_login_header"
          >
            {t('login_register_page.an_email_sent')}
          </h4>
          <h5
            className="text-xl font-medium text-gray-900 dark:text-white text-center"
            id="label_sign-up_description"
          >
            {t('general.your')} medi<span className="boldColored">MESH</span>{" "}
            <span className="boldOnly">{t('login_register_page.account_will_reset')}.</span>
          </h5>
          <div className="reset_passwor_modal_content">
            <p id="label_verfication_state-description">
              {t('login_register_page.an_email_sent')}{" "}
              <span className="boldOnly">{resetEmail}</span>. {t('login_register_page.please_check_your_email')}.
            </p>
          </div>
        </>
      )}
    </>
  );
}

export default ForgetPassword;
