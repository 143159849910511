/**
 * @file Avatar.js
 * @created_date Thursday, December 21, 2022
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The code defines a React component called Avatar that represents a user avatar. The component renders an avatar image and displays additional user information when the avatar is clicked or hovered over. The additional user information includes the user's name, registration date, number of comments, and category.
 *<br/>Breakdown of the code - {@tutorial Avatar}
 * @module Avatar
 *
 **/

import React from "react";
import { withTranslation } from "react-i18next";

class Avatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHideSummaryBlock: false,
      iconClicked: false,
      // hier können Zustandsvariablen definiert werden
    };
  }

  onClickOutsideListener = () => {
    if (this.state.iconClicked) {
      document.removeEventListener("click", this.onClickOutsideListener);
      this.setState({ showHideSummaryBlock: !this.state.showHideSummaryBlock });
      this.setState({ iconClicked: !this.state.iconClicked });
    }
  };

  render() {
    return (
      <div className="comment_box_user">
        <span
          className="comment_box_user_image"
          style={{ background: `#616161 url(${this.props.avatar})` }}
          onClick={() => {
            this.setState({
              iconClicked: !this.state.iconClicked,
              showHideSummaryBlock: !this.state.iconClicked,
            });
          }}
          onMouseOver={() => {
            !this.state.iconClicked &&
              this.setState({ showHideSummaryBlock: true });
          }}
          onMouseOut={() => {
            !this.state.iconClicked &&
              this.setState({ showHideSummaryBlock: false });
          }}
          onMouseLeave={() => {
            this.state.iconClicked &&
              document.addEventListener("click", this.onClickOutsideListener);
          }}
        ></span>
        {/* <div className="comment_user_profile_textbox" >{this.props.name}</div>   */}
        {this.state.showHideSummaryBlock && (
          <div className="comment_user_profile_summary">
            <span>{this.props.t('general.user')}: {this.props.name}</span>
            <span>{this.props.t('general.registered_since')}:</span>
            <span>{this.props.t('general.number_of_comments')}:</span>
            <span>{this.props.t('general.category')}:</span>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(Avatar);
