/**
 *
 * @file InteractionsServices.js
 * @created_date Friday, July 28, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This class is for handling of the Interaction-Objects, it encapsulates various functions for handling and retrieving information related to single comment objects.
 * <br/>Example use age of code - {@tutorial InteractionsServices}
 * @module InteractionsServices
 *
 **/

import { MiscFunctions } from 'helper-functions-package';

/** Class for handling the Interaction-Objects. */
class Interactions {
  /**
   * @description Creates an array of Comment-Objects.
   * @param {Comment} Array - Array of Comment-Objects.
   */
  constructor(Array) {
    this.Elements = Array;
  }

  //
  /**
   * @description Setter-Function to create the object from a json. Adds an additional Comment-Object to Interactions.Elements
   * @param {Comment} Comment Comment-Object to be added.
   */
  set addComment(Comment) {
    if (MiscFunctions.isUndefined(Comment.error)) {
      this.Elements.push(Comment);
    }
  }

  get getLength() {
    return !MiscFunctions.isUndefined(this.Elements) ? this.Elements.length : 0;
  }

  get getElements() {
    return this.Elements;
  }

  // Methods

  /**
   * @description Will return all comments associated to the activated categories.
   * @param {category} categories Array of category objects
   * @return {Interactions} All comments with the activated categories.
   */
  getElementsOfCategory(categories) {
    // Searching for the category "All" inside the Category-Array
    let getAll =
      categories.find((category) => category.label === "All").status !==
      undefined
        ? categories.find((category) => category.label === "All").status
        : false;
    // In the case, that "All-Categories" is picked
    if (getAll) {
      return new Interactions(this.Elements);
    } else {
      let FilteredInteractions = new Interactions([]);
      this.Elements.map((comment) => {
        if (
          comment.isCommentOfCategory(
            categories.filter((category) => category.status === true)
          )
        ) {
          FilteredInteractions.addComment = comment;
        }
      });
      return FilteredInteractions;
    }
  }

  /**
   * @description Will return all comments, which include the given keywords.
   * @param {string} keywords String conatining the keyword
   * @return {Interactions} All comments, which include the given keywords.
   */
  getElementsOfKeywords(keywords) {
    let unique = new Interactions([]);
    this.Elements.map((comment) => {
      let headline = comment.getHeadline
        .toLowerCase()
        .indexOf(keywords.toLowerCase());
      let content = comment.getContent !== null && comment.getContent
        .toLowerCase()
        .indexOf(keywords.toLowerCase());
      if (headline !== -1 || content !== -1) {
        unique.addComment = comment;
      }
    });
    return unique;
  }

  /**
   * @description Will return all comments assoociated with the given authors.
   * @param {companyUsers} persons Array of persons, who are within comapny association of the user
   * @return {Interactions} All comments, whos authors corresponds to the "activated" persons.
   */
  getElementsofPersons(persons) {
    let unique = new Interactions([]);
    this.Elements.map((comment) => {
      if (
        !MiscFunctions.isUndefined(persons.find((o) => {
          return comment.getUserName.indexOf(o.display) !== -1 && o.filterState;
        })) || // Default if no person is clicked
        MiscFunctions.isUndefined(persons.find((o) => {
          return o.filterState;
        }))
      ) {
        unique.addComment = comment;
      }
    });
    return unique;
  }
}

export default Interactions;
