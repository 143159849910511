import React from "react";
class Layout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <>Layout</>;
  }
}
export default Layout;
