let WorkFlowInfoData = {
  WorkflowName: "Workflow Information",
  WorkflowId: "#",
  resources: [
    {
      id: "123",
      type: "default_person_group",
      label: "Default Person",
      description:
        "Description of general roles and specific responsibilities for Person 1 in the procedure.",
      picture: null,
    },

    {
      id: "456",
      type: "rooms",
      label: "Default Room",
      description:
        "Specific description for a room or a room area, detailing its purpose and setup in the surgical workflow.",
      picture: null,
    },

    {
      id: "789",
      type: "equipment",
      label: "Default Equipment",
      description:
        "Detailed information on a specific equipment, explaining its role and function in the procedure.",
      picture: null,
    },

    {
      id: "321",
      type: "tools",
      label: "Default Tool",
      description:
        "Summary of tools and instruments used, highlighting their importance in the surgery.",
      picture: null,
    },
    {
      id: "654",
      type: "Pharmaceuticals",
      label: "Default Pharma",
      description:
        "Detailed profile of Pharmaceutical 1, explaining its application and importance in the procedure.",
      picture: null,
    },
  ],

  infos: [
    {
      label: "General Information",
      tag: "general-info",
      info: [
        {
          label: "Abstract",
          description:
            "A brief overview of the procedure. This should include the nature of the surgery, its purpose, and a highlight of its significance or safety profile. The abstract is a condensed version of the entire document, giving the reader a quick snapshot of what to expect.",
        },
        {
          label: "Introduction",
          description:
            "In this section, provide a basic understanding of the procedure, including who typically performs it, the general steps involved, and its significance in the medical field. Highlight any unique aspects of the procedure, such as advancements in technique or technology.",
        },
        {
          label: "Indication",
          description:
            "Clearly state the conditions or situations where the procedure is advised. This could include specific medical conditions, lifestyle choices, or demographic considerations. The goal here is to define the target patient population for the procedure. ",
        },
        {
          label: "Contraindication",
          description:
            "Outline the conditions or factors that make the procedure inadvisable. This could include certain health conditions, medication interactions, or other risk factors. It's important to be clear and specific to avoid potential complications.",
        },
        {
          label: "Preoperative preparation",
          description:
            "Detail the necessary steps and preparations a patient must undertake before the surgery. This could include dietary restrictions, pre-surgery tests, or psychological preparations. Ensure that these instructions are clear and easy to follow.",
        },
        {
          label: "Postoperative care",
          description:
            "Describe the care and precautions needed after the surgery. This should include information on recovery time, mobilization, medication, follow-up appointments, and lifestyle adjustments. Also, mention any long-term considerations if applicable.",
        },
      ],
    },
    {
      label: "Workflow Overview",
      tag: "workflow-overview",
      info: [
        {
          label: "Workflow Phases",
          description:
            "Describes the various phases of the surgical workflow, providing a comprehensive overview.",
        },
        {
          label: "Main Tasks",
          description:
            "Elaborates on the main tasks involved in the surgery, outlining their roles and interactions.",
        },
      ],
    },
  ],

  WorkflowOverview: {
    Phases: [
      {
        id: "phase1",
        label: "Workflow Phases",
        description:
          "Describes the various phases of the surgical workflow, providing a comprehensive overview.",
      },
    ],
    Tasks: [
      {
        id: "task1",
        label: "Main Tasks",
        description:
          "Elaborates on the main tasks involved in the surgery, outlining their roles and interactions.",
      },
    ],
  },
};

module.exports = { WorkFlowInfoData };
