import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import logo from '../assets/img/logo_mediMESH_1500x300.png'

class DeleteConfirmPage extends Component {
    render() {
        return (
            <div className='delete-confirm-page'>
                <span>
                    <img src={logo} className='delelte-confirm-logo' />
                </span>
                <h2 className='delete-confirm-heading'>{this.props.t('messages.you_will_receive_a_deletion_email')}</h2>
                <p className='delete-confirm-body-text'>{this.props.t('messages.thanks_for_using_our_product')}!</p>
                <p className='delete-confirm-body-text'>{this.props.t('messages.look_forward_seeing_you_again')}.</p>
            </div>
        )
    }
}

export default withTranslation()(DeleteConfirmPage)