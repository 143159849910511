import React from "react";
import { withTranslation } from "react-i18next";
class Security extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    render() {
        return (
            <>{this.props.t('setting_page.security')}</>
        )
    }

}
export default  withTranslation()(Security)