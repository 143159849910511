import React from "react";
class SwimlaneQuickAccessMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="swimlane-quick-access-menu-container"></div>;
  }
}
export default SwimlaneQuickAccessMenu;
