import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function SelectRole(props) {
  const [toggleMedicalExpertClick, setToggleMedicalExpertClick] =
    useState(false);
  const [toggleDevelopertClick, setToggleDeveloperClick] = useState(false);

  /**
   * @description This function is an event handler that is triggered when the medical expert role button is hovered over. It sets the toggleMedicalExpertClick state variable to true, displaying additional content related to the medical expert role.
   *
   * @function handleMedicalExpertShowBTN
   * @return {void}
   * */
  const handleMedicalExpertShowBTN = () => {
    setTimeout(() => {
    setToggleMedicalExpertClick(true);
    }, 200);
  };

  /**
   * @description This function is an event handler that is triggered when the mouse pointer is moved away from the medical expert role button. It sets the toggleMedicalExpertClick state variable to false, hiding the additional content related to the medical expert role.
   *
   * @function handleMedicalExpertHideBTN
   * @return {void}
   * */
  const handleMedicalExpertHideBTN = () => {
    setTimeout(() => {
    setToggleMedicalExpertClick(false);
    }, 200);
  };

  /**
   * @description This function is an event handler that is triggered when the developer role button is hovered over. It sets the toggleDeveloperClick state variable to true, displaying additional content related to the developer role.
   *
   * @function handleDeveloperShowBTN
   * @return {void}
   * */
  const handleDeveloperShowBTN = () => {
    setTimeout(() => {
    setToggleDeveloperClick(true);
    }, 200);
  };

  /**
   * @description This function is an event handler that is triggered when the mouse pointer is moved away from the developer role button. It sets the toggleDeveloperClick state variable to false, hiding the additional content related to the developer role.
   *
   * @function handleDeveloperHideBTN
   * @return {void}
   * */
  const handleDeveloperHideBTN = () => {
    setTimeout(() => {
    setToggleDeveloperClick(false);
      }, 200);
  };

  /**
   * @description This function is an event handler that is triggered when the user clicks the "Jetzt Starten" button to register with a specific role. It takes a role parameter, which represents the selected user role. Within the function, it performs the following actions:
- Sets the toggleDeveloperClick and toggleMedicalExpertClick state variables to false, hiding any
- previously displayed additional content related to the developer or medical expert roles.
- Sets the registerPopupVisible state variable to true, showing the register popup/modal.
- Sets the switchRolePopupVisible state variable to false, hiding the switch role popup/modal.
- Sets the isSwitchRoleUser state variable to the value of the role parameter, representing the selected user role.
   *
   * @function handleDeveloperHideBTN
   * @return {void}
   * */
  const handleRegister = (role) => {
    setToggleDeveloperClick(false);
    setToggleMedicalExpertClick(false);
    props.setRegisterPopupVisible(true);
    props.setSwitchRolePopupVisible(false);
    props.setIsSwitchRoleUser(role);
  };

  const { t } = useTranslation();

  return (
    <div className="switch_role_roles_section flex">
      {/* Developer */}
      <div
        className="switch_role_select_role"
        onMouseOver={() => {
          handleDeveloperShowBTN();
        }}
        onMouseOut={() => {
          handleDeveloperHideBTN();
        }}
        onClick={() => {
          handleRegister("Entwickler");
        }}
      >
        <div className="card_role_switcher_background">
          <div
            className={
              "indicator_role_switcher indicator_role_switcher_transition indicator_role_developer " +
              (toggleDevelopertClick ? "indicator_role_developer_select" : "")
            }
          ></div>
          <h2 className="label_role_switcher">{t("landing_page.developer")}</h2>
          <p
            className={
              "label_description_role_switcher " +
              (!toggleDevelopertClick
                ? "button_show_role_switcher"
                : "button_hide_role_switcher")
            }
          >
            {t("landing_page.developer_desc")}
          </p>
          <button
            className={
              "button_switch_role " +
              (toggleDevelopertClick
                ? "button_show_role_switcher"
                : "button_hide_role_switcher")
            }
          >
            {t("landing_page.start_now")}
          </button>
        </div>
      </div>
      {/* Kliniker */}
      <div
        className="switch_role_select_role"
        onMouseOver={handleMedicalExpertShowBTN}
        onMouseOut={handleMedicalExpertHideBTN}
        onClick={() => handleRegister("Kliniker")}
      >
        <div className="card_role_switcher_background">
          <div
            className={
              "indicator_role_switcher indicator_role_switcher_transition indicator_role_medical_expert" +
              (toggleMedicalExpertClick
                ? "role_border_color indicator_role_medical_expert_select"
                : "")
            }
          ></div>
          <h2 className="label_role_switcher">{t("landing_page.clinician")}</h2>
          <p
            className={
              "label_description_role_switcher " +
              (!toggleMedicalExpertClick
                ? "button_show_role_switcher"
                : "button_hide_role_switcher")
            }
          >
            {t("landing_page.clinician_des")}
          </p>
          <button
            className={
              "button_switch_role " +
              (toggleMedicalExpertClick
                ? "button_show_role_switcher"
                : "button_hide_role_switcher")
            }
          >
            {t("landing_page.start_now")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelectRole;
