/**
 * @file RessourceObjectInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This component is a React class that acts as a central interface for managing various resource types in the application. It dynamically renders specific input forms for rooms, tools, persons, and person groups based on the selected type. Each input form, such as `RessourceObject_RoomInput` and others, handles the creation and submission of resource data, ensuring a modular and organized approach to resource management. The component utilizes state variables to facilitate communication between parent and child components, resulting in a cohesive and user-friendly resource management system.
 *<br/>Breakdown of the code - {@tutorial RessourceObjectInput}
 * @module RessourceObjectInput
 *
 **/

import React from "react";
import RessourceObject_RoomInput from "./RessourceObject_RoomInput";
import RessourceObject_ToolInput from "./RessourceObject_ToolInput";
import RessourceObject_PersonGroupInput from "./RessourceObject_PersonGroupInput";
import RessourceObject_PersonInput from "./RessourceObject_PersonInput";
import { Card } from "flowbite-react";
import { withTranslation } from "react-i18next";

class RessourceObjectInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      selectedElement: "",
      name: "",
      parent: null,
    };
  }

  handleParent = (id) => {
    this.setState({ parent: id });
  };
  handleName = (string) => {
    this.setState({ name: string });
  };

  onClickOutsideListener = () => { };

  componentWillUnmount() { }

  render() {
    return (
      <Card className="max-w-full mt-2">
        <div className="">
          <div className="input_label mb-2">{this.props.t('general.resource')}-Object</div>
          <div className="flex flex-row">
            <div
              style={{
                "background-color":
                  this.state.selectedElement === "person"
                    ? "hsl(180, 100%, 60%)"
                    : "hsl(180, 100%, 40%)",
              }}
              className="contet-admin-button-resource-block"
            >
              <button
                onClick={() => {
                  this.setState({ selectedElement: "person" });
                }}
                className={
                  "w-full text-white hover:bg-hightlight-800 font-medium text-md px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700"
                }
              >
                {" "}
                {this.props.t('general.person')}{" "}
              </button>
            </div>
            <div
              style={{
                width: "140px",
                "background-color":
                  this.state.selectedElement === "persongroup"
                    ? "hsl(180, 100%, 60%)"
                    : "hsl(180, 100%, 40%)",
              }}
              className="contet-admin-button-resource-block"
            >
              <button
                onClick={() => {
                  this.setState({ selectedElement: "persongroup" });
                }}
                className={
                  "w-full text-white hover:bg-hightlight-800 font-medium text-md px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700"
                }
              >
                {" "}
                {this.props.t('general.person_group')}{" "}
              </button>
            </div>
            <div
              style={{
                "background-color":
                  this.state.selectedElement === "room"
                    ? "hsl(180, 100%, 60%)"
                    : "hsl(180, 100%, 40%)",
              }}
              className="contet-admin-button-resource-block"
            >
              <button
                onClick={() => {
                  this.setState({ selectedElement: "room" });
                }}
                className={
                  "w-full text-white hover:bg-hightlight-800 font-medium text-md px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700"
                }
              >
                {" "}
                {this.props.t('general.room')}{" "}
              </button>
            </div>
            <div
              style={{
                "background-color":
                  this.state.selectedElement === "tool"
                    ? "hsl(180, 100%, 60%)"
                    : "hsl(180, 100%, 40%)",
                "width": "170px",
              }}
              className="contet-admin-button-resource-block"
            >
              <button
                onClick={() => {
                  this.setState({ selectedElement: "tool" });
                }}
                className={
                  "w-full text-white hover:bg-hightlight-800 font-medium text-md px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700"
                }
              >
                {" "}
                {this.props.t('general.equipment')}/{this.props.t('general.tool')}/{this.props.t('general.pharmaceuticals')}{" "}
              </button>
            </div>

          </div>

          {this.state.selectedElement === "room" && (
            <RessourceObject_RoomInput />
          )}
          {this.state.selectedElement === "tool" && (
            <RessourceObject_ToolInput />
          )}
          {this.state.selectedElement === "person" && (
            <RessourceObject_PersonInput />
          )}
          {this.state.selectedElement === "persongroup" && (
            <RessourceObject_PersonGroupInput />
          )}
        </div>
      </Card>
    );
  }
}

export default withTranslation()(RessourceObjectInput);
