/**
 * @file ContentInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description The purpose of this component is to create a dropdown menu with selectable categories. It provides a user interface for selecting categories and manages the state of the menu, including the focus state, display state, and the list of available categories.
 *<br/>Breakdown of the code - {@tutorial Menu}
 * @module Menu
 *
 **/

import React from "react";
import SegmentInput from "./SegmentInput";
import WorkflowInfoInput from "./WorkflowInfoInput";
import TaskMetaInput from "./TaskMetaInput";
import RessourceObjectInput from "./RessourceObjectInput";
import { Button } from "flowbite-react";
import { withTranslation } from "react-i18next";

class ContentInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedElement: "",
      // put state variables here
    };
  }

  onClickOutsideListener = () => { };

  componentWillUnmount() { }

  render() {
    return (
      <div>
        <div className="flex flex-row">
          <div>
            <Button.Group outline>
              <Button
                // color="gray"
                style={{
                  width: "100px",
                  height: "auto",
                  "background-color":
                    this.state.selectedElement === "phase"
                      ? "hsl(180, 100%, 60%)"
                      : "hsl(180, 100%, 40%)",
                }}
                onClick={() => {
                  this.setState({ selectedElement: "phase" });
                }}
                className={
                  "focus:ring-5"
                }
              >
                {this.props.t('general.phases')}
              </Button>
              <Button
                style={{
                  width: "100px",
                  height: "auto",
                  "background-color":
                    this.state.selectedElement === "task"
                      ? "hsl(180, 100%, 60%)"
                      : "hsl(180, 100%, 40%)",
                }}
                onClick={() => {
                  this.setState({ selectedElement: "task" });
                }}
                className={
                  "focus:ring-5"
                }
              >
                {this.props.t('general.task')}/{this.props.t('general.resource')}

              </Button>
              <Button
                style={{
                  width: "100px",
                  height: "auto",
                  "background-color":
                    this.state.selectedElement === "workflowinfo"
                      ? "hsl(180, 100%, 60%)"
                      : "hsl(180, 100%, 40%)",
                }}
                onClick={() => {
                  this.setState({ selectedElement: "workflowinfo" });
                }}
                className={
                  "focus:ring-5"
                }
              >
                {this.props.t('general.workflow_info')}
              </Button>
            </Button.Group>
          </div>
          <div style={{
            marginLeft: "50px",
          }}>

            <Button
              // color="gray"
              style={{
                width: "100px",
                "background-color":
                  this.state.selectedElement === "notworkflowspecific"
                    ? "hsl(180, 100%, 60%)"
                    : "hsl(180, 100%, 40%)",
              }}
              onClick={() => {
                this.setState({ selectedElement: "notworkflowspecific" });
              }}
              className={
                "focus:ring-5"
              }
            >
              Non-Workflow specific Templates
            </Button>

          </div>
        </div>
        {this.state.selectedElement === "notworkflowspecific" && (
          <RessourceObjectInput />
        )}
        {this.state.selectedElement === "phase" && (
          <SegmentInput
            workflow={this.props.workflow}
            segments={this.props.phases}
            segmenttype={"PhaseDto"}
            UpdateSegments={this.props.UpdateSegments}
          />
        )}
        {this.state.selectedElement === "task" && (
          <TaskMetaInput
            workflow={this.props.workflow}
            segments={this.props.tasks}
            segmenttype={"TaskDto"}
            UpdateSegments={this.props.UpdateSegments}
          />
        )}
        {this.state.selectedElement === "workflowinfo" && (
          <WorkflowInfoInput
            workflow={this.props.workflow}
            setRessourcesUpdate={this.props.setRessourcesUpdate}
            ressourcesUpdate={this.props.ressourcesUpdate}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(ContentInput);
