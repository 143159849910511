/**
 * @file PlayerSidebar.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description 
The PlayerSidebar component is a React functional component that represents a sidebar for a player interface. It displays various features and functionalities related to video comments and filtering. The component utilizes hooks such as useState and useEffect to manage state and perform side effects. It receives props from its parent component to customize its behavior and display. The component handles user interactions, such as filtering comments, sorting, and displaying notifications. It also renders a list of comments with their associated information, allowing users to play the video from specific comment timestamps and interact with the comments through various actions.
 *<br/>Breakdown of the code - {@tutorial PlayerSidebar}
 * @module PlayerSidebar
 *
 **/

import { useEffect, useState } from "react";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { MiscFunctions } from 'helper-functions-package';
import { Modal, Spinner } from "flowbite-react";
import { Icon_Click, Icon } from "../Misc/Icon";
import Category from "../../services/CategoryServices";
import Phase from "../../services/SegmentServices";
import Comment from "../../services/CommentServices";
import CommentCard from "../Misc/CommentCard";
import notifyAPI from "../../api/notifyAPI";
import Interactions from "../../services/InteractionsServices";
import SearchField from "../Misc/SearchField";
import ContentInput from "../ContentInput/ContentInput";
import videoAPI from "../../api/videoAPI";
import { useTranslation } from "react-i18next";
import userAPI from "../../api/userAPI";

const PlayerSidebar = (props) => {
  const [videoCaptions, setVideoCaptions] = useState([]);
  const player = props.player;
  const processSteps = props.processSteps;

  const [showHideSortBlock, setShowHideSortBlock] = useState(false);
  const [showHideFilterBlock, setShowHideFilterBlock] = useState(false);
  const [showHideNotifyBlock, setShowHideNotifyBlock] = useState(false);

  const [keywords, setKeywords] = useState("");

  const [companyUsers, setCompanyUsers] = useState(props.companyUsers);
  const [UserObject, setUserObject] = useState(props.UserObject);

  const [ContentAdmin, setContentAdmin] = useState(false);
  const [notificationBell, setNotificationBell] = useState(false);
  const [showVideoInfo, setShowVideoInfo] = useState(false);
  const [videoInfo, setVideoInfo] = useState("");
  const [checkCommentDeleted, setCheckCommentDeleted] = useState(false);
  const [checkCommentDeletedInfo, setCheckCommentDeletedInfo] = useState(false);


  useEffect(() => {
    setCompanyUsers(props.companyUsers);
  }, [props.companyUsers]);

  useEffect(() => {
    setUserObject(props.UserObject);
    checkNotification();
  }, [props.UserObject]);

  const checkNotification = async () => {
    const data = await userAPI.getUserObject();
    if(!MiscFunctions.isNull(data.preferences)){
      if (data.preferences.includes("notifications_comment_app")) {
        setNotificationBell(true);
      } else {
        setNotificationBell(false);
      }
    }
  };
  const [sidebarLoading, setSidebarLoading] = useState(true);

  // This is a temporary and quick implementation for removing the old categories. It should be removed once the old categories are deleted from the database.
  const targetCategoryTypes = ["ask_ai", "comment", "question", "notice"];
  const newFilterCategories = props.filterCategories.filter((item) =>
    targetCategoryTypes.includes(item.type)
  );

  let fitlerCategories = newFilterCategories.map((elem) => {
    let cat = new Category(elem);
    return cat;
  });

  // Add All-Category to the beginning of the array
  fitlerCategories.unshift(
    new Category({
      label: "All",
      icon: "all_category",
      id: "2368ece9-33de-42d0-8e3e-98eb5827ae02",
      status: true,
    })
  );

  const [fitlerCategoryList, setFitlerCategoryList] =
    useState(fitlerCategories);

  const getFilterItem = (name, state) => {
    let cat = fitlerCategoryList.map((item) => {
      let item_new = new Category(item);
      if (name === "All") {
        item_new.setStatus = false;
      } else if (item.label === "All") {
        item_new.setStatus = false;
      }
      if (name === item.label) {
        item_new.setStatus = state;
      }

      return item_new;
    });
    setFitlerCategoryList(cat);
  };

  const getFilterPerson = (ev, elem) => {
    let state = false;
    if (ev.target.className === "filter_person_item_container") {
      state = true;
      ev.target.className = "filter_person_item_container-hover";
    } else {
      ev.target.className = "filter_person_item_container";
    }

    let companyUsersCopy = companyUsers.map((obj) => {
      if (obj.id === elem) {
        obj.filterState = state;
      }
      return obj;
    });
    setCompanyUsers(companyUsersCopy);
  };

  const clickNotify = async (elem, player) => {
    console.log(elem);
    console.log(videoCaptions);
    let val = {};
    if(!MiscFunctions.isUndefined(videoCaptions.Elements)){
      if (elem.video.id === props.workflow.getVideoID) {
        if (elem.parent.id === "null") {
          val = videoCaptions.Elements.find((obj) => obj.id === elem.comment.id);
        } else {
          val = videoCaptions.Elements.find((obj) => obj.id === elem.parent.id);
        }
        console.log(val);
        if(!MiscFunctions.isUndefined(val) && !MiscFunctions.isNull(val)){
          props.getClickedComment(val.id);
          clickComment(val, player);
        const dataNotify = await notifyAPI.notify(elem.id);
        if (dataNotify !== false && !MiscFunctions.isUndefined(dataNotify)) {
          let UserObjectNew = await MiscFunctionsLocal.createUserObject(dataNotify);
          setUserObject(UserObjectNew);
        }
        }else{
        const dataNotify = await notifyAPI.notify(elem.id);
          if (dataNotify !== false && !MiscFunctions.isUndefined(dataNotify)) {
            let UserObjectNew = await MiscFunctionsLocal.createUserObject(dataNotify);
            setUserObject(UserObjectNew);
          }
          setCheckCommentDeletedInfo("Comment already deleted.");
          setCheckCommentDeleted(true);
        }
      } else {
        const videoData = await videoAPI.getVideoById(elem.video.id);
        setVideoInfo(videoData.label);
        setShowVideoInfo(true);
      }
    }
  };
  // On-Click-Callback für the Play-Button in the comment card
  const clickComment = (val, player) => {
    MiscFunctionsLocal.gotoTimestamp(val.getTimeStampSeconds, player);
    var intervalID = setInterval(function () {
      let check = document.getElementById("ann_" + val.getID);
      if (check !== null) {
        clearInterval(intervalID);
        check.classList.add("FadeInAndOut");
        check.addEventListener("animationend", function () {
          check.classList.remove("FadeInAndOut");
        });
      }
    }, 300);
  };

  const onClickOutsideListener = () => {
    if (showHideFilterBlock) {
      document.removeEventListener("click", onClickOutsideListener);
      setShowHideFilterBlock(!showHideFilterBlock);
    }
    if (showHideSortBlock) {
      document.removeEventListener("click", onClickOutsideListener);
      setShowHideSortBlock(!showHideSortBlock);
    }
    if (showHideNotifyBlock) {
      document.removeEventListener("click", onClickOutsideListener);
      setShowHideNotifyBlock(!showHideNotifyBlock);
    }
  };

  const getNotifyText = (elem) => {
    return MiscFunctionsLocal.formatCommentText(elem.content);
  };

  const { t } = useTranslation();

  useEffect(() => {
    videoAPI
      .getVideoInteractionsJsonById(props.workflow.getVideoID)
      .then((response) => {
        if (MiscFunctions.isUndefined(response)) {
          return;
        } else {
          let Captions = new Interactions([]);
          response.map((response) => {
            return (Captions.addComment = new Comment(response));
          });
          setVideoCaptions(Captions);
          setSidebarLoading(false);
          props.updateTextTracks();
        }
      });
  }, [props.addComment])

 useEffect(() => {
  videoAPI
     .getVideoInteractionsJsonById(props.workflow.getVideoID)
     .then((response) => {
       if (MiscFunctions.isUndefined(response)) {
        setSidebarLoading(false);
         return;
       }
     });
}, [])

  useEffect(() => {
    if (!MiscFunctions.isNull(props.deleteCommentId) && !MiscFunctions.isUndefined(videoCaptions)) {
      let Captions = new Interactions([]);
      videoCaptions.Elements.filter(elem => {
        if (elem.id !== props.deleteCommentId) {
          Captions.addComment = new Comment(elem);
        }
      }
      )
      setVideoCaptions(Captions)
    }
  }, [props.deleteCommentId])

  return (
    <>
      <button
        className="button_sign_up comment_side_box_transition"
        style={{
          minWidth: "200px",
          margin: "4px",
          visibility:
            !MiscFunctions.isNull(UserObject) && UserObject.role === "Admin"
              ? "visible"
              : "hidden",
        }}
        onClick={() => {
          setContentAdmin(!ContentAdmin);
        }}
      >
        {t("player_sidebar.content_admin")}{" "}
      </button>

      {
        <SearchField
          stateHandler={(input) => {
            setKeywords(input);
          }}
          iconDirection={"right"}
        />
      }

      {
        <div className="filters_block flex flex-wrap comment_side_box_transition">
          <div className="selected_tags">
            {fitlerCategoryList.map((category, i) => {
              if (category.status) {
                return (
                  <Icon
                    category={category}
                    key={i}
                    changeState={getFilterItem}
                  />
                );
              } else {
                return false;
              }
            })}
          </div>
          <div className="filter-icons">
            <div className="flex flex-row filter-section">
              <span
                className={
                  (showHideFilterBlock && " icon_filter_category_select") +
                  " icon_filter_category"
                }
                onClick={() => {
                  setTimeout(() => {
                    setShowHideFilterBlock(!showHideFilterBlock);
                  }, 300);
                }}
              ></span>

              {showHideFilterBlock && (
                <div
                  className="block filter_block"
                  onMouseLeave={() => {
                    document.addEventListener("click", onClickOutsideListener);
                  }}
                >
                  <div className="flex flex-col text-sm">
                    <div className="flex py-1 px-2 rounded">
                      <div className="context_menu_header">
                        {t("player_sidebar.filter_category")}
                      </div>
                    </div>
                    <hr className="border-gray-300" />

                    {fitlerCategoryList.map((category, i) => {
                      return (
                        <Icon_Click
                          category={category}
                          changeState={getFilterItem}
                          keyName={i}
                          key={i}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            {/* Sort section */}

            {
              <div className="flex flex-row filter-section">
                <span
                  className={
                    (showHideSortBlock && "icon_filter_person_select") +
                    " icon_filter_person"
                  }
                  onClick={() => {
                    setTimeout(() => {
                      setShowHideSortBlock(!showHideSortBlock);
                    }, 300);
                  }}
                ></span>
                {showHideSortBlock && (
                  <div
                    className="block sort_block"
                    onMouseLeave={() => {
                      document.addEventListener(
                        "click",
                        onClickOutsideListener
                      );
                    }}
                  >
                    <div className="flex flex-col text-sm">
                      <div className="flex py-1 px-2 rounded">
                        <div className="context_menu_header">
                          {t("player_sidebar.filter_person")}
                        </div>
                      </div>
                      <hr className="border-gray-300" />
                      <div className="filter-box-list">
                      {companyUsers.map((elem, i) => {
                        return (
                          <div key={i}>
                            <div
                              className={
                                elem.filterState
                                  ? "filter_person_item_container-hover"
                                  : "filter_person_item_container"
                              }
                              style={{
                                height: "30px",
                                alignItems: "center",
                                display: "flex",
                              }}
                              onClick={(ev) => {
                                getFilterPerson(ev, elem.id);
                              }}
                            >
                              {elem.display}
                            </div>
                          </div>
                        );
                      })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }

            {/* Notify section */}

            {notificationBell ? (
              <div className="flex flex-row filter-section">
                <span
                  className={
                    !MiscFunctions.isNull(UserObject) &&
                      UserObject.notifications &&
                      UserObject.notifications.findIndex(
                        (o) => o.read === false
                      ) !== -1
                      ? (showHideNotifyBlock &&
                        "icon_filter_notify_active_select") +
                      " icon_filter_notify_active"
                      : (showHideNotifyBlock && "icon_filter_notify_select") +
                      " icon_filter_notify"
                  }
                  onClick={() => {
                    setTimeout(() => {
                      setShowHideNotifyBlock(!showHideNotifyBlock);
                    }, 300);
                  }}
                ></span>
                {showHideNotifyBlock && (
                  <div
                    className="block notify_block"
                    onMouseLeave={() => {
                      document.addEventListener(
                        "click",
                        onClickOutsideListener
                      );
                    }}
                  >
                    <div className="flex flex-col text-sm">
                      <div className="flex py-1 px-2 rounded">
                        <div className="context_menu_header">
                          {t("general.notifications")}
                        </div>
                      </div>
                      <hr className="border-gray-300" />
                      <div className="filter-box-list">
                        {!UserObject.notifications ? (
                          <p className="text-center pt-3">
                            {t("messages.no_new_notifications")}.
                          </p>
                        ) : (
                          UserObject.notifications.map((elem, i) => {
                            if (!elem.read) {
                              return (
                                <div key={i}>
                                  <div className="notify_box_message">
                                    {t("messages.you_were_mentioned") +
                                      elem.author +
                                      "in a" +
                                      elem.parent ===
                                      null
                                      ? " " + t("messages.comment")
                                      : " " + t("messages.reply_a_comment")}
                                  </div>
                                  <div className="flex flex-row" key={i}>
                                    <div className="notify_box_comment_text">
                                      {getNotifyText(elem)}
                                    </div>
                                    <div
                                      className="selected_tag_container flex"
                                      onClick={() => {
                                        clickNotify(elem, player);
                                      }}
                                    >
                                      {/* Play-Icon */}
                                      <span className="icon_play all-category-icon"></span>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      }
      {/* Comment List section */}
      {ContentAdmin && (
        <Modal
          show={ContentAdmin}
          className="content-admin-box"
          onClose={() => {
            // setCounterPopup(false);
          }}
        >
          <Modal.Body
            className="text-center"
            style={{ overflow: "inherit", flex: "none" }}
            onClick={(e) => e.stopPropagation()}
          >
            <span
              className="button_modal_close"
              onClick={() => setContentAdmin(false)}
            ></span>
            <div className="content_admin_main_block">
              <ContentInput
                workflow={props.workflow}
                phases={props.processSteps}
                tasks={props.tasks}
                UpdateSegments={props.UpdateSegments}
                setRessourcesUpdate={props.setRessourcesUpdate}
                ressourcesUpdate={props.ressourcesUpdate}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}

      {
        <div
          className="all_comments_list"
          style={{ height: props.containerHeight }}
        >
          {sidebarLoading && (
            <div className="flex flex-wrap flex-col gap-2 pt-40 text-center marg">
              <Spinner
                color="success"
                aria-label="loading comments"
                size="xl"
              />
            </div>
          )}
          {MiscFunctions.isUndefined(videoCaptions.Elements) ||
            videoCaptions.Elements.length < 1 ? (
            <p style={{ textAlign: "center" }}>
              {t("messages.no_result_found")}!
            </p>
          ) : (
            videoCaptions
              .getElementsOfKeywords(keywords)
              .Elements.map((comment, i) => {
                if(!MiscFunctions.isNull(comment.company) && !MiscFunctions.isNull(comment.user) && 
                ((comment.visibility === "public" && comment.company.id === UserObject.UserCompany) || 
                (comment.visibility === "private" && comment.user.id === UserObject.UserID))){
                // Search through the CategoryList to find the corresponding object and it's status
                let match = false;
                // ToDo change the filtering
                if (
                  !MiscFunctions.isUndefined(
                    companyUsers.find((o) => {
                      return (
                        comment.getUserName.indexOf(o.display) !== -1 &&
                        o.filterState
                      );
                    })
                  ) ||
                  MiscFunctions.isUndefined(
                    companyUsers.find((o) => {
                      return o.filterState;
                    })
                  )
                ) {
                  if (fitlerCategoryList[0].status) {
                    // In the case, that "All-Categories" is picked
                    match = true;
                  } else {
                    comment.category.map((cat, j) => {
                      let obj = fitlerCategoryList.find((o) => o.id === cat.id);
                      if (obj && obj.status) {
                        match = true;
                      }
                    });
                  }
                }

                if (match) {
                  let processName = new Phase();
                  if (
                    !MiscFunctions.isUndefined(processSteps) &&
                    !MiscFunctions.isNull(processSteps)
                  ) {
                    processName.assignData = processSteps.Elements.find(
                      (elem) => {
                        if (
                          elem.getTimeStampSeconds <=
                          comment.getTimeStampSeconds &&
                          elem.getTimeStampSeconds + elem.getDuration >
                          comment.getTimeStampSeconds
                        ) {
                          return 1;
                        }
                      }
                    );
                  }

                  return (
                    <CommentCard
                      comment={comment}
                      player={player}
                      process={processName}
                      key={i}
                      getClickedComment={props.getClickedComment}
                      commentCreate={props.commentCreate}
                      setCommentCreate={props.setCommentCreate}
                      setClickPlayIconInSiderbar={props.setClickPlayIconInSiderbar}
                    />
                  );
                }
              }
              })
          )}
        </div>
      }
      <Modal
        show={showVideoInfo}
        size="md"
        onClose={() => setShowVideoInfo(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="videoNotificationInfo">
              {t("messages.video_notification_info") + videoInfo}
            </h3>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={checkCommentDeleted}
        size="md"
        onClose={() => setCheckCommentDeleted(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="videoNotificationInfo">
              {checkCommentDeletedInfo}
            </h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlayerSidebar;
