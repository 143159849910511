/**
 * @file QuickOverviewTabs.js
 * @created_date Thursday, November 30, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component manages the rendering of tabs and associated content. It groups resources by type, renders tab headers, and displays the content of the active tab along with an associated image. The active tab can be changed by clicking on a different tab.
 *<br/>Breakdown of the code - {@tutorial QuickOverviewTabs}
 * @module QuickOverviewTabs
 *
 **/

import React, { Component } from "react";
import QuickOverviewTags from "./QuickOverviewTags";
import medimelogo from "../../assets/img/logo_mediMESH_1500x300.png";
import { MiscFunctions } from 'helper-functions-package';
class QuickOverviewTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.tabs[0],
      tabs: props.tabs,
    };
  }

  handleTabClick = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const resourcesByType = {};
    this.state.tabs.forEach((resource) => {
      if (!resourcesByType[resource.type]) {
        resourcesByType[resource.type] = [];
      }
      resourcesByType[resource.type].push(resource);
    });
    return (
      <div className="drop-down-tabs-main-block">
        <div className="drop-down-tabs-block">
          <div className="drop-down-tabs-scroll">
            {Object.keys(resourcesByType).map((type, index) => {
              return (
                <div key={index}>
                  <p className="drop-down-tabs-type-headline">
                    {type.replace(/_/g, " ")}
                  </p>
                  <>
                    {" "}
                    <QuickOverviewTags
                      resourcesByType={resourcesByType}
                      type={type}
                      tabs={this.state.tabs}
                      activeTab={resourcesByType[type][0]}
                      handleTabClick={(tab) => this.handleTabClick(tab)}
                    />
                  </>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="drop-down-right-content-img-block"
          style={{
            backgroundImage: `url("${
              this.state.activeTab
                ? !MiscFunctions.isNull(this.state.activeTab.picture)
                  ? this.state.activeTab.picture
                  : medimelogo
                : ""
            }")`,
          }}
        ></div>
      </div>
    );
  }
}

export default QuickOverviewTabs;
