/**
 *
 * @file workflowAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to workflow authentication for the application, These functions are organized within the workflowAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial workflowAPI}
 * @module workflowAPI
 *
 **/
import { getAuthAccessObject } from "../utils/tokenStorage";
import { apiClient } from 'my-api-client-package';
const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * @description
 * <br />Here you can find some examples: - {@tutorial workflowAPI}
 * @function getUploadLink
 * @return {void}
 * */
const getWorkflowList = async () => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/workflow/list`, customHeaders)
      .then(async function (response) {
        // console.log("inside endpoint call: ",await response.json());
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          const data = await JSON.parse(resp.message);
          return data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description des
 * @function getWorkflowByID
 * @return {void}
 * */
const getWorkflowByID = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/workflow/${id}`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        console.log(resp)
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          const data = await JSON.parse(resp.message);
          return data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a PUT request to the /workflow endpoint to change it's content in the DB. It includes the access token and reCAPTCHA value in the headers. It returns the response object.
 * <br />Here you can find some examples: - {@tutorial workflowAPI}
 * @function changeWorkflow
 * @return {void}
 * */
const changeWorkflow = async (captchaValue, data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: captchaValue,
    };

    return await apiClient
      .put(API_BASE_URL, `/workflow`, data, customHeaders)
      .then(async function (response) {
        const resp = await response.text();
        return resp;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const WorkflowAPI = {
  getWorkflowList,
  changeWorkflow,
  getWorkflowByID,
};

export default WorkflowAPI;
