/**
 * @file GeneralDropDown.js
 * @created_date Thursday, November 30, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component represents a dropdown tab with associated content. It manages the visibility of the content, renders a tab with a label and icon, and includes an optional dropdown icon. The component receives props for controlling its state, including the visibility of the content, and provides a callback for handling tab clicks. The dropdown content is rendered using the QuickOverviewContent component.
 *<br/>Breakdown of the code - {@tutorial GeneralDropDown}
 * @module GeneralDropDown
 *
 **/

import React from "react";
import QuickOverviewContent from "../IntroductionPopups/QuickOverviewContent";
import { Spinner } from "flowbite-react";

class GeneralDropDown extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isShowContent: props.isOpen,
      content: props.content,
      loadingSpiner: props.loadingSpiner,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isShowContent: this.props.isOpen });
    }
    if (prevProps.content !== this.props.content) {
      this.setState({ content: this.props.content });
    }
    if (prevProps.loadingSpiner !== this.props.loadingSpiner) {
      this.setState({ loadingSpiner: this.props.loadingSpiner });
    }
  }

  render() {
    return (
      <div
        className={
          "drop-down-main-block " +
          (this.state.isShowContent ? "" : "drop-down-main-block-border-bottom")
        }
      >
        <div
          className={
            "drop-down-tab-block " +
            (this.state.isShowContent ? "drop-down-tab-block-active" : "")
          }
          onClick={() => {
            this.props.onClick();
          }}
        >
          <span
            className={
              "drop-down-tab-icon-" +
              this.props.tag +
              (this.state.isShowContent ? "-selected" : "")
            }
          ></span>
          <h2 className="drop-down-tab-heading">{this.props.label}</h2>
          <span
            className={
              !this.state.isShowContent
                ? "drop-down-tab-dropdown-icon"
                : "drop-down-tab-dropdown-icon-selected"
            }
          ></span>
        </div>
        {this.state.loadingSpiner && this.state.isShowContent && (
          <Spinner color="success" aria-label="loading categories" size="sm" />
        )}
        {this.state.isShowContent && !this.state.loadingSpiner && (
          <>
            <QuickOverviewContent
              content={this.state.content}
              rightSide={this.props.rightSide}
              contentType={this.props.contentType}
            />
          </>
        )}
      </div>
    );
  }
}

export default GeneralDropDown;
