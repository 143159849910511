import React, { Component } from "react";
import CommentUploadedFile from "./CommentUploadedFile";
import { withTranslation } from "react-i18next";
import Swal from "sweetalert2";
import commentAPI from "../../api/commentAPI";
import GeneralToggleButton from "../Misc/GeneralToggleButton";
import MediaUploadUI from "../ContentInput/MediaUploadUI";
import { MiscFunctions } from 'helper-functions-package';

class CommentFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentView: this.props.commentDisplay ? true : false,
      commentEdit: this.props.commentEdit ? true : false,
      commentReply: this.props.commentReply ? true : false,
      checkCommentIsReply: this.props.checkCommentIsReply ? true : false,
      role: this.props.user.role,
      toggleStatus: false,
      openSettings: false,
      openUpload: false,
      isAccordionOpen: false,
      autoplay:
        this.props.commentUpdate === true
          ? this.props.commentForUpdate.getAutoPlay
          : false,
      stopWhenReached:
        this.props.commentUpdate === true
          ? this.props.commentForUpdate.getStopWhenReached
          : false,
      allowReplies:
        this.props.commentUpdate === true
          ? this.props.commentForUpdate.getAllowReplies
          : false,
      commentVisibilityStatus:
        this.props.commentUpdate === true
          ? this.props.commentForUpdate.getVisibility === "public"
            ? true
            : false
          : true,
      switchSide: this.props.switchSide,
      checkSettingPanel: this.props.checkSettingPanel,
    };
  }
  confirmDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4baaac",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        commentAPI
          .deleteCommentById(id)
          .then((response) => {
            if (response === undefined) {
              Swal.fire({
                title: "Error!",
                text: "Comment not deleted. You're neither admin nor owner!",
                icon: "error",
                confirmButtonColor: "#4baaac",
              });
            } else {
              this.props.setDeleteComment(!this.props.deleteComment);
              this.props.setDeleteCommentId(this.props.clickedComment.getID);
              Swal.fire({
                title: "Deleted!",
                text: "Comment has been deleted.",
                icon: "success",
                confirmButtonColor: "#4baaac",
              });
              this.props.closeDislayComment();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(MiscFunctions.isNull(document.getElementById("comment-setting-block-id")) && this.state.openSettings === true && this.props.checkSettingPanel === false){
        this.handleOpenSettings();
        this.props.handleSettingPanel(true);
    }

    if(this.state.checkSettingPanel !== this.props.checkSettingPanel){
      this.setState({checkSettingPanel: this.props.checkSettingPanel})
    }
    if (prevProps.commentUpdate !== this.props.commentUpdate) {
      if (this.props.commentUpdate !== true)
        this.setState({
          openSettings: false,
          autoplay: false,
          stopWhenReached: false,
          allowReplies: false,
        });
    }
    if (prevProps.switchSide !== this.props.switchSide) {
      this.setState({ switchSide: this.props.switchSide });
    }
  }

  handleAutoplayToggle = (status) => {
    this.setState({ autoplay: status });
    this.props.handleAutoplaySettings(status);
    if(status === true){
      this.handleStopWhenReachedToggle(status)
    }
  };

  handleStopWhenReachedToggle = (status) => {
    this.setState({ stopWhenReached: status });
    this.props.handleStopWhenReached(status);
  };

  handleAllowRepliesToggle = (status) => {
    this.setState({ allowReplies: status });
    this.props.handleAllowReplies(status);
  };

  handleSetCommentStatus = async (status) => {
    this.setState({ commentVisibilityStatus: status });
    this.props.handleSetCommentStatus(status);
  };

  handleOpenSettings = () => {
    this.setState((prevState) => ({
      openSettings: !prevState.openSettings,
      openUpload: false,
    }));
  };

  handleOpenUpload = () => {
    this.setState((prevState) => ({
      openUpload: !prevState.openUpload,
      openSettings: false,
    }));
  };

  toggleAccordion = () => {
    this.setState({ isAccordionOpen: !this.state.isAccordionOpen });
  };

  handlePictureVideo = (elem, fileInfo) => {
    this.props.addToMedias(elem);
    this.setState({ openUpload: false });
  };

  handleDocument = (elem, fileInfo) => {
    this.props.addToMedias(elem);
    this.setState({ openUpload: false });
  };

  render() {
    const { switchSide } = this.state;
    return (
      <>
        {<div
          className="comment-footer-block"
          style={{
            borderRadius: `${!MiscFunctions.isNull(switchSide) &&
              !MiscFunctions.isUndefined(switchSide) &&
              switchSide === "left"
              ? "0 0 5px 0"
              : "0 0 0 5px"
              }`,
          }}
        >
          {this.state.commentEdit && (
            <CommentUploadedFile
              changeValue={this.props.changeValue}
              changeCommentHeight={this.props.changeCommentHeight}
              commentMedias={this.props.commentMedias}
              setMiniSlider={this.props.setMiniSlider}
              commentEdit={true}
              user={this.props.user}
              setUpdateMediaFile={this.props.setUpdateMediaFile}
              updateMediaFile={this.props.updateMediaFile}
              commentUpdate={this.props.commentUpdate}
              clickedComment={this.props.clickedComment}
              commentForUpdate={this.props.commentForUpdate}
              commentForReply={this.props.commentForReply}
            />
          )}
          {this.state.commentReply && (
            <CommentUploadedFile
              changeValue={this.props.changeValue}
              changeCommentHeight={this.props.changeCommentHeight}
              commentMedias={this.props.commentMedias}
              setMiniSlider={this.props.setMiniSlider}
              commentEdit={false}
              user={this.props.user}
              setUpdateMediaFile={this.props.setUpdateMediaFile}
              updateMediaFile={this.props.updateMediaFile}
              commentUpdate={this.props.commentUpdate}
              commentReply={this.props.commentReply}
              clickedComment={undefined}
              setFileIndex={this.props.setFileIndex}
              commentForUpdate={this.props.commentForUpdate}
              commentForReply={this.props.commentForReply}
            />
          )}
          {this.props.editCommentReply && this.props.commentForReply.id === this.props.clickedComment.id && (
            <CommentUploadedFile
              changeValue={this.props.changeValue}
              changeCommentHeight={this.props.changeCommentHeight}
              commentMedias={this.props.commentMedias}
              setMiniSlider={this.props.setMiniSlider}
              user={this.props.user}
              setUpdateMediaFile={this.props.setUpdateMediaFile}
              updateMediaFile={this.props.updateMediaFile}
              commentUpdate={this.props.commentUpdate}
              editCommentReply={this.props.editCommentReply}
              clickedComment={this.props.clickedComment}
              setFileIndex={this.props.setFileIndex}
              commentEdit={this.props.commentEdit}
              commentForUpdate={this.props.commentForUpdate}
              commentForReply={this.props.commentForReply}
            />
          )}
          {this.state.commentView === true && !(this.props.editCommentReply && this.props.commentForReply.id === this.props.clickedComment.id) && (
            <>
              {this.props.clickedComment.medias !== undefined &&
                this.props.clickedComment.medias.length > 0 && (
                  <CommentUploadedFile
                    changeValue={this.props.changeValue}
                    changeCommentHeight={this.props.changeCommentHeight}
                    clickedComment={this.props.clickedComment}
                    setMiniSlider={this.props.setMiniSlider}
                    commentEdit={false}
                    setFileIndex={this.props.setFileIndex}
                    user={this.props.user}
                    setUpdateMediaFile={this.props.setUpdateMediaFile}
                    updateMediaFile={this.props.updateMediaFile}
                    commentUpdate={this.props.commentUpdate}
                    commentReply={this.props.commentReply}
                    editCommentReply={this.props.editCommentReply}
                    commentForUpdate={this.props.commentForUpdate}
                    commentForReply={this.props.commentForReply}
                    checkCommentIsReply={this.props.checkCommentIsReply}
                  />
                )}
            </>
          )}
          {(this.state.commentReply || (this.props.editCommentReply && this.props.commentForReply.id === this.props.clickedComment.id)) &&  
           <div className="comment-footer-detial-feature">
              <>
                {this.state.openUpload === true && (
                  <div className="comment-upload-block">
                    <MediaUploadUI
                      label={
                        <>
                          <span className="comment_upload_icon_small"></span>
                          <span className="comment-setting-option-text">
                            Document{" "}
                            <span className="comment-text-gray">(50MB)</span>
                          </span>
                        </>
                      }
                      buttonClass="comment-upload-option"
                      selectHandler={this.handleDocument}
                      fileType={"doc"}
                      description={false}
                    />

                  <MediaUploadUI
                    label={
                      <>
                        <span className="comment_picture_video_icon"></span>
                        <span className="comment-setting-option-text">
                          Audio, Picture, Video{" "}
                          <span className="comment-text-gray">(5GB)</span>
                        </span>
                      </>
                    }
                    buttonClass="comment-upload-option"
                    selectHandler={this.handlePictureVideo}
                    border={15}
                    imageWidth={1600}
                    imageHeight={900}
                    borderRadius={0}
                    reScale={true}
                    rotate={true}
                    fileType={"image,video"}
                    description={false}
                  />
                </div>
              )}
              <span
                className={`${this.state.openUpload
                  ? "comment_upload_icon_active"
                  : "comment_upload_icon"
                  }`}
                onClick={() => this.handleOpenUpload()}
              ></span>
            </>
          </div>}
          {this.state.commentEdit && (
            <div className="comment-footer-detial-feature">
              {this.state.role.toLowerCase() === "admin" && (
                <>
                  {this.state.openSettings === true && (
                    <div className="comment-setting-block"
                    id={`${(this.state.openSettings && this.props.checkSettingPanel===true)
                      ? "comment-setting-block-id"
                      : ""
                      }`}>
                      <span className="comment-setting-option">
                        <span className="comment-setting-option-text">
                          Pause the video when the comment is reached
                        </span>
                        <GeneralToggleButton
                          isActive={this.state.stopWhenReached}
                          onToggle={this.handleStopWhenReachedToggle}
                          size="small" //leave empty for big size, add small for small size.
                        />
                      </span>

                      <span className="comment-setting-option">
                        <span className="comment-setting-option-text">
                          Autoplay (Video, Audio, Text to Speech)
                        </span>
                        <GeneralToggleButton
                          isActive={this.state.autoplay}
                          onToggle={this.handleAutoplayToggle}
                          size="small" //leave empty for big size, add small for small size.
                        />
                      </span>

                      <span className="comment-setting-option">
                        <span className="comment-setting-option-text">
                          Allow reply
                        </span>
                        <GeneralToggleButton
                          isActive={this.state.allowReplies}
                          onToggle={this.handleAllowRepliesToggle}
                          size="small" //leave empty for big size, add small for small size.
                        />
                      </span>

                      <span className="comment-setting-option">
                        <span className="comment-setting-option-text">
                          Make comment public
                        </span>
                        <GeneralToggleButton
                          isActive={this.state.commentVisibilityStatus}
                          onToggle={this.handleSetCommentStatus}
                          size="small" //leave empty for big size, add small for small size.
                        />
                      </span>
                    </div>
                  )}
                  <span
                    className={`${this.state.openSettings
                      ? "comment_setting_icon_active"
                      : "comment_setting_icon"
                      }`}
                    onClick={() => this.handleOpenSettings()}
                  ></span>
                </>
              )}
              <>
                {this.state.openUpload === true && (
                  <div className="comment-upload-block">
                    <MediaUploadUI
                      label={
                        <>
                          <span className="comment_upload_icon_small"></span>
                          <span className="comment-setting-option-text">
                            Document{" "}
                            <span className="comment-text-gray">(50MB)</span>
                          </span>
                        </>
                      }
                      buttonClass="comment-upload-option"
                      selectHandler={this.handleDocument}
                      fileType={"doc"}
                      description={false}
                    />

                    <MediaUploadUI
                      label={
                        <>
                          <span className="comment_picture_video_icon"></span>
                          <span className="comment-setting-option-text">
                            Audio, Picture, Video{" "}
                            <span className="comment-text-gray">(5GB)</span>
                          </span>
                        </>
                      }
                      buttonClass="comment-upload-option"
                      selectHandler={this.handlePictureVideo}
                      border={15}
                      imageWidth={1600}
                      imageHeight={900}
                      borderRadius={0}
                      reScale={true}
                      rotate={true}
                      fileType={"image,video,audio"}
                      description={false}
                    />
                  </div>
                )}
                <span
                  className={`${this.state.openUpload
                    ? "comment_upload_icon_active"
                    : "comment_upload_icon"
                    }`}
                  onClick={() => this.handleOpenUpload()}
                ></span>
              </>
            </div>
          )}
        </div>}

        {(!this.state.commentEdit && !this.props.commentReplyEdit && !(this.props.editCommentReply && !MiscFunctions.isUndefined(this.props.commentForReply) && this.props.commentForReply.id === this.props.clickedComment.id)) && <div className="comment-footer-detial">
          <span className="comment-footer-date-block">
            <span>
              {!MiscFunctions.isUndefined(this.props.clickedComment)
                ? "Changed: "
                : "Creation: "}
            </span>
            <span>
              {(!MiscFunctions.isUndefined(this.props.clickedComment) && this.props.clickedComment.getIsDateChanged)
                ? this.props.clickedComment.getDateChangedFormat
                : this.props.clickedComment.getDateCreatedFormat}
            </span>
          </span>
          {/* This condition is cheked with userObject, if the user is admin or if the comment owner then the icon will be visible */}
          {(this.state.role.toLowerCase() === "admin" ||
            this.props.clickedComment.getUserID ===
            this.props.user.UserID) && (
              <span
                className="comment-footer-delete-bin"
                onClick={() =>
                  this.confirmDelete(this.props.clickedComment.getID)
                }
              ></span>
            )}
        </div>}
      </>
    );
  }
}

export default withTranslation()(CommentFooter);
