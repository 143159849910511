/**
 *
 * @file VideoService.js
 * @created_date Monday, November 13, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description The VideoService class encapsulates video-related data, providing a structured way to access information such as the video's ID, thumbnail, description, and more. It includes getter functions for retrieving specific attributes and a JSON getter function for generating data suitable for HTTP requests.
 * <br/>Example use age of code - {@tutorial VideoService}
 * @module VideoService
 *
 **/

import { MiscFunctions } from 'helper-functions-package';

class VideoService {
  constructor(input) {
    this.videoID = !MiscFunctions.isNull(input.id) ? input.id.id : null;
    this.thumbnail = !MiscFunctions.isNull(input.thumbnail) ? input.thumbnail : null;
    this.picture = !MiscFunctions.isNull(input.picture) ? input.picture : null;
    this.description = !MiscFunctions.isNull(input.description) ? input.description : null;
    this.label = !MiscFunctions.isNull(input.label) ? input.label : null;
    this.media = !MiscFunctions.isNull(input.media) ? input.media : null;
    this.duration = !MiscFunctions.isNull(input.duration) ? input.duration : null;
    this.uploadTime = !MiscFunctions.isNull(input.uploadtime) ? input.uploadtime : null;
    this.tags = !MiscFunctions.isNull(input.tags) ? input.tags.id : null;
    this.phases = !MiscFunctions.isNull(input.phases) ? input.phases : null;
    this.contexts = !MiscFunctions.isNull(input.contexts) ? input.contexts : null;
  }

  get getVideoID() {
    return this.videoID;
  }

  get getThumbnail() {
    return this.thumbnail;
  }

  get getPicture() {
    return this.picture;
  }

  get getDescription() {
    return this.description;
  }

  get getLabel() {
    return this.label;
  }

  get getMedia() {
    return this.media;
  }

  get getDuration() {
    return this.duration;
  }

  get getUploadTime() {
    return this.uploadTime;
  }

  get getTags() {
    return this.tags;
  }

  get getPhases() {
    return this.phases;
  }

  //
  /**
   * @description Getter-Function to retrieve the json required for POST/PUT-Request
   * @return {String} JSON
   **/
  get json() {
    let video = {
      id: {
        id: !MiscFunctions.isUndefined(this.videoID) ? this.videoID : this.id ,
        tag: "video",
      },
      label: this.label,
    };

    if (!MiscFunctions.isNull(this.context)) {
      video["contexts"] = this.contexts.map((context) => {
        return context;
      });
    }
    // if (this.categoryies!==null){
    //   workflow["category"] = this.categories.map((category)=>{return category.id});
    // };
    // if (this.description!==null){
    //   video["description"] = this.description;
    // };

    return video;
  }
}

export default VideoService;
