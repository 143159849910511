/**
 *
 * @file ResetPassword.js
 * @created_date Thurseday, Jan 19, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The ResetPassword component is responsible for rendering a password reset feature in a user interface. It displays a modal or popup window that allows the user to reset their password.
 * <br />Here's what it does: - {@tutorial ResetPassword}
 * @module ResetPassword
 **/

import React, { useState } from "react";
import backgroundImage from "../assets/img/normal_background_img_1.jpg";
import { useSearchParams } from "react-router-dom";
import { Modal } from "flowbite-react";
import IndecatorUserProfile from "../assets/img/indicator_user_profile.png";
import ResetPasswordForm from "./ResetPasswordForm";
import { useTranslation } from "react-i18next";

// This method is for reseting password.
function ResetPassword() {
  // This statement is for inline css of this page.
  const mainPageStyles = {
    backgroundImage: `url(${backgroundImage})`,
  };

  const [resetPopupVisible, setResetPopupVisible] = useState(true);
  const handleOnCloseReset = () => {
    setResetPopupVisible(false);
  };

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams.get("email"));
  console.log(searchParams.get("token"));
  return (
    <div className="container-reset-page" style={mainPageStyles}>
      <div className="landing_header float-right mr-1">
        <Modal
          show={resetPopupVisible}
          className="register_main_modal"
          onClick={() => setResetPopupVisible(false)}
          onClose={() => {
            setResetPopupVisible(false);
          }}
        >
          <Modal.Body
            className="reset_popup_body"
            style={{ overflow: "inherit", flex: "none" }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col items-center justify-center mx-3 my-3">
              <div className="block-resetw-full relative max-w-5xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                <img
                  src={IndecatorUserProfile}
                  alt=""
                  className="user_role_image_reset"
                />
                <span
                  className="button_modal_close"
                  onClick={handleOnCloseReset}
                ></span>
                <h4
                  className="text-xl font-medium text-gray-900 dark:text-white text-center"
                  id="label_login_header"
                >
                  {t('login_register_page.set_new_password')}
                </h4>
                <h5
                  className="text-xl font-medium text-gray-900 dark:text-white text-center"
                  id="label_reset-password_description"
                >
                  {t('login_register_page.set_new_password_for')} medi
                  <span className="boldColored">MESH</span>{" "}
                  <span className="boldOnly"> {t('general.account')}.</span>
                </h5>

                <p id="label_reset_password_call-to-action">
                  {t('login_register_page.agree_terms_password')}
                </p>
                <ResetPasswordForm
                  setResetPopupVisible={setResetPopupVisible}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default ResetPassword;
