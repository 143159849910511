/**
 * @file QuickOverviewTags.js
 * @created_date Thursday, November 30, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component manages the rendering of tabs and associated content within the Quick Overview. It tracks the active tab on hover and click, handles tab clicks, and displays tab labels and descriptions based on the hovered or clicked tab. The component communicates with the parent component by invoking the handleTabClick method.
 *<br/>Breakdown of the code - {@tutorial QuickOverviewTags}
 * @module QuickOverviewTags
 *
 **/

import React, { Component } from "react";

class QuickOverviewTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // activeTabHover: null, // Track the active tab on hover
      activeTabClick: this.props.activeTab, // Track the active tab on click
      tabs: this.props.tabs,
      resourcesByType: this.props.resourcesByType,
      type: this.props.type,
      isHovered: false, // Track whether the component is being hovered
    };
  }

  handleTabClick = (tab) => {
    this.setState({ activeTabClick: tab });
    this.props.handleTabClick(tab);
  };

  render() {
    return (
      <>
        <ul className="drop-down-tab-list">
          {this.state.resourcesByType[this.state.type].map((tab, index) => {
            return (
              <li
                key={index}
                id={tab.id}
                className={
                  tab === this.state.activeTabClick
                    ? "active drop-down-button"
                    : "drop-down-button"
                }
                onClick={() => this.handleTabClick(tab)}
                // onMouseOver={() => {
                //   this.setState({ activeTabHover: tab, isHovered: true });
                // }}
                // onMouseOut={() => {
                //   this.setState({ activeTabHover: null, isHovered: false });
                // }}
              >
                {tab.label}
              </li>
            );
          })}
        </ul>
        <div
          className={`drop-down-tab-content drop-down-resources-text ${
            this.state.isHovered ? "drop-down-resources-text-hover" : ""
          }`}
        >
          <p>
            {this.state.activeTabClick &&
              this.state.activeTabClick.type === this.state.type && (
                <span>
                  {
                  // this.state.activeTabHover
                  //   ? this.state.activeTabHover.description
                  //   : 
                    this.state.activeTabClick.description
                    }
                </span>
              )}
          </p>
        </div>
      </>
    );
  }
}

export default QuickOverviewTags;
