/**
 *
 * @file CategoryServices.js
 * @created_date Friday, July 28, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The Category class encapsulates the characteristics of a category, such as its label, icon, type, status (active or non-active), and identifier. It provides setter functions to modify the status, allowing for dynamic updates.
 * <br/>Example use age of code - {@tutorial CategoryServices}
 * @module CategoryServices
 *
 **/

import { MiscFunctions } from 'helper-functions-package';

/** Class for handling the Category-Object. */
class Category {
  constructor(input) {
    this.label = input.label;
    this.icon = input.icon;
    this.type = input.type;
    this.status = !MiscFunctions.isUndefined(input.status) ? input.status : false;
    this.id = !MiscFunctions.isUndefined(input.id.id) ? input.id.id : input.id;
  }

  // Setter-Function to set the status of the object (active: status=true, non-active: status=false)
  set setStatus(input) {
    this.status = input;
  }

  // Setter-Function to toggle the status of the object
  set toggleStatus(input) {
    this.status = !this.status;
  }
}

export default Category;
