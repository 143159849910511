import React from "react";
import SettingsNav from "./SettingsNav";
import PersonalData from './PersonalData';
import Account from './Account';
import Communication from './Communication';
import Layout from './Layout';
import Security from './Security';
import ChangePassword from './ChangePassword'; // Import the new ChangePassword component
import Language from "./Language";
import Notification from "./Notification";
import DeleteAccount from "./DeleteAccount";

class SettingsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMenu: 'personal_data', // Default active menu item
        };
    }
    
    setActiveMenu = (menuTag) => {
        this.setState({ activeMenu: menuTag });
    };

    renderComponent = () => {
        const { activeMenu } = this.state;
        switch (activeMenu) {
            case 'personal_data':
                return <PersonalData userMainObject={this.props.userMainObject} hotRefreshUserObject={(object) => this.props.hotRefreshUserObject(object)} />;
            case 'account':
                return <Account />;
            case 'delete_account':
                return <DeleteAccount />;
            case 'communication':
                return <Communication />;
            case 'notification_within_app':
                return <Notification activeSubmenu={activeMenu} 
                userMainObject={this.props.userMainObject}
                hotRefreshUserObject={(object) => this.props.hotRefreshUserObject(object)}/>;
            case 'email_notification':
                return <Notification activeSubmenu={activeMenu} 
                userMainObject={this.props.userMainObject}
                hotRefreshUserObject={(object) => this.props.hotRefreshUserObject(object)}/>;
            case 'layout':
                return <Layout />;
            case 'language':
                return <Language />;
            case 'security':
                return <Security />;
            case 'change_password': // Handle the 'change_password' submenu item
                return <ChangePassword />;
            default:
                return null;
        }
    };

    render() {
        return (
            <div className="settings-container">
                <SettingsNav activeMenu={this.state.activeMenu} setActiveMenu={this.setActiveMenu} />
                <div className="settings-section">
                    {this.renderComponent()}
                </div>
            </div>
        );
    }
}

export default SettingsContainer;
