import React, { Component } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

class PDFViewer extends Component {
  render() {
    return (
      <div className="pdf-preview">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer fileUrl={this.props.fileUrl} />
        </Worker>
      </div>
    );
  }
}

export default PDFViewer;
