/**
 * @file RessourceObject_RoomInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description The `RessourceObject_RoomInput` component is a React form for capturing room information. It includes input fields for the room's name and description, along with logic for submitting data to the server via an API. The form provides visual feedback, displaying success or failure icons after submission. Overall, it is designed for integration within a broader application managing resource objects.
 *<br/>Breakdown of the code - {@tutorial RessourceObject_RoomInput}
 * @module RessourceObject_RoomInput
 *
 **/

import React from "react";
import ressourceObjectAPI from "../../api/ressourceObjectAPI";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";
import StringInput from "./StringInput";
import TextInput from "./TextInput";
import { withTranslation } from "react-i18next";
class RessourceObject_RoomInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      name: "",
      description: "",
      sendSuccess: null,
      saveData: false,
    };
  }

  handleDescription = (string) => {
    console.log(string);
    this.setState({ description: string });
  };
  handleName = (string) => {
    this.setState({ name: string });
  };

  handleSendSuccess = (e) => {
    this.setState({ sendSuccess: e });
  };

  handleSubmit = async () => {
    this.setState({ saveData: true });
    let data = {
      label: this.state.name,
      description: this.state.description,
      type: "RoomDto",
    };
    const res = await ressourceObjectAPI.postRessource(data);
    if (res.status === 200 || res.status === 201) {
      this.setState({
        saveData: false,
      });
      this.handleDescription("");
      this.handleName("");
      this.handleSendSuccess(true);
    } else {
      this.handleSendSuccess(false);
    }
  };

  onClickOutsideListener = () => { };

  componentWillUnmount() { }

  render() {
    return (
      <div>
        <StringInput
          label={this.props.t('general.name')}
          setValue={this.handleName}
          value={this.state.name}
        />
        {/* <ListInput 
                label="Room-Template"
                Elements={this.RoomTemplates.Elements}
                selectHandler={this.handleRoom}
            /> */}
        <TextInput
          label={this.props.t('general.description')}
          rows="9"
          setValue={this.handleDescription}
          value={this.state.description}
        />
        <button
          onClick={() => {
            this.handleSubmit();
          }}
          className="content-admin-save-btn"
          disabled={!this.state.name || !this.state.description ? true : false}
        >
          {this.props.t('general.enter')}
        </button>
        {this.state.sendSuccess === true && (
          <CheckBadgeIcon className="h-6 w-6" />
        )}
        {this.state.sendSuccess === false && (
          <XCircleIcon className="h-6 w-6" />
        )}
      </div>
    );
  }
}

export default withTranslation()(RessourceObject_RoomInput);
