/**
 *
 * @file ContextServices.js
 * @created_date Friday, November 17, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This class is for handling of the Context-Objects, it encapsulates various functions for handling and retrieving information related to single context objects.
 * <br/>Example use age of code - {@tutorial ContextServices}
 * @module ContextServices
 *
 **/

/** Class for handling the Context-Objects. */
class Context {
  constructor(json) {
    Object.keys(json).map((key) => {
      this[key] = json[key];
    });
  }

  getValueByString(string) {
    let mapping = JSON.parse(this.mapping);
    for (let feld in mapping) {
      if (mapping[feld] === string) {
        return this[feld];
      }
    }
    return null;
  }
}

export default Context;
