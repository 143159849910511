/**
 * @file TaskMetaInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This component serves as a dynamic input selector for either tasks or resources within the video workflow. Users can toggle between "Task" and "Resource" sections using buttons, each triggering a change in the displayed input form. The internal state, managed by `selectedElement`, keeps track of the active input mode. Depending on the selection, either the `SegmentInput` or `RessourceInput` component is rendered, providing a streamlined interface for users to input relevant details based on their choice.
 *<br/>Breakdown of the code - {@tutorial TaskMetaInput}
 * @module TaskMetaInput
 *
 **/

import React from "react";
import SegmentInput from "./SegmentInput";
import RessourceInput from "./RessourceInput";
import { Button } from "flowbite-react";
import { withTranslation } from "react-i18next";

class TaskMetaInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      selectedElement: "",
    };
  }

  onClickOutsideListener = () => { };

  componentWillUnmount() { }

  render() {
    return (
      <div>
        <div className="flex flex-row">
          <>
            <Button.Group outline>
              <Button
                // color="gray"
                style={{
                  width: "100px",
                  "background-color":
                    this.state.selectedElement === "task"
                      ? "hsl(180, 100%, 60%)"
                      : "hsl(180, 100%, 40%)",
                }}
                onClick={() => {
                  this.setState({ selectedElement: "task" });
                }}
                className={"focus:ring-5"}
              >
                {this.props.t('general.task')}
              </Button>
              <Button
                style={{
                  width: "100px",
                  "background-color":
                    this.state.selectedElement === "ressource"
                      ? "hsl(180, 100%, 60%)"
                      : "hsl(180, 100%, 40%)",
                }}
                onClick={() => {
                  this.setState({ selectedElement: "ressource" });
                }}
                className={"focus:ring-5"}
              >
                {this.props.t('general.resource')}
              </Button>
            </Button.Group>
          </>
        </div>
        {this.state.selectedElement === "task" && (
          <SegmentInput
            workflow={this.props.workflow}
            segments={this.props.segments}
            segmenttype={"TaskDto"}
            UpdateSegments={this.props.UpdateSegments}
          />
        )}
        {this.state.selectedElement === "ressource" && (
          <div>
            <RessourceInput
              workflow={this.props.workflow}
              phases={this.props.phases}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(TaskMetaInput);
