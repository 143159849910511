/**
 *
 * @file DOClient.js
 * @created_date Monday, November 13, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description The DOClient module is a specialized utility for making HTTP requests to DigitalOcean Spaces. It provides functions for uploading files using both PUT and POST methods, including file data and additional metadata such as file descriptions.
 * <br/>Example use age of code - {@tutorial DOClient}
 * @module DOClient
 *
 **/

const DOClient = {
  put: async (url, data, description) => {
    
    // const headers = {
    //   "Content-Type": "application/json",
    //   ...customHeaders,
    // };

    return await fetch(url, {
      method: "PUT",
      body: data,
      headers: {
        "Content-Type": data.type,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error or API request failed");
        }
        return { file: data, description: description };
      })
      .catch((error) => {
        console.error("API request failed:", error);
        throw error;
      });
  },

  post: async (url, data, file, description) => {
    // const headers = {
    //   "Content-Type": "application/json",
    //   ...customHeaders,
    // };

    return await fetch(url, {
      method: "POST",
      // headers,
      body: data,
      headers: {
        "Content-Type": file.type,
        "x-amz-acl": "public-read",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error or API request failed");
        }
        return { file: file, description: description };
      })
      .catch((error) => {
        console.error("API request failed:", error);
        throw error;
      });
  },
};

export default DOClient;
