import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PDFViewer from "../Misc/PDFViewer";
import VideoPlayer from "../Misc/VideoPlayer";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { MiscFunctions } from 'helper-functions-package';

class CommentFileMiniSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: this.props.fileIndex,
            activeSlide: this.props.clickedComment !== undefined && props.clickedComment.medias[props.fileIndex],
            slides: props.clickedComment !== undefined && props.clickedComment.medias,
            pause: false,
            commentSettingAutoPlay: false,
            clickPauseAndPlay: false,
        };
        this.commentMiniSliderRef = React.createRef();
    }

    setVideoPause = (bool) => {
        this.setState({ pause: bool })
    }

    goToPrevSlide = () => {
        const { activeIndex, slides } = this.state;
        const newIndex = activeIndex > 0 ? activeIndex - 1 : slides.length - 1;
        this.setActiveItem(newIndex, slides[newIndex]);
        this.props.setFileIndex(newIndex);
        this.setVideoPause(true);
        this.setState({clickPauseAndPlay: false})
    };

    goToNextSlide = () => {
        const { activeIndex, slides } = this.state;
        const newIndex = activeIndex < slides.length - 1 ? activeIndex + 1 : 0;
        this.setActiveItem(newIndex, slides[newIndex]);
        this.props.setFileIndex(newIndex);
        this.setVideoPause(true);
        this.setState({clickPauseAndPlay: false})
    };

    setActiveItem = (index, slide) => {
        this.setState({ activeIndex: index, activeSlide: slide });
    };

    goWithIndicator = (index, slide) => {
        const { slides } = this.state;
        this.setActiveItem(index, slides[index]);
        this.props.setFileIndex(index);
        this.setVideoPause(true);
        this.setState({clickPauseAndPlay: false})
    }

    updateMedias() {
        if (!MiscFunctions.isNull(this.state.slides)) {
            const data = this.state.slides.filter(
                (slide) =>
                    slide.description &&
                    !slide.description.trim().toLowerCase().includes("deleted media".toLowerCase())
            );
            this.setState({ slides: data });
        }
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.clickedComment !== this.props.clickedComment) {
            this.setState({ slides: this.props.clickedComment.medias });
        }
        if (prevProps.clickedComment !== this.props.clickedComment) {
            this.updateMedias();
        }
    }
    
    componentDidMount() {
        if(this.commentMiniSliderRef.current){
            const contentMiniSliderHeight = this.commentMiniSliderRef.current.offsetHeight;
            this.props.setMainCommentActionPosition(contentMiniSliderHeight)
        }
        this.updateMedias();
        if(!MiscFunctions.isNull(this.props.clickedComment.commentSettings)){
            this.setState({commentSettingAutoPlay: this.props.clickedComment.commentSettings.autoplay})
        }
    }
    
    handleClickPauseAndPlay = (status) => {
        this.setState({clickPauseAndPlay: status})
    }
    render() {
        const { activeIndex, slides } = this.state;
        return (
            this.state.activeSlide !== undefined && 
            <div className={`mini-slider-block-${this.props.switchSide}`} ref={this.commentMiniSliderRef}>
                <div className="mini-slider-header">
                    <span className="back-btn-filename-block">
                        <span
                            className="mini-slider-back-btn"
                            onClick={() => {
                                this.props.setMiniSlider(false);
                                this.props.changeValue(!this.props.changeCommentHeight);
                            }}
                        ></span>
                        <span className="mini-slider-file-title">
                            {
                                !MiscFunctions.isNull(this.state.slides[this.state.activeIndex].filename) ? this.state.slides[this.state.activeIndex].filename : 
                                this.state.slides[this.state.activeIndex].description.split("/").length > 1
                                ? this.state.slides[this.state.activeIndex].description.split("/")[1]
                                : this.state.slides[this.state.activeIndex].description
                            }
                        </span>
                    </span>
                        <span className="icon-download-small" onClick={() => MiscFunctionsLocal.handleDownload(this.state.slides[this.state.activeIndex])}></span>
                </div>

                <div className="mini-slider">
                    <div className="mini-slider-slides">
                        {slides !== undefined &&
                            slides.map((slide, index) => (
                                <div
                                    key={slide.id}
                                    className={`mini-slider-slide ${index === activeIndex ? "active" : ""
                                        }`}
                                    style={{ display: index === activeIndex ? "block" : "none" }}
                                >
                                    {slide.type === "picture" && (
                                        <img src={slide.url} alt={slide.description} />
                                    )}
                                    {slide.type === "audio" && (
                                        <VideoPlayer
                                            url={slide.url}
                                            width="468px"
                                            height="265px"
                                            size="small"
                                            setVideoPause={this.setVideoPause}
                                            pause={this.state.pause}
                                            commentSettingAutoPlay={this.state.commentSettingAutoPlay}
                                            slide={slide}
                                            index={index}
                                            fileIndex={this.props.fileIndex}
                                            clickPauseAndPlay={this.state.clickPauseAndPlay}
                                            handleClickPauseAndPlay={this.handleClickPauseAndPlay}
                                        />
                                    )}
                                    {slide.type === "video" && (
                                        <VideoPlayer
                                            url={slide.url}
                                            width="468px"
                                            height="265px"
                                            size="small"
                                            setVideoPause={this.setVideoPause}
                                            pause={this.state.pause}
                                            commentSettingAutoPlay={this.state.commentSettingAutoPlay}
                                            slide={slide}
                                            index={index}
                                            fileIndex={this.props.fileIndex}
                                            clickPauseAndPlay={this.state.clickPauseAndPlay}
                                            handleClickPauseAndPlay={this.handleClickPauseAndPlay}
                                        />
                                    )}
                                    {slide.type === "document" && (
                                        <PDFViewer
                                            fileUrl={slide.url}
                                        />
                                    )}
                                </div>
                            ))}
                    </div>
                    <div className="mini-slider-prev-next-block">
                        <button
                            className="mini-slider-prev"
                            onClick={this.goToPrevSlide}
                            disabled={
                                slides !== undefined && slides.length > 1 ? false : true
                            }
                        >
                            <span className="mini-slider-prev-icon"></span>
                        </button>

                        <div className="mini-slider-indicators">
                            {slides !== undefined &&
                                slides.map((slide, index) => (
                                    <span
                                        key={slide.id}
                                        className={`mini-slider-indicator ${index === activeIndex ? "active" : ""
                                            }`}
                                        onClick={() => this.goWithIndicator(index, slide)}
                                    ></span>
                                ))}
                        </div>

                        <button
                            className="mini-slider-next"
                            onClick={this.goToNextSlide}
                            disabled={
                                slides !== undefined && slides.length > 1 ? false : true
                            }
                        >
                            <span className="mini-slider-next-icon"></span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CommentFileMiniSlider);
