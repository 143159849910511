/**
 *
 * @file MainHeader.js
 * @created_date Thursday, December 15, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component manages the registration and login functionality within the application. It includes dependencies such as buttons, modals, and state management hooks. The component utilizes state variables to control the visibility of popups and track user interactions, such as selecting a user role. It also implements event handlers to handle actions like closing modals, switching between registration and login, and submitting registration details. The component incorporates modals for displaying registration and login forms, with associated UI elements and descriptive text. Overall, `MainRegisterModal` provides a user-friendly interface for users to register, log in, and switch between different user roles.
 * <br/>Breakdown of the code - {@tutorial MainHeader}
 * @module MainHeader
 **/

import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import IndecatorUserProfile from "../assets/img/indicator_user_profile.png";
import Entwickler from "../assets/img/normal_indicator_medical_product_developer.png";
import Kliniker from "../assets/img/normal_indicator_medical_expert.png";
import Login from "./Login";
import Register from "./Register";
import { useTranslation } from "react-i18next";
import SelectRole from "./SelectRole";
// import LanguageSelector from "./Misc/LanguageSelector";

const MainRegisterModal = () => {
  const [registerPopupVisible, setRegisterPopupVisible] = useState(false);

  const [switchRolePopupVisible, setSwitchRolePopupVisible] = useState(true);

  const [isSwtichRoleUser, setIsSwitchRoleUser] = useState("Kliniker");

  let userRoleImg = Kliniker;
  if (isSwtichRoleUser === "Entwickler") userRoleImg = Entwickler;
  const handleOnCloseRegister = () => {
    setRegisterPopupVisible(false);
  };

  /**
   * @description This function is an event handler that is triggered when the login popup/modal is closed. It calls the setLoginPopupVisible function and sets the loginPopupVisible state variable to false, hiding the login popup/modal.
   *
   * @function handleOnCloseLogin
   * @return {void}
   * */
  const handleOnCloseLogin = () => {
    setLoginPopupVisible(false);
  };

  /**
   * @description This function is an event handler that is triggered when the user navigates back from the register popup to the switch role popup. It sets the registerPopupVisible state variable to false, hiding the register popup/modal, and sets the switchRolePopupVisible state variable to true, showing the switch role popup/modal.
   *
   * @function handleOnBackRegister
   * @return {void}
   * */
  const handleOnBackRegister = () => {
    setRegisterPopupVisible(false);
    setSwitchRolePopupVisible(true);
  };

  /**
   * @description This function is an event handler that is triggered when the switch role popup/modal is closed. It calls the setSwitchRolePopupVisible function and sets the switchRolePopupVisible state variable to false, hiding the switch role popup/modal.
   *
   * @function closeSwitchUserPopup
   * @return {void}
   * */
  const closeSwitchUserPopup = () => {
    setSwitchRolePopupVisible(false);
  };

  /**
   * @description This function is an event handler that is triggered when the user wants to switch their role. It performs the following actions:
   - Sets the loginPopupVisible and registerPopupVisible state variables to false, hiding any open popups/modals.
   - Sets the switchRolePopupVisible state variable to true, showing the switch role popup/modal.
   *
   * @function handleOpenSwitchRole
   * @return {void}
   * */
  const handleOpenSwitchRole = () => {
    setLoginPopupVisible(false);
    setRegisterPopupVisible(false);
    setSwitchRolePopupVisible(true);
  };

  /**
   * @description This function is an event handler that is triggered when the user wants to open the login popup/modal. It performs the following actions:
  - Sets the toggleDeveloperClick and toggleMedicalExpertClick state variables to false, hiding any previously displayed additional content related to the developer or medical expert roles.
  - Sets the registerPopupVisible, switchRolePopupVisible, and loginPopupVisible state variables to false, hiding any open popups/modals.
  - Sets the loginPopupVisible state variable to true, showing the login popup/modal.
   *
   * @function handleOpenLogin
   * @return {void}
   * */
  const [loginPopupVisible, setLoginPopupVisible] = useState(false);
  const handleOpenLogin = () => {
    // setToggleDeveloperClick(false);
    // setToggleMedicalExpertClick(false);
    setRegisterPopupVisible(false);
    setSwitchRolePopupVisible(false);
    setLoginPopupVisible(true);
  };

  const { t } = useTranslation();
  return (
    <div className="landing_header float-right mr-1 flex">
      <ToastContainer />
      <Button onClick={handleOpenLogin} className="button_login mr-3 mt-2 landing_header_transition">
        {t("general.login")}
      </Button>
      <Button
        onClick={handleOpenSwitchRole}
        className="button_sign_up mr-3 mt-2"
      >
        {t("general.registration")}
      </Button>

      {/* <div style={{ position: 'absolute', left: '15px', top: '15px' }}><LanguageSelector /></div> */}
      {/* Swith Role Popup */}
      <Modal
        show={switchRolePopupVisible}
        onClick={() => setSwitchRolePopupVisible(false)}
        onClose={() => {
          setSwitchRolePopupVisible(false);
        }}
        className="register_main_modal"
      >
        <Modal.Body
          className="switch_role_body"
          style={{ overflow: "inherit", flex: "none" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal_header">
            <span
              className="button_modal_close"
              onClick={closeSwitchUserPopup}
            ></span>
            <h1 className="label_sign-up_header">
              {t("general.registration")}
            </h1>
            <h5
              className="text-xl font-medium text-gray-900 dark:text-white text-center"
              id="label_sign-up_description"
            >
              {t("landing_page.welcome_to")} medi
              <span className="boldColored">MESH</span>{" "}
              <span className="boldOnly">{t("landing_page.world")}.</span>
            </h5>
            <p
              className="text-sm font-medium text-worldgray-500 dark:text-gray-300 text-center"
              id="label_text_link-log-in"
            >
              {t("landing_page.have_an_account")}{" "}
              <span
                className="text-blue-700 hover:underline dark:text-blue-500 clickLink"
                id="label_text_link-log-in_a"
              >
                <span onClick={handleOpenLogin}>{t("general.login")}</span>
              </span>
            </p>
          </div>
        </Modal.Body>

        <SelectRole
          setRegisterPopupVisible={setRegisterPopupVisible}
          setSwitchRolePopupVisible={setSwitchRolePopupVisible}
          setIsSwitchRoleUser={setIsSwitchRoleUser}
        />
      </Modal>

      {/* Register Popup */}
      <Modal
        show={registerPopupVisible}
        className="register_main_modal"
        onClick={() => setRegisterPopupVisible(false)}
        onClose={() => {
          setRegisterPopupVisible(false);
        }}
      >
        <Modal.Body
          className="register_popup_body"
          style={{ overflow: "inherit", flex: "none" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="signup-block flex flex-col items-center justify-center mx-3 my-3">
            <div className="container-signup-block w-full max-w-4xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
              <img
                src={userRoleImg}
                alt=""
                className="user_role_image_register"
              />
              <div className="modal-"></div>
              <span
                className="button_modal_close"
                onClick={handleOnCloseRegister}
              ></span>
              <span
                className="button_modal_back"
                onClick={handleOnBackRegister}
              ></span>
              <h5 id="label_sign-up_header" className="text-center">
                {t("general.registration")}
              </h5>
              <h5
                className="text-xl font-medium text-gray-900 dark:text-white text-center"
                id="label_sign-up_description"
              >
                {t("landing_page.welcome_to")} medi
                <span className="boldColored">MESH</span>{" "}
                <span className="boldOnly">{t("landing_page.world")}.</span>
              </h5>
              <p
                className="text-sm font-medium text-gray-500 dark:text-gray-300 text-center"
                id="label_text_link-log-in"
              >
                {t("landing_page.have_an_account")}{" "}
                <span
                  className="text-blue-700 hover:underline dark:text-blue-500 clickLink"
                  id="label_text_link-log-in_a"
                >
                  <span onClick={handleOpenLogin}>{t("general.login")}</span>
                </span>
              </p>

              <Register
                isSwtichRoleUser={isSwtichRoleUser}
                userRoleImg={userRoleImg}
                setRegisterPopupVisible={setRegisterPopupVisible}
              />

              <p id="label_sign_up_conditions_text">
                {t("landing_page.agree_terms")}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Login Popup */}
      <Modal
        show={loginPopupVisible}
        className="register_main_modal"
        onClick={() => setLoginPopupVisible(false)}
        onClose={() => {
          setLoginPopupVisible(false);
        }}
      >
        <Modal.Body
          className="login_popup_body"
          style={{ overflow: "inherit", flex: "none" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="signup-block flex flex-col items-center justify-center mx-3 my-3">
            <div className="block-login w-full max-w-4xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-4 dark:bg-gray-800 dark:border-gray-700">
              <img
                src={IndecatorUserProfile}
                alt=""
                className="user_role_image_login"
              />
              <span
                className="button_modal_close"
                onClick={handleOnCloseLogin}
              ></span>
              <h4
                className="text-xl font-medium text-gray-900 dark:text-white text-center"
                id="label_login_header"
              >
                {t("general.login")}
              </h4>
              <h5
                className="text-xl font-medium text-gray-900 dark:text-white text-center"
                id="label_sign-up_description"
              >
                {t("landing_page.welcome_to")} medi
                <span className="boldColored">MESH</span>{" "}
                <span className="boldOnly">{t("landing_page.world")}.</span>
              </h5>

              <p id="label_text_link-sign-up">
                {t("landing_page.dont_have_an_account")}{" "}
                <span
                  id="control_text_link-sign-up"
                  onClick={handleOpenSwitchRole}
                >
                  {t("general.registration")}
                </span>
              </p>
              <Login setLoginPopupVisible={setLoginPopupVisible} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default MainRegisterModal;
