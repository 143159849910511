import React, { useState, useEffect } from "react";
import CheckLogin from "./CheckLogin";
import VideoPlayer from "./Player/VideoPlayer";
import Workflow from "../services/WorkflowService";
import categoryAPI from "../api/categoryAPI";
import Category from "../services/CategoryServices";
import authAPI from "../api/authAPI";
import { MiscFunctions } from 'helper-functions-package';
import { Spinner } from "flowbite-react";
import workflowlibraryAPI from "../api/workflowlibraryAPI";
import { setAuthAccessObject } from "../utils/tokenStorage";
import userAPI from "../api/userAPI";

const Dashboard = () => {
  const [workflow, setWorkflow] = useState(null);
  const [patientVideo, setPatientVideo] = useState(false);
  const [PlayerSidebar, setPlayerSidebar] = useState(true);
  const [QuickOverviewPopups, setQuickOverviewPopups] = useState(true);
  const [addComment, setAddComment] = useState(true);
  const [introPopupShown, setIntroPopupShown] = useState(false);
  const [redirectByRateMy, setRedirectByRateMy] = useState(false);

  const selectedWorkflowItem = JSON.parse(localStorage.getItem("selected_workflow_item"));
  const patientVideoItem = JSON.parse(localStorage.getItem("patient_video_item"));
  const patientVideoStatus = !MiscFunctions.isNull(patientVideoItem) ? patientVideoItem.status : false;

  useEffect(() => {
    if (!workflow) {

      const loadData = () => {
        if (!patientVideoStatus) {
          const data = {
            id: selectedWorkflowItem?.id || authAPI.logout(),
            label: selectedWorkflowItem?.label,
            categories: [],
            selectedVideo: selectedWorkflowItem?.selectedVideo?.id ? selectedWorkflowItem.selectedVideo : null,
            description: selectedWorkflowItem?.description,
          };

          categoryAPI.getCategories().then((res) => {
            let Categories = res.map((elem) => new Category(elem));
            data.categories.push(...Categories);
            const workflowInstance = new Workflow(data);
            setWorkflow(workflowInstance);
          });
        } else {
          const data = {
            label: patientVideoItem.data.label,
            id: patientVideoItem.data.id,
            selectedVideo: patientVideoItem.data,
          };

          const workflowInstance = new Workflow(data);
          setWorkflow(workflowInstance);
          setPatientVideo(true);
          setPlayerSidebar(false);
          setQuickOverviewPopups(false);
          setAddComment(false);
        }
      };

      const queryParams = new URLSearchParams(window.location.search);
      const rateMytoken = queryParams.get('_token');
      const workflowLibID = queryParams.get('_workflow_id');
      const loginEmail = queryParams.get('_email');

      const loadIfRateMyRedirect = () => {
        if (rateMytoken && workflowLibID && loginEmail) {
          const ID = userAPI.getUserID(rateMytoken, '', loginEmail);

          ID.then((userID) => {
            let newID = userID;
            setAuthAccessObject(
              JSON.stringify({
                loginStatus: true,
                token: rateMytoken,
                recaptcha: '',
                ID: newID,
                referredBy: 'rate_my'
              })
            );

            setRedirectByRateMy(true)
            // Adding a small delay to ensure localStorage is updated
            setTimeout(() => {
              localStorage.setItem("activeWorkflowId", workflowLibID);

              workflowlibraryAPI.getWorkflowLibraryById(workflowLibID).then((resp) => {
                if (!MiscFunctions.isUndefined(resp)) {
                  let workflowLib = {
                    ...resp[0],
                    selectedVideo: resp[0].videos[0],
                    rate_my: true
                  };
                  const hasReloaded = sessionStorage.getItem("hasReloaded");
                  if (!hasReloaded) {
                    localStorage.setItem("selected_workflow_item", JSON.stringify(workflowLib));
                    sessionStorage.setItem("hasReloaded", "true");
                    setTimeout(() => {
                      window.location.reload();
                    }, 100); // Ensures localStorage is set before reload
                  } else {
                    const data = {
                      id: { id: workflowLibID, tag: 'workflow' },
                      label: resp[0].label,
                      categories: [],
                      selectedVideo: resp[0].videos[0],
                      description: resp[0].description,
                      introPopupShown: null,
                    };
                    const workflow = new Workflow(data);
                    setWorkflow(workflow);
                    setAddComment(false);
                  }
                }
              });
            }, 100); // Small delay for ensuring everything is set
          });
        }
      };

      if (workflowLibID !== null && loginEmail && rateMytoken !== null) {
        loadIfRateMyRedirect();
      }
      else {
        loadData()
      }

      // Prevent back navigation
      window.history.pushState(null, document.title, window.location.href);
      const handlePopState = () => {
        window.history.pushState(null, document.title, window.location.href);
      };
      window.addEventListener("popstate", handlePopState);

      // Handle storage event
      const handleStorage = () => {
        setIntroPopupShown(localStorage.getItem("hasSeenIntroductionPopup"));
      };
      window.addEventListener("storage", handleStorage);

      return () => {
        window.removeEventListener("popstate", handlePopState);
        window.removeEventListener("storage", handleStorage);
      };
    }
  }, [patientVideoStatus, selectedWorkflowItem, patientVideoItem, workflow]);

  return (
    <div className="dashboard-scrollable">
      <div className="h-screen" style={{ background: "#f2f2f2" }}>
        <CheckLogin />
        <div className="all_sections_block">
          {workflow ? (
            <VideoPlayer
              workflow={workflow}
              patientVideo={patientVideo}
              PlayerSidebar={PlayerSidebar}
              QuickOverviewPopups={QuickOverviewPopups}
              addComment={addComment}
              redirectByRateMy={redirectByRateMy}
            />
          ) : (
            <Spinner color="success" aria-label="loading categories" size="xl" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
