/**
 *
 * @file commentAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to comment authentication for the application, These functions are organized within the commentAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial commentAPI}
 * @module commentAPI
 *
 **/

import { getAuthAccessObject } from "../utils/tokenStorage";
import { apiClient } from 'my-api-client-package';
const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * @description This function sends a POST request to the /comment endpoint to post a comment. It includes the access token and reCAPTCHA value in the headers. It returns the response object.
 * <br />Here you can find some examples: - {@tutorial commentAPI}
 * @function postComment
 * @return {void}
 * */
const postComment = async (data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };

    return await apiClient
      .post(API_BASE_URL, `/comment`, data, customHeaders)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const putComment = async (data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };
    return await apiClient
      .put(API_BASE_URL, `/comment`, data, customHeaders)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a GET request to the /comment/:id/children endpoint to retrieve child comments of a parent comment. It includes the access token in the headers. It returns the response as a parsed JSON object if the request is successful.
 * <br />Here you can find some examples: - {@tutorial commentAPI}
 * @function getCommentChildrenById
 * @return {void}
 * */
const getCommentChildrenById = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/comment/${id}/children`, customHeaders)
      .then(async function (response) {
        const textRes = await response.text();
        if (!textRes.includes('no Children')) {
          const resp = JSON.parse(textRes);
          if ("error" in resp) {
            console.log(resp)
            return []
          }
          else {
            return resp;
          }
        }

      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a DELETE request to the /comment/:id endpoint to retrieve delete a comments by id. It includes the access token in the headers. It returns the response as a parsed JSON object if the request is successful.
 * <br />Here you can find some examples: - 
 * @function deleteCommentById
 * @return {void}
 * */
const deleteCommentById = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .delete(API_BASE_URL, `/comment/${id}`, customHeaders)
      .then(async function (response) {
        if ("error" in response) {
          return []
        }
        else {
          return response;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const commentAPI = { postComment, putComment, getCommentChildrenById, deleteCommentById };

export default commentAPI;
