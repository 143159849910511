/**
 * @file WorkflowPhase.js
 * @created_date Tuesday, August 29, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This component is a React class component that represents the phase or segment within the video workflow. It is designed to be part of a hierarchical structure where each phase may have child phases. The component is responsible for rendering itself and its child phases, enabling the visualization of a complex workflow structure.
 * @module WorkflowPhase
 *
 **/

import React from "react";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { MiscFunctions, ElementLayoutHelpers } from 'helper-functions-package';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class WorkflowPhase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elem: this.props.phase,
      phases: this.props.phases,
      seconds: this.props.phase.getTimeStampSeconds,
      key: this.props.counter,
      selectedPhase: "",
      phaseOver: false,
      isOverflowing: false
    };

    this.swimlanePhaseSegmentRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.phaseOver !== this.state.phaseOver) {
      this.setState({
        isOverflowing: ElementLayoutHelpers.checkOverflow(
          this.swimlanePhaseSegmentRef
        ),
      });
    }
  }

  componentDidMount() {
    this.setState({
      isOverflowing: ElementLayoutHelpers.checkOverflow(
        this.swimlanePhaseSegmentRef
      ),
    });
  }

  renderChildren() {
    let Children = this.state.phases.getChildren(this.state.elem);
    if (Children.Elements.length > 0) {
      return Children.Elements.map((child, i) => {
        return (
          <WorkflowPhase
            phase={child}
            phases={this.state.phases}
            videoLength={this.state.elem.getDuration}
            counter={i}
            player={this.props.player}
            start={
              child.getTimeStampSeconds - this.props.phase.getTimeStampSeconds
            }
            key={i}
          />
        );
      });
    }
  }
  render() {
    let childClass = "";
    if (!MiscFunctions.isNull(this.state.elem.parent))
      childClass = " swimlane-sub-segment-wrapper";
    return (
      <div
        className={"swimlane-segment-wrapper flex-wrap" + childClass}
        key={this.state.key}
        style={{
          left: (this.props.start / this.props.videoLength) * 100 + "%",
          width:
            (Number(this.props.phase.getDuration) / this.props.videoLength) *
            100 +
            "%",
        }}
        onMouseOver={() => {
          this.setState({ phaseOver: true })
        }}
        onMouseOut={() => {
          this.setState({ phaseOver: false })
        }}
      >
        {this.state.isOverflowing && <Tooltip
          id={"my-tooltip-" + this.props.phase.getID}
          className="swimlane-process-label-tooltip"
          border="2px solid #19eef2"
        />}
        <div
          data-tooltip-id={"my-tooltip-" + this.props.phase.getID}
          data-tooltip-content={this.props.phase.getLabel}
          data-tooltip-place="top"
          className={"swimlane-process" + this.state.selectedPhase}
          onMouseDown={(e) => {
            e.target.classList.add("swimlane-process-clicked");
          }}
          onMouseUp={(e) => {
            e.target.classList.remove("swimlane-process-clicked");
          }}
          onClick={(e) => {
            MiscFunctionsLocal.gotoTimestamp(
              this.props.phase.getTimeStampSeconds,
              this.props.player
            );
          }}
        >
          <span className="swimlane-process-label">
            <span className="swimlane-process-label-inside" ref={this.swimlanePhaseSegmentRef}
            >
              {this.props.phase.getLabel}
            </span>
          </span>
        </div>

        {this.renderChildren()}
      </div>
    );
  }
}

export default WorkflowPhase;
