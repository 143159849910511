/**
 *
 * @file TimelineLocal.js
 * @created_date Wednesday, August 01, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component serves as a local timeline inside the scrollable workflow element with interactive features. It provides a visual representation of time points along with the ability to click on those points to navigate a video player's timeline.
 *<br/>Key functionalities - {@tutorial TimelineLocal}
 * @module TimelineLocal
 *
 **/

import React from "react";
import { MiscFunctions } from 'helper-functions-package';

class TimelineLocal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.ref.current.scrollLeft = "100px";
  }
  render() {
    let indicatorCurrentTime;
    if (!isNaN(this.props.duration)) {
      const splitedByDot = MiscFunctions.secondsMinuteFormat(
        this.props.duration
      ).split(".")[0];
      const splittedByColon = splitedByDot.split(":");
      indicatorCurrentTime =
        this.state.checkIfHitHour === true
          ? splitedByDot
          : `${splittedByColon[1]}:${splittedByColon[2]}`;
    }


    return (
      <div
        className="timeline-local"
        ref={this.ref}
        onClick={(e) => {
          let timelineWidth = e.target.clientWidth;
          let myPlayer = document.querySelector(".medimesh-video-player");

          myPlayer.currentTime =
            (e.nativeEvent.offsetX / timelineWidth) * myPlayer.duration;
        }}
      >
        <span className="timeline-start-time">00:00</span>
        <div className="move_to_10">
          <span className="process-time-label">10</span>
        </div>
        <div className="move_to_20">
          <span className="process-time-label">20</span>
        </div>
        <div className="move_to_30">
          <span className="process-time-label">30</span>
        </div>
        <div className="move_to_40">
          <span className="process-time-label">40</span>
        </div>
        <div className="move_to_50">
          <span className="process-time-label">50</span>
        </div>
        <div className="move_to_60">
          <span className="process-time-label">60</span>
        </div>
        <div className="move_to_70">
          <span className="process-time-label">70</span>
        </div>
        <div className="move_to_80">
          <span className="process-time-label">80</span>
        </div>
        <div className="move_to_90">
          <span className="process-time-label">90</span>
        </div>
        {/* <div className="move_to_100">
          <span className="process-time-label">100</span>
        </div> */}
        <span className="timeline-end-time">{indicatorCurrentTime}</span>
      </div>
    );
  }
}
export default TimelineLocal;
