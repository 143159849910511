import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Dashboard from "./components/Dasboard";
import LandingPage from "./components/LandingPage";
import NotFound from "./components/NotFound";
import RegisterSuccess from "./components/RegisterSuccess";
import ResetPassword from "./components/ResetPassword";
import WorkflowLibrary from "./components/WorkflowLibrary/WorkflowLibrary";
import PatientLogin from "./components/Patient/PatientLogin";
import './i18n';
import DeleteConfirmPage from "./components/DeleteConfirmPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route index element={<App />} />
      <Route path="landing_page" element={<LandingPage />} />
      <Route path="password_reset" element={<ResetPassword />} />
      <Route path="register_success" element={<RegisterSuccess />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="workflow_library" element={<WorkflowLibrary />} />
      <Route path="patient" element={<PatientLogin />} />
      <Route path="/delete_confirm" element={<DeleteConfirmPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
  // </React.StrictMode >
);
