/**
 *
 * @file RegisterSuccess.js
 * @created_date Thurseday, Jan 19, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The RegisterSuccess component represents a registration success popup that is displayed to the user after a successful registration process.
 * <br/>Breakdown of the code - {@tutorial RegisterSuccess}
 * @module RegisterSuccess
 **/

import React, { useEffect, useState } from "react";
import backgroundImage from "../assets/img/normal_background_img_1.jpg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal } from "flowbite-react";
import IndecatorUserProfile from "../assets/img/indicator_user_profile.png";
import Entwickler from "../assets/img/normal_indicator_medical_product_developer.png";
import Kliniker from "../assets/img/normal_indicator_medical_expert.png";
import { useTranslation } from "react-i18next";

// This method is for reseting password.
function RegisterSuccess() {
  // This statement is for inline css of this page.
  const mainPageStyles = {
    backgroundImage: `url(${backgroundImage})`,
  };

  /**
   * @description This function is triggered when the user clicks the close button in the registration success modal.
It updates the state variable registerSuccessPopupVisible to false, causing the modal to be hidden.
   *
   * @function handleOnCloseRegisterSuccess
   * @return {void}
   * */
  const [registerSuccessPopupVisible, setRegisterSuccessPopupVisible] =
    useState(true);
  const handleOnCloseRegisterSuccess = () => {
    setRegisterSuccessPopupVisible(false);
  };

  const { t } = useTranslation();

  /**
   * @description This function is called when the user clicks the "Los gehts" button in the registration success modal.
It uses the useNavigate hook to navigate the user to the login page (/landing_page).
   *
   * @function redirectToLogin
   * @return {void}
   * */
  const navigate = useNavigate();
  const redirectToLogin = () => {
    navigate("/landing_page");
  };

  const [isRegisterEmail, setIsRegisterEmail] = useState("");
  const [isRegisterRole, setIsRegisterRole] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const getEmail = searchParams.get("email");
    const getRole = searchParams.get("role");
    setIsRegisterEmail(getEmail);
    setIsRegisterRole(getRole);
  }, [searchParams]);

  let userRoleImg = IndecatorUserProfile;
  if (isRegisterRole.toLowerCase() === "entwickler") userRoleImg = Entwickler;
  else if (isRegisterRole.toLowerCase() === "kliniker") userRoleImg = Kliniker;

  return (
    <div className="container-register-success-page" style={mainPageStyles}>
      <div className="landing_header float-right mr-1">
        <Modal
          show={registerSuccessPopupVisible}
          className="register_main_modal"
          onClick={() => setRegisterSuccessPopupVisible(false)}
          onClose={() => {
            setRegisterSuccessPopupVisible(false);
          }}
        >
          <Modal.Body
            className="register_popup_body"
            style={{ overflow: "inherit", flex: "none" }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col items-center justify-center mx-3 my-3">
              <div className="register-success-block w-full max-w-4xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                <img
                  src={userRoleImg}
                  alt=""
                  className="user_role_image_register_success"
                />
                <span
                  className="button_modal_close"
                  onClick={handleOnCloseRegisterSuccess}
                ></span>
                <h4
                  className="text-xl font-medium text-gray-900 dark:text-white text-center"
                  id="label_login_header"
                >
                  {t('general.congratulation')}

                </h4>
                <h5
                  className="text-xl font-medium text-gray-900 dark:text-white text-center"
                  id="label_confirmation_description"
                >
                  <span className="boldOnly">{t('general.your')}
                  </span> medi
                  <span className="boldColored">MESH</span>{" "}
                  <span className="boldOnly">
                    {" "}
                    {t('login_register_page.account_is_activated')}
                  </span>
                </h5>

                <p id="label_confirmation_state-description">
                  {t('login_register_page.success_register_message', { isRegisterEmail: isRegisterEmail })}
                </p>
                <div className="flex flex-col items-center">
                  <button
                    type="submit"
                    className="w-full text-white bg-hightlight-700 hover:bg-hightlight-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700 dark:focus:ring-hightlight-800 button_link_to_login"
                    onClick={redirectToLogin}
                  >
                    {t('general.letsgo')}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default RegisterSuccess;
