/**
 *
 * @file RessourcesServices.js
 * @created_date Friday, Dec 08, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The provided JavaScript file defines a class named Ressources designed to manage and categorize various resources. The class includes a constructor to initialize an array of resources and a method, addResources, allowing the addition of new resources under the condition that they lack an 'error' property. Additionally, the class provides getter functions, such as getResources, getPersons, getTools, and getRooms, each filtering and returning specific types of resources based on their 'type' property.
 * <br/>Example use age of code - {@tutorial Ressources}
 * @module Ressources
 *
 **/

import { MiscFunctions } from 'helper-functions-package';
import Ressource from "./RessourceServices";

/** Class for handling the Ressources-Object. */
class Ressources {
  constructor(Array) {
    this.Elements = Array;
  }

  set addResources(Ressources) {
    if (MiscFunctions.isUndefined(Ressources.error)) {
      this.Elements.push(Ressources);
    }
  }
  //
  /**
   * @description Getter Function to extract all resources.
   * @return {Resources} All Resources.
   */
  get getResources() {
    const Resources = new Ressources(this.Elements);
    return Resources.Elements;
  }

  get getPersons() {
    const Persons = [];

    const persons = new Ressources(this.Elements).getResources.filter(
      ({ reference }) => reference.type === "PersonDto"
    );
    persons.map((elem) => {
      elem = new Ressource(elem);
      if (MiscFunctions.isUndefined(Persons.find((person) => person.getID === elem.getID))) {
        Persons.push(elem);
      }
    });
    return Persons;
  }

  get getPersonGroup() {
    const personsGroup = [];

    const persons = new Ressources(this.Elements).getResources.filter(
      ({ reference }) => reference.type === "PersonDto"
    );

    persons.map((elem) => {
      elem = new Ressource(elem);
      if (
        MiscFunctions.isUndefined(
          personsGroup.find(
            (persongroup) => persongroup.id === elem.reference.persongroup.id.id
          )
        )
      ) {
        const newItem = { ...elem.reference.persongroup };
        newItem.id = elem.reference.persongroup.id.id;
        personsGroup.push(newItem);
      }
    });
    return personsGroup;
  }

  get getTools() {
    const persons = new Ressources(this.Elements).getResources.filter(
      ({ reference }) => reference.type === "ToolDto" && reference.category==="tool"
    );
    return persons;
  }

  get getEquipment() {
    const data = new Ressources(this.Elements).getResources.filter(
      ({ reference }) => reference.type === "ToolDto" && reference.category==="equipment"
    );
    return data;
  }

  get getPharmaceutical() {
    const data = new Ressources(this.Elements).getResources.filter(
      ({ reference }) => reference.type === "ToolDto" && reference.category==="pharmaceutical"
    );
    return data;
  }

  get getRooms() {
    const persons = new Ressources(this.Elements).getResources.filter(
      ({ reference }) => reference.type === "RoomDto"
    );
    return persons;
  }
}

export default Ressources;
