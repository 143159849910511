/**
 * @file WorkflowLibraryItemList.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component represents an item within the workflow library, providing details, image carousels for videos and phases, and interactive features. The component efficiently handles the display and switching between videos and phases, with preloading for enhanced image slider performance. Users can hover over the item to reveal additional information and play the selected video or phase.
 *<br/>Breakdown of the code - {@tutorial WorkflowLibraryItemList}
 * @module WorkflowLibraryItemList
 *
 **/

import React from "react";
import { Navigate } from "react-router-dom";
import VideoService from "../../services/VideoService";
import Workflow from "../../services/WorkflowService";
import Segment from "../../services/SegmentServices";
import { MiscFunctions } from 'helper-functions-package';
class WorkflowLibraryItemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editItem: null,
      deleteItem: null,
      workflowList: this.props.workflowList,
      elem: new Workflow(props.element),
      showOnHover: false,
      videoCurrentIndex: 0,
      phaseCurrentIndex: 0,
      currentVideo: null,
      switchVideo: true,
      allPhases: null,
      currentPhase: null,
      favoritItemHover: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevProps.workflowList)
    // console.log(this.props.workflowList);
    // console.log(this.state.item.videos[0].phases)

    if (prevProps.workflowList !== this.props.workflowList) {
      let elem = new Workflow(this.props.element);
      this.setState({ elem: elem });
      // console.log(this.state.elem)
      // console.log("elem",this.state.elem)
      let currentVideo = elem.getVideos[0];
      // console.log(currentVideo)// frames von alle Video in seperate Arrays
      let video = new VideoService(currentVideo);
      // console.log(video) // frames von alle Video in seperate Arrays
      // console.log(video.getPhases)
      this.setState({
        currentVideo: video,
        videoCurrentIndex: 0,
        allPhases: video.getPhases,
        phaseCurrentIndex: 0,
        currentPhase:
          !MiscFunctions.isNull(video.getPhases) && !MiscFunctions.isUndefined(video.getPhases)
            ? video.getPhases[0]
            : null,
      });
    }
    // console.log(this.state.allPhases)
    if (prevProps.element !== this.props.element) {
      this.setState({
        switchVideo: true,
      });
    }

    if (prevState.currentVideo !== this.state.currentVideo) {
      this.setState({
        currentVideo: this.state.currentVideo,
        allPhases: this.state.allPhases,
        videoCurrentIndex: this.state.videoCurrentIndex,
      });
    }
    if (prevState.allPhases !== this.state.allPhases) {
      this.setState({
        allPhases: this.state.allPhases,
      });
    }
    if (prevState.currentPhase !== this.state.currentPhase) {
      this.setState({
        currentPhase: this.state.currentPhase,
        phaseCurrentIndex: this.state.phaseCurrentIndex,
      });
    }
    if (prevState.switchVideo !== this.state.switchVideo) {
      this.setState({
        switchVideo: this.state.switchVideo,
      });
    }
  }

  componentDidMount() {
    this.handleStepClick(0);
  }

  componentWillUnmount() {
    this.setState({
      switchVideo: true,
    });
  }

  setSlideImage(index) {
    if (this.state.switchVideo) {
      let currentVideo = this.state.elem.getVideos[index];
      let video = new VideoService(currentVideo);
      this.setState({
        currentVideo: video,
        allPhases: video.getPhases,
      });
    } else {
      this.setState({
        currentPhase: this.state.allPhases[index],
      });
    }
  }

  handleStepClick = (step) => {
    if (this.state.switchVideo) {
      this.setState({
        videoCurrentIndex: step,
      });
    } else {
      this.setState({
        phaseCurrentIndex: step,
      });
    }
    this.setSlideImage(step);
  };

  navigateToDestination = () => {
    const { elem } = this.state;
    const currentVideo = elem.getVideos[this.state.videoCurrentIndex];

    let selected_elem = {
      ...this.state.elem,
      selectedVideo: currentVideo,
    };
    localStorage.setItem(
      "selected_workflow_item",
      JSON.stringify(selected_elem)
    );
    localStorage.removeItem("patient_video_item");
    this.setState({ clickPlay: true });
  };

  editWorkFlowItem = () => {
    this.setState({ editItem: !this.state.editItem });
  };

  deleteWorkFlowItem = () => {
    this.setState({ deleteItem: !this.state.deleteItem });
  };

  render() {
    const { elem, videoCurrentIndex, phaseCurrentIndex } = this.state;
    let videos = elem.getVideos;
    let segment = new Segment([]);
    segment.assignData = this.state.currentPhase;
    let currentPhase = segment;
    // console.log(this.props.itemClicked)
    // console.log(this.state.elem) // ein Video mit unterschiedlichen Frames
    // console.log(videos) // Unterschiedliche Videoframes für ein Video
    // console.log(segment)
    // console.log(this.state.videoCurrentIndex)
    return (
      <>
        <div
          className={`workflow-library-workflow-item workflow_item_transition ${
            this.props.itemClicked
          } ${
            this.state.showOnHover ? "workflow-library-workflow-item-hover" : ""
          } `}
          onMouseOver={() => 
          setTimeout(() => {
            this.setState({ showOnHover: true })
          }, 300)}
          onMouseOut={() => 
          setTimeout(() => {
            this.setState({ showOnHover: false })
          }, 300)}
          onClick={() => this.props.onClick()}
        >
          {/* <>
            {this.state.editItem && this.props.favoritItem === true && (
              <div className="workflow-library-workflow-item-edit-box">
                <div className="workflow-library-workflow-item-edit-text-star">
                  <input
                    className="workflow-library-workflow-item-edit-text-box"
                    type="text"
                    value={this.state.elem.label}
                  />
                  <span className="workflow-library-header-section-fav-icon"></span>
                </div>
                <div className="workflow-library-workflow-item-edit-id">
                  ID: {this.state.elem.id.id}
                </div>
                <div className="workflow-library-workflow-item-edit-text-box-footer">
                  <span
                    className="workflow-library-header-section-bin-icon"
                    onClick={() => {
                      this.deleteWorkFlowItem();
                    }}
                  ></span>
                  <span
                    className="button_modal_close"
                    onClick={() => this.setState({ editItem: false })}
                  ></span>
                </div>
              </div>
            )}
            {this.state.deleteItem && (
              <div className="workflow-library-workflow-item-edit-box">
                <span className="workflow-library-workflow-item-delete-message">
                  Are you sure to delete the workflow project ?
                </span>
                <div className="workflow-library-workflow-item-edit-text-box-footer">
                  <div className="workflow-library-workflow-item-delete-text-box-footer">
                    <button className="workflow-library-workflow-item-btn yes-btn">
                      Yes
                    </button>
                    <button
                      className="workflow-library-workflow-item-btn no-btn"
                      onClick={() => this.setState({ deleteItem: false })}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}
          </> */}
          <div className="workflow-library-carousel">
            <div className="workflow-library-workflow-item-details-block" onClick={() => this.navigateToDestination()}>
              <div className="workflow-library-workflow-item-details-heading-content">
                <span className="workflow-library-workflow-item-details-heading">
                  <span
                    className={
                      this.state.showOnHover === true
                        ? "workflow-library-workflow-item-details-name workflow-library-workflow-item-details-name-hover"
                        : "workflow-library-workflow-item-details-name"
                    }
                  >
                    {elem.getLabel}
                  </span>
                </span>
                <span
                  className={
                    this.state.favoritItemHover === true
                      ? "workflow-library-workflow-item-details-fav-hover"
                      : this.props.favoritItem === true ||
                        elem.favorited === true
                      ? "workflow-library-workflow-item-details-fav-active"
                      : "workflow-library-workflow-item-details-fav"
                  }
                  onMouseOver={() => {
                    this.setState({ favoritItemHover: true });
                  }}
                  onMouseOut={() => {
                    this.setState({ favoritItemHover: false });
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.handleFavoriteWorkflow(
                      elem,
                      this.props.favoritItem
                    );
                  }}
                ></span>
              </div>
            </div>

            <div
              className="carousel-container"
              style={{ position: "relative", overflow: "hidden" }}
            >
              {/* Video images are preloaded for better result on image slider */}
              {videos.map((el, i) => {
                el = new VideoService(el);
                return !MiscFunctions.isUndefined(el.getThumbnail) ? (
                  <img
                    key={i}
                    src={el.getThumbnail}
                    alt={i}
                    style={{ display: "none" }}
                  />
                ) : (
                  ""
                );
              })}

              {/* Phases images are preloaded for better result on image slider */}
              {!MiscFunctions.isNull(this.state.allPhases) &&
                !MiscFunctions.isUndefined(this.state.allPhases) &&
                this.state.allPhases.map((el, i) => {
                  let segment = new Segment([]);
                  segment.assignData = el;
                  return !MiscFunctions.isUndefined(segment.getThumbnail) ? (
                    <img
                      key={i}
                      src={segment.getThumbnail}
                      alt={i}
                      style={{ display: "none" }}
                    />
                  ) : (
                    ""
                  );
                })}

              <div
                style={{
                  backgroundImage: `url(${
                    this.state.switchVideo
                      ? !MiscFunctions.isNull(this.state.currentVideo) &&
                        this.state.currentVideo.getThumbnail
                      : !MiscFunctions.isUndefined(currentPhase.getThumbnail)
                      ? currentPhase.getThumbnail
                      : ""
                  } )`,
                }}
                className="workflow-library-current-block"
              >
                <div className="workflow-library-workflow-item-video-label">
                  <span style={{ fontWeight: 600 }}>
                    {this.state.switchVideo && "Video:"}
                    {!this.state.switchVideo && "Phase:"}
                  </span>{" "}
                  {this.state.switchVideo &&
                    !MiscFunctions.isNull(this.state.currentVideo) &&
                    this.state.currentVideo.getLabel}
                  {!this.state.switchVideo &&
                    !MiscFunctions.isNull(currentPhase) &&
                    currentPhase.getLabel}
                </div>
                <div className="workflow-library-modify-block">
                  {this.state.clickPlay && (
                    <Navigate to="/dashboard" replace={true} />
                  )}

                  <div style={{ width: "15%" }}>
                    {this.state.showOnHover && (
                      <span
                        className="workflow-library-workflow-item-play"
                        onClick={() => this.navigateToDestination()}
                      ></span>
                    )}
                  </div>
                  <div className="workflow-library-image-carousel-steps-block">
                    {this.state.switchVideo &&
                      !MiscFunctions.isNull(videos) &&
                      !MiscFunctions.isUndefined(videos) &&
                      videos.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => this.handleStepClick(index)}
                          className={
                            videoCurrentIndex === index
                              ? "workflow-library-image-carousel-step active"
                              : "workflow-library-image-carousel-step"
                          }
                        ></button>
                      ))}

                    {!this.state.switchVideo &&
                      !MiscFunctions.isNull(this.state.allPhases) &&
                      !MiscFunctions.isUndefined(this.state.allPhases) &&
                      this.state.allPhases.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => this.handleStepClick(index)}
                          className={
                            phaseCurrentIndex === index
                              ? "workflow-library-image-carousel-step active"
                              : "workflow-library-image-carousel-step"
                          }
                        ></button>
                      ))}
                    {/* {this.state.allPhases !== null &&
                      this.state.allPhases !== undefined && (
                        <button
                          className="workflow-library-change-arrow"
                          onClick={() => {
                            this.setSlideImage(this.state.videoCurrentIndex);
                            this.setState({
                              switchVideo: !this.state.switchVideo,
                            });
                            if (this.state.switchVideo)
                              this.setState({
                                currentPhase: this.state.allPhases[0],
                              });
                          }}
                        ></button>
                      )} */}
                    {!MiscFunctions.isNull(this.state.elem.videos[videoCurrentIndex])
                      && !MiscFunctions.isUndefined(this.state.elem.videos[videoCurrentIndex])
                      && !MiscFunctions.isNull(this.state.elem.videos[videoCurrentIndex].phases) 
                      && (
                            <button
                              className="workflow-library-change-arrow"
                              onClick={() => {
                                this.setSlideImage(this.state.videoCurrentIndex);
                                this.setState({
                                  switchVideo: !this.state.switchVideo,
                              });
                          }}
                        ></button>
                      )}
                  </div>

                  <div style={{ width: "15%" }}>
                    {/* {this.state.showOnHover && this.props.favoritItem && (
                      <span
                        className="workflow-library-workflow-item-edit"
                        onClick={() => this.editWorkFlowItem()}
                      ></span>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WorkflowLibraryItemList;
