import React from 'react';

class Counter extends React.Component {
    timeoutId = null;

    increment = () => {
        this.updateValue(this.props.value + 1);
    }

    decrement = () => {
        this.updateValue(this.props.value - 1);
    }

    updateValue = (newValue) => {
        const clampedValue = Math.max(this.props.min, Math.min(newValue, this.props.max));
        this.props.onChange(clampedValue);
    }

    startIncrement = () => {
        this.increment();
        this.timeoutId = setInterval(this.increment, 200); // Adjust interval as needed
    }

    startDecrement = () => {
        this.decrement();
        this.timeoutId = setInterval(this.decrement, 200); // Adjust interval as needed
    }

    stop = () => {
        clearInterval(this.timeoutId);
    }

    render() {
        return (
            <div className="counter-wrapper">
                <div className="counter-title">{this.props.title}</div>
                <div className="counter-container">
                    <button
                        className="counter-button"
                        onMouseDown={this.startDecrement}
                        onMouseUp={this.stop}
                        onMouseLeave={this.stop}
                    >
                        -
                    </button>
                    <input className="counter-input" type="text" value={this.props.value} readOnly />
                    <button
                        className="counter-button"
                        onMouseDown={this.startIncrement}
                        onMouseUp={this.stop}
                        onMouseLeave={this.stop}
                    >
                        +
                    </button>
                </div>
            </div>
        );
    }
}

export default Counter;
