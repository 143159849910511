import React from 'react';
import { withTranslation } from 'react-i18next';

class SettingsNav extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            MenuOptions: [
                { tag: 'personal_data', label: this.props.t('setting_page.personal_data') },
                {
                    tag: 'account', label: this.props.t('general.account'),
                    submenu: [
                        { tag: 'delete_account', label: this.props.t('general.delete_account') },
                        // Add more submenu items here if needed
                    ]
                },
                {
                    tag: 'communication', label: this.props.t('setting_page.communication'),
                    submenu: [
                        { tag: 'notification_within_app', label: this.props.t('general.notification_within_app') },
                        { tag: 'email_notification', label: this.props.t('general.email_notification') },
                        // Add more submenu items here if needed
                    ]
                },
                {
                    tag: 'layout', label: this.props.t('setting_page.layout'),
                    submenu: [
                        { tag: 'language', label: this.props.t('general.language') },
                        // Add more submenu items here if needed
                    ]
                },
                {
                    tag: 'security',
                    label: this.props.t('setting_page.security'),
                    submenu: [
                        { tag: 'change_password', label: this.props.t('setting_page.change_password') },
                        // Add more submenu items here if needed
                    ]
                }
            ],
            openMenu: null, // Track the menu with an open submenu
        };

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.t !== this.props.t) {
            this.setState({
                MenuOptions: [
                    { tag: 'personal_data', label: this.props.t('setting_page.personal_data') },
                    {
                        tag: 'account', label: this.props.t('general.account'),
                        submenu: [
                            { tag: 'delete_account', label: this.props.t('general.delete_account') },
                            // Add more submenu items here if needed
                        ]
                    },
                    {
                        tag: 'communication', label: this.props.t('setting_page.communication'),
                        submenu: [
                            { tag: 'notification_within_app', label: this.props.t('general.notification_within_app') },
                            { tag: 'email_notification', label: this.props.t('general.email_notification') },
                            // Add more submenu items here if needed
                        ]
                    },
                    {
                        tag: 'layout', label: this.props.t('setting_page.layout'),
                        submenu: [
                            { tag: 'language', label: this.props.t('general.language') },
                            // Add more submenu items here if needed
                        ]
                    },
                    {
                        tag: 'security',
                        label: this.props.t('setting_page.security'),
                        submenu: [
                            { tag: 'change_password', label: this.props.t('setting_page.change_password') },
                            // Add more submenu items here if needed
                        ]
                    }
                ],
            })
        }
    }

    toggleSubMenu = (menuTag) => {
        // Toggle the openMenu state to show/hide the submenu
        this.setState(prevState => ({
            openMenu: prevState.openMenu === menuTag ? null : menuTag
        }));
    }

    render() {
        const { activeMenu, setActiveMenu } = this.props;
        const { openMenu } = this.state;

        return (
            <nav className="settings-navbar">
                <ul className="settings-nav-links">
                    {this.state.MenuOptions.map((menuOption) => (
                        <React.Fragment key={menuOption.tag}>
                            <li
                                className={`settings-nav-item ${activeMenu === menuOption.tag ? 'active' : ''}`}
                                onClick={() => {
                                    this.toggleSubMenu(menuOption.tag);
                                    setActiveMenu(menuOption.tag);
                                }}
                            >
                                <div className="settings-nav-links-text">
                                    {menuOption.label}
                                </div>
                                {menuOption.submenu && openMenu === menuOption.tag && (
                                    <ul className="settings-submenu">
                                        {menuOption.submenu.map((subItem) => (
                                            <li
                                                key={subItem.tag}
                                                className={`settings-submenu-item ${activeMenu === subItem.tag ? 'active' : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActiveMenu(subItem.tag);
                                                }}
                                            >
                                                {subItem.label}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
            </nav>
        );
    }
}

export default withTranslation()(SettingsNav);
