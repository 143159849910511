/**
* @file Checklogin.js
* @created_date Thursday, December 21, 2022
* @author Rafi Haidari <r.haidari@medimesh.de>
* @Copyright © 2022 mediMESH. All rights reserved.
* @description This component called CheckLogin which is responsible for managing the user's login status and session expiration. It imports several dependencies from various libraries such as {@link https://react.dev/|react}, {@link https://www.npmjs.com/package/react-router-dom|react-router-dom}, and {@link https://www.npmjs.com/package/react-idle-timer/|react-idle-timer}. The component displays a modal pop-up when the user is idle for a specific duration.
*<br/>Breakdown of the code - {@tutorial CheckLogin}
@module checkLogin
*
**/
import { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { getAuthAccessObject } from "../utils/tokenStorage";
import { useIdleTimer } from "react-idle-timer";
import { Modal } from "flowbite-react";
import authAPI from "../api/authAPI";
import { useTranslation } from "react-i18next";

const CheckLogin = () => {
  const navigate = useNavigate();
  const [remainingTime, setRemainingTime] = useState();
  const [counterPopup, setCounterPopup] = useState(false);

  // The hook is created to show the popup counter if the is idle for a specific durations.
  // The hook will be called if there is any change in counterPopup state.
  /** 
     * @description 
     * The hook is created to show the popup counter if the is idle for a specific durations.
     * <br/>
     * The hook will be called if there is any change in counterPopup state.
     * @function useEffect1
     * @example
    *   useEffect(() => {
        var count = process.env.REACT_APP_TIMEOUT_DURATION;
        setRemainingTime(`${count} secs`)
        if (counterPopup) {
            const counterTimer = window.setInterval(() => {
                if (count <= 0) {
                    clearInterval(counterTimer);
                    authService.logout();
                }
                setRemainingTime(count + " secs")
                count -= 1;
            }, 1000);
            // This will clear the time inteval so we don't face multiple numbers on counter popup. 
            return () => {
                window.clearInterval(counterTimer);
            };
        }
    }, [counterPopup]);
     * 
     * */
  useEffect(() => {
    var count = process.env.REACT_APP_TIMEOUT_DURATION;
    setRemainingTime(`${count} secs`);
    if (counterPopup) {
      const counterTimer = window.setInterval(() => {
        if (count <= 0) {
          clearInterval(counterTimer);
          authAPI.logout();
        }
        setRemainingTime(count + " secs");
        count -= 1;
      }, 1000);
      // This will clear the time inteval so we don't face multiple numbers on counter popup.
      return () => {
        window.clearInterval(counterTimer);
      };
    }
  }, [counterPopup]);

  /** 
     * @description This function called handleOnIdle, it is triggered when the user becomes idle. It sets the counterPopup state to true.
     * @function handleOnIdle 
     * @example
    *  const handleOnIdle = () => {
        setCounterPopup(true)
    }
     * @return {void}
     * */
  const handleOnIdle = () => {
    if(Date.now()- getLastActiveTime().getTime() > parseInt(process.env.REACT_APP_IDLE_TIMER)){
      console.log("onIdle")
      setCounterPopup(true);
    }
  }
  const handleOnActive = (event) => {
    console.log(event)
    if(event.type === "mousemove"){
    setCounterPopup(false)
    }
    // setCounterPopup(false)
    // console.log('user is active', event)
    // console.log('time remaining', getRemainingTime())
  };

  const handleOnAction = (event) => {
    // setCounterPopup(false)
    // console.log('User still has activity', event)
    // console.log('reminded: ', getRemainingTime())
  };

  /** 
     * @description The useIdleTimer hook is used to track user idle time. It provides several callback functions (onIdle, onActive, and onAction) that are called based on user interaction.
     * @function useIdleTimer 
     * @example
    * const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: parseInt(process.env.REACT_APP_IDLE_TIMER),
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })
     * @return {object}
     * */

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: parseInt(process.env.REACT_APP_IDLE_TIMER),
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  /** 
     * @description useEffect hook is used to perform certain actions when the component mounts.
<li>Inside this hook, a setInterval is defined that periodically checks the user's login status by calling getAuthAccessObject().</li>
<li>If the user is logged in (loginObject.loginStatus is true), the user is navigated to the dashboard page; otherwise, they are redirected to the landing page.</li>
<li>The authAPI.refreshToken() function is called to refresh the user's access token.</li>
     * @function useEffect2
     * @example
    *   useEffect(() => {
        setInterval(() => {
            const loginObject = getAuthAccessObject();
            (loginObject && loginObject.loginStatus) ? navigate('/dashboard') : navigate('/landing_page')
            authAPI.refreshToken(loginObject.token);
        }, parseInt(process.env.REACT_APP_GET_REFRESH_TOKEN_EVERY))

    }, [])
     * 
     * */

  useEffect(() => {
    setInterval(() => {
      const loginObject = getAuthAccessObject();
      loginObject && loginObject.loginStatus
        ? <Route to={window.location.href}/>
        : navigate("/landing_page");
      authAPI.refreshToken(loginObject.token);
    }, parseInt(process.env.REACT_APP_GET_REFRESH_TOKEN_EVERY));
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={counterPopup}
        className="expiration-box"
        onClose={() => {
          setCounterPopup(false);
        }}
      >
        <Modal.Body
          className="text-center"
          style={{ overflow: "inherit", flex: "none" }}
          onClick={(e) => e.stopPropagation()}
        >
          <p>{t('messages.session_will_expire')}.</p>
          <p className="timeCountDown">{remainingTime}</p>
          <button
            type="submit"
            className="button_session_expire_counter"
            onClick={() => {
              setCounterPopup(false);
              authAPI.refreshToken(getAuthAccessObject().token);
            }}
          >
            {" "}
            {t('check_login_page.stay_on_page')}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CheckLogin;
