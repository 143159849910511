/**
 *
 * @file contextAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to context authentication for the application, These functions are organized within the contextAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial contextAPI}
 * @module contextAPI
 *
 **/

import { getAuthAccessObject } from "../utils/tokenStorage";
import { apiClient } from 'my-api-client-package';
const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * @description This function sends a GET request to the /video/:id endpoint to retrieve video information. It includes the access token in the headers. It returns the response as a parsed JSON object if the request is successful.
 * <br />Here you can find some examples: - {@tutorial contextAPI}
 * @function getContextById
 * @return {void}
 * */
const getContextById = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };
    return await apiClient
      .get(API_BASE_URL, `/context/${id}`, customHeaders)
      .then(async function (response) {
        let resJson = await response.json();
        if ("error" in resJson) {
          console.log(resJson)
          return []
        }
        else {
          return resJson;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a POST request to the /context endpoint to post a context-object. It includes the access token and reCAPTCHA value in the headers. It returns the response object.
 * @function postContext
 * @return {void}
 * */
const postContext = async (data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };

    return await apiClient
      .post(API_BASE_URL, `/context`, data, customHeaders)
      .then(async function (response) {
        const resp = await response.text();
        return resp;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const contextAPI = {
  getContextById,
  postContext,
};

export default contextAPI;
