/**
 * @file VideoJS.js
 * @created_date Thursday, November 30, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This code defines a React functional component named VideoJS. It utilizes the Video.js library to create a video player in the applications. The component takes options as configuration for the Video.js player and provides an onReady callback function when the player is ready. It manages the initialization and disposal of the Video.js player using React hooks.
 *<br/>Breakdown of the code - {@tutorial VideoJS}
 * @module VideoJS
 *
 **/

import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("medimesh-video-player-block");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
      }));

      const getVjsPlayer = document.querySelector(`#${player.id_}_html5_api`);
      getVjsPlayer.classList.add("medimesh-video-player");

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      //Removed this part to prevent reset of the player on prop change.
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef, onReady]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
