/**
 *
 * @file Register.js
 * @created_date Thursday, December 28, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The purpose of the Register component is to render a registration form and handle the registration process for users. It includes various form fields such as name, email, password, and reCAPTCHA verification. The component interacts with external services, such as the authentication service and toast notification library, to perform the necessary registration actions.
 * <br/>Breakdown of the code - {@tutorial Register}
 * @module Register
 **/

import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";
import { MiscFunctions } from 'helper-functions-package';
import { Modal } from "flowbite-react";
import RegisterVerify from "./RegisterVerify";
import IndecatorUserProfile from "../assets/img/indicator_user_profile.png";
import EmailExist from "./EmailExist";
import PasswordChecklist from "react-password-checklist";
import authAPI from "../api/authAPI";
import ToastMessages from "../helpers/ToastMessages";
import { useTranslation } from "react-i18next";

function Register(props) {
  // This statement load reCaptchaKey from .env file (application rool dir).
  const reCaptchaKey = process.env.REACT_APP_SITE_KEY;

  // This hook enable/desable signup button on reCaptcha verifiction.
  const [isSignupVerfied, setIsSignupVerified] = useState(false);

  // Below hooks are to set/get form inputs.
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [signupPasswordConfirm, setSignupPasswordConfirm] = useState("");
  const [signupName, setSignupName] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [emailInvalidInput, setEmailInvalidInput] = useState(false);
  const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const confirmPasswordInvalidMessage = t("messages.password_must_match");

  // Below are predefined Default/Error/Success classes which is called on it's state for inputs.
  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-transparent focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white input-style";
  const errorClass =
    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 customize-input-style";
  const successClass =
    "border border-greeColorLight text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-greeColorLight text-sm rounded-lg focus:ring-greeColorLight focus:border-greeColorLight block w-full p-2.5 dark:bg-gray-700 dark:border-greeColorLight customize-input-style";

  /**
   * @description Validates the email field on blur event. It checks if the entered email address is valid and displays appropriate error/success messages based on the validation result. It also updates the UI to reflect the validation status.
   *
   * @function checkEmailValidation
   * @return {void}
   * */
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [userEmailIconColor, setUserEmailIconColor] = useState("");
  const [emailInvalidLabel, setEmailInvalidLabel] = useState("");
  const [emailSuccessMessage, setEmailSuccessMessage] = useState(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const checkEmailValidation = async (e) => {
    const email = MiscFunctions.emailValidation(signupEmail);
    if (!email) {
      setUserEmailIconColor("normal_icon_email");
      setEmailSuccessMessage(false);
      setEmailErrorMessage(t('messages.enter_valid_email'));
      e.target.className = errorClass;
      setEmailInvalidInput(true);
      setEmailInvalidLabel("errorLabel");
    } else {
      const emailExist = authAPI.checkIfEmailExist(signupEmail);

      emailExist.then(async (email) => {
        // '200' means we can create account with this email with '406' we can't.
        if (email === 200) {
          setUserEmailIconColor("normal_icon_email_confirm");
          setEmailSuccessMessage(true);
          e.target.className = successClass;
          setEmailInvalidInput(false);
          setEmailInvalidLabel("");
          setEmailAlreadyExists(false);
        } else {
          setUserEmailIconColor("normal_icon_email");
          setEmailAlreadyExists(true);
          setEmailErrorMessage(t("messages.email_alrealy_exist"));
          setEmailSuccessMessage(false);
          e.target.className = errorClass;
          setEmailInvalidInput(true);
          setEmailInvalidLabel("errorLabel");
        }
      });
      setUserEmailIconColor("normal_icon_email");
    }
  };

  // This hook is for enable/disable of spinner (Loading).
  const [spinner, setSpinner] = useState(false);

  /**
   * @description Handles the form submission event for registration. It performs various validations, such as checking the email and password validity, matching passwords, and reCAPTCHA verification. If all validations pass, it calls the registration API provided by the authService to register the user. It displays success/error toast messages based on the registration result.
   *
   * @function handleRegisterSubmit
   * @return {void}
   * */
  const handleRegisterSubmit = async (e) => {
    setSpinner(true);
    e.preventDefault();
    if (emailAlreadyExists) {
      setSpinner(false);
      setEmailExistPopupVisible(true);
    } else {
      // To remove success class of email field.
      e.target[1].className = inputClasses;

      const email = MiscFunctions.emailValidation(signupEmail);
      if (!email) {
        ToastMessages.erorrMessage(t('messages.enter_valid_email'));

        setSpinner(false);
      }
      if (signupPassword !== signupPasswordConfirm) {
        ToastMessages.erorrMessage(t("messages.password_must_match"));

        setSpinner(false);
      } else {
        let res = authAPI.registerUser(
          captchaValue,
          signupName,
          signupEmail,
          signupPasswordConfirm,
          props.isSwtichRoleUser
        );
        res
          .then((data) => {
            if (data !== 200) {
              ToastMessages.erorrMessage(t("messages.error"));
              setSpinner(false);
            } else {
              props.setRegisterPopupVisible(false);
              setVerifyEmailPopupVisible(true);
              ToastMessages.successMessage(t("messages.wow_easy"));
              setSpinner(false);

              setSignupName("");
              setSignupEmail("");
              setSignupPassword("");
              setSignupPasswordConfirm("");
            }
          })
          .catch((err) => {
            ToastMessages.erorrMessage(t("messages.error"));
            setSpinner(false);
          });
      }
    }
  };

  /**
   * @description Checks if the name field is empty on blur event and updates the UI accordingly. It validates the name field and updates the UI to indicate whether the field is empty or not.
   *
   * @function checkIfNameEmpty
   * @return {void}
   * */
  const [userNameIconColor, setUserNameIconColor] = useState("");
  const [nameInvalidLabel, setNameInvalidLabel] = useState("");
  const checkIfNameEmpty = (e) => {
    setUserNameIconColor("");
    if (!signupName) {
      setNameInvalidLabel("errorLabel");
      e.target.className = errorClass;
    } else {
      setUserNameIconColor("normal_icon_user_name_confirm");
      setNameInvalidLabel("");
      e.target.className = inputClasses;
    }
  };

  /**
   * @description Checks if the password field is empty or doesn't meet the required criteria on blur event and updates the UI accordingly. It validates the password field and updates the UI to indicate whether the field is empty or does not meet the required criteria (e.g., minimum length, special characters).
   *
   * @function checkIfPasswordEmpty
   * @return {void}
   * */
  const [userPasswordIconColor, setUserPasswordIconColor] = useState("");
  const [passwordInvalidLabel, setPasswordInvalidLabel] = useState("");
  const checkIfPasswordEmpty = (e) => {
    setUserPasswordIconColor("");
    if (
      !signupPassword ||
      signupPassword.length < 8 ||
      !MiscFunctions.checkIfSpecialCharacterExist(signupPassword)
    ) {
      setPasswordInvalidLabel("errorLabel");
      e.target.className = errorClass;
    } else {
      setPasswordInvalidLabel("");
      setUserPasswordIconColor("normal_icon_password_confirm");
    }
  };

  /**
   * @description Checks if the confirm password field is not empty and updates the UI accordingly. It checks if the confirm password field has a value and updates the UI to reflect the presence or absence of a value.
   *
   * @function checkConfirmPasswordIfNotEmpty
   * @return {void}
   * */
  const [userRepeatPasswordIconColor, setUserRepeatPasswordIconColor] =
    useState("");
  const checkConfirmPasswordIfNotEmpty = () => {
    if (!signupPasswordConfirm) {
      setUserRepeatPasswordIconColor("");
    } else {
      setUserRepeatPasswordIconColor("normal_icon_repeat_password_confirm");
      setUserPasswordIconColor("normal_icon_password_confirm");
    }
  };

  /**
   * @description Checks if the password and confirm password fields match and updates the UI accordingly. It compares the values of the password and confirm password fields and updates the UI to indicate whether the passwords match or not.
   *
   * @function checkConfirmPassword
   * @return {void}
   * */
  const checkConfirmPassword = (e) => {
    console.log(signupPassword);

    if (
      signupPassword !== signupPasswordConfirm &&
      signupPasswordConfirm !== ""
    ) {
      setConfirmPasswordInvalid(true);
      document.querySelector("#signup_password").className = errorClass;
      document.querySelector("#repeat_password").className = errorClass;
      setUserRepeatPasswordIconColor("");
      setUserPasswordIconColor("");
    } else if (
      signupPassword === signupPasswordConfirm &&
      signupPasswordConfirm !== ""
    ) {
      setConfirmPasswordInvalid(false);
      document.querySelector("#signup_password").className = successClass;
      document.querySelector("#repeat_password").className = successClass;
      setUserRepeatPasswordIconColor("normal_icon_repeat_password_confirm");
      setUserPasswordIconColor("normal_icon_password_confirm");
    } else {
      setConfirmPasswordInvalid(false);
      document.querySelector("#signup_password").className = inputClasses;
      document.querySelector("#repeat_password").className = inputClasses;
    }
  };

  /**
   * @description Handles the reCaptcha value from the ReCAPTCHA component and updates the state accordingly. It receives the reCaptcha value and updates the state to indicate whether the user has been verified through reCaptcha.
   *
   * @function recaptchaSignup
   * @return {void}
   * */
  const recaptchaSignup = (value) => {
    if (MiscFunctions.isNull(value)) {
      setIsSignupVerified(true);
      setSpinner(false);
      setCaptchaValue("");
    } else {
      setCaptchaValue(value);
      setIsSignupVerified(false);
    }
  };

  /**
   * @description Handles the closing of the email verification modal and resets relevant states. It is triggered when the user closes the email verification modal, and it performs necessary cleanup tasks, such as resetting states related to email verification.
   *
   * @function handleOnCloseVerifyModal
   * @return {void}
   * */
  const [verifyEmailPopupVisible, setVerifyEmailPopupVisible] = useState(false);
  const handleOnCloseVerifyModal = () => {
    setEmailSuccessMessage(false);
    setSignupEmail("");
    setSignupName("");
    setSignupPassword("");
    setSignupPasswordConfirm("");
    setVerifyEmailPopupVisible(false);
  };

  /**
   * @description Handles the closing of the email already exists modal. It is triggered when the user closes the modal indicating that the email address already exists.
   *
   * @function handleOnCloseResetPasswordModal
   * @return {void}
   * */
  const [emailExistPopupVisible, setEmailExistPopupVisible] = useState(false);
  const handleOnCloseResetPasswordModal = () => {
    setEmailExistPopupVisible(false);
  };

  /**
   * @description Handles going back from the email already exists modal to the registration form. It is triggered when the user clicks the back button in the email already exists modal, and it navigates back to the registration form.
   *
   * @function handleOnBackRegister
   * @return {void}
   * */
  const handleOnBackRegister = () => {
    setEmailExistPopupVisible(false);
    props.setLoginPopupVisible(true);
  };

  /**
   * @description Runs the effect of resetting the reCaptcha component when the props change. It is an effect hook that is triggered when the props passed to the component change. It resets the reCaptcha component to its initial state using the reset method provided by the recaptchaRef reference.
   *
   * @function recaptchaRef
   * @return {void}
   * */
  const recaptchaRef = useRef(null);
  useEffect(() => {
    recaptchaRef.current?.reset();
  }, [props]);

  useEffect(() => {
    if (
      captchaValue &&
      signupName &&
      signupEmail &&
      signupPassword &&
      signupPasswordConfirm &&
      validPassword &&
      !confirmPasswordInvalid &&
      emailSuccessMessage
    ) {
      setIsSignupVerified(false);
    } else {
      setIsSignupVerified(true);
    }
  }, [
    captchaValue,
    signupName,
    signupEmail,
    signupPassword,
    signupPasswordConfirm,
    validPassword,
    confirmPasswordInvalid,
    emailSuccessMessage,
  ]);
  return (
    <>
      <form className="space-y-6" onSubmit={handleRegisterSubmit} method="post">
        <div className="input_group">
          <span
            className={userNameIconColor + " normal_icon_user_name input_icon"}
          ></span>
          <div className="input_group_item">
            <label
              htmlFor="first_name"
              className={
                nameInvalidLabel +
                " block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
              }
            >
              {t("login_register_page.your_name")}
            </label>
            <input
              type="text"
              name="first_name"
              value={signupName}
              onChange={(e) => setSignupName(e.target.value)}
              id="first_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white input-style"
              onFocus={(e) => {
                e.target.className = inputClasses;
                setUserNameIconColor("normal_icon_user_name_select");
              }}
              onBlur={checkIfNameEmpty}
              required
            />
            {nameInvalidLabel ? (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500 default-label-style">
                {t("messages.enter_valid_email")}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="input_group">
          <span
            className={userEmailIconColor + " normal_icon_email input_icon"}
          ></span>
          <div className="input_group_item">
            <label
              htmlFor="signup_email"
              className={
                emailInvalidLabel +
                " block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
              }
            >
              {" "}
              {t("login_register_page.your_email")}
            </label>
            <input
              type="email"
              name="signup_email"
              value={signupEmail}
              onChange={(e) => setSignupEmail(e.target.value)}
              onBlur={checkEmailValidation}
              onFocus={(e) => {
                e.target.className = inputClasses;
                setUserEmailIconColor("normal_icon_email_select");
              }}
              id="signup_email"
              className={inputClasses}
              required
            />
            {emailInvalidInput ? (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500 default-label-style">
                {emailErrorMessage}
              </p>
            ) : (
              ""
            )}
            {emailSuccessMessage ? (
              <p className="mt-2 text-sm text-greeColorLight dark:text-greeColorLight default-label-style">
                {t('messages.email_is_valid')}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="input_group">
          <span
            className={
              userPasswordIconColor + " normal_icon_password input_icon"
            }
          ></span>
          <div className="input_group_item">
            <label
              htmlFor="password"
              className={
                passwordInvalidLabel +
                " block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
              }
            >
              {t("login_register_page.your_password_be")}
            </label>
            <input
              type="password"
              name="signup_password"
              value={signupPassword}
              onChange={(e) => setSignupPassword(e.target.value)}
              onKeyUp={checkConfirmPassword}
              onBlur={checkIfPasswordEmpty}
              onFocus={(e) => {
                e.target.className = inputClasses;
                setUserPasswordIconColor("normal_icon_password_select");
              }}
              id="signup_password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white input-style"
              autoComplete="new-password"
              required
            />
            {signupPassword && (
              <PasswordChecklist
                rules={[
                  "minLength",
                  "capital",
                  "lowercase",
                  "number",
                  "specialChar",
                ]}
                minLength={12}
                value={signupPassword}
                valueAgain={signupPasswordConfirm}
                className="passwordCheck"
                messages={{
                  minLength: t("messages.character_long", { len: 12 }),
                  capital: t("messages.uppercase_letter"),
                  lowercase: t("messages.lowercase_letters"),
                  number: t("general.numbers"),
                  specialChar: t("messages.special_characters"),
                }}
                onChange={(isValid) => {
                  if (isValid === true) setValidPassword(true);
                  else setValidPassword(false);
                }}
              />
            )}
          </div>
        </div>

        <div className="input_group">
          <span
            className={
              userRepeatPasswordIconColor +
              " normal_icon_repeat_password input_icon"
            }
          ></span>
          <div className="input_group_item">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
            >
              {t("login_register_page.confirm_our_password")}
            </label>
            <input
              type="password"
              name="repeat_password"
              value={signupPasswordConfirm}
              onChange={(e) => setSignupPasswordConfirm(e.target.value)}
              onFocus={(e) => {
                e.target.className = inputClasses;
                setUserRepeatPasswordIconColor(
                  "normal_icon_repeat_password_select"
                );
              }}
              onBlur={checkConfirmPasswordIfNotEmpty}
              onKeyUp={checkConfirmPassword}
              id="repeat_password"
              className={inputClasses}
              required
            />
            {confirmPasswordInvalid ? (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500 default-label-style">
                {" "}
                {confirmPasswordInvalidMessage}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={reCaptchaKey}
          hl={i18n.language}
          onChange={recaptchaSignup}
          id="recaptcha-register-container"
          className="container_recaptcha_register"
          onErrored={(error) => {
            console.log("rec err: ", error);
          }}
          onExpired={() => {
            recaptchaRef.current.reset();
            setCaptchaValue("");
            setIsSignupVerified(false);
          }}
        />
        <div className="signup-button-block flex flex-col items-center">
          <button
            type="submit"
            className=" text-white bg-hightlight-700 hover:bg-hightlight-800 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-hightlight-600 dark:hover:bg-hightlight-700 dark:focus:ring-hightlight-800 text-center button_sign_in_up"
            disabled={isSignupVerfied}
          >
            {spinner ? (
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              ""
            )}
            {t("general.registration")}
          </button>
        </div>
      </form>

      {/* Verify Email Popup */}
      <Modal
        show={verifyEmailPopupVisible}
        className="register_main_modal"
        onClick={() => setVerifyEmailPopupVisible(false)}
        onClose={() => {
          setVerifyEmailPopupVisible(false);
        }}
      >
        <Modal.Body
          className=""
          style={{ overflow: "inherit", flex: "none" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="signup-block flex flex-col items-center justify-center mx-3 my-3">
            <div className="register-verify-block w-full max-w-4xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
              <img
                src={props.userRoleImg}
                alt=""
                className="user_role_image_verfiy"
              />
              <span
                className="button_modal_close"
                onClick={handleOnCloseVerifyModal}
              ></span>
              <RegisterVerify signupEmail={signupEmail} />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Email Already Exist Popup */}
      <Modal
        show={emailExistPopupVisible}
        className="register_main_modal"
        onClose={() => {
          setEmailExistPopupVisible(false);
        }}
      >
        <Modal.Body className="" style={{ overflow: "inherit", flex: "none" }}>
          <div className="signup-block flex flex-col items-center justify-center mx-3 my-3">
            <div className="register-verify-block w-full max-w-4xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
              <img
                src={IndecatorUserProfile}
                alt=""
                className="user_role_image_verfiy"
              />
              <span
                className="button_modal_close"
                onClick={handleOnCloseResetPasswordModal}
              ></span>
              {/* <span className="modal_bac
                            k_btn" onClick={handleOnBackRegister}></span> */}

              <EmailExist handleOnBackRegister={handleOnBackRegister} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Register;
