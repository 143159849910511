import { Modal } from 'flowbite-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PDFViewer from '../Misc/PDFViewer';
import VideoPlayer from '../Misc/VideoPlayer';
import { MiscFunctions } from 'helper-functions-package';

class CommentFileFullSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullSliderPopup: true,
            currentSlideIndex: this.props.fileIndex,
            pause: false,
            medias: this.props.clickedComment.medias,
            clickedComment: this.props.clickedComment,
            commentSettingAutoPlay: false,
            clickPauseAndPlay: false,
        };
    }

    setVideoPause = (bool) => {
        this.setState({pause: bool})
    }

    handleClose = () => {
        this.setState({ fullSliderPopup: false });
        this.props.setFullSlider(false)
    };

    handleNext = () => {
        const { medias } = this.state;
        let newIndex = this.state.currentSlideIndex;
        newIndex = (newIndex + 1) % medias.length;
        this.setState({ currentSlideIndex: newIndex });
        this.props.setFileIndex(newIndex);
        this.setVideoPause(true);
        this.setState({clickPauseAndPlay: false})
    };

    handlePrev = () => {
        const { medias } = this.state;
        let newIndex = this.state.currentSlideIndex;
        newIndex = (newIndex - 1 + medias.length) % medias.length;
        this.setState({ currentSlideIndex: newIndex });
        this.props.setFileIndex(newIndex);
        this.setVideoPause(true);
        this.setState({clickPauseAndPlay: false})
    };

    handleThumbnailClick = (index) => {
        const { medias } = this.state;
        this.setState({ currentSlideIndex: index });
        this.props.setFileIndex(index);
        this.setVideoPause(true);
        this.setState({clickPauseAndPlay: false})
    };

    updateMedias() {
        const { clickedComment } = this.props;
    
        if (!MiscFunctions.isNull(clickedComment)) {
            const data = clickedComment.medias.filter(
                (media) =>
                    media.description &&
                    !media.description.trim().toLowerCase().includes("deleted media".toLowerCase())
            );
            this.setState({ medias: data });
        }
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.clickedComment !== this.props.clickedComment) {
            this.updateMedias();
        }
    }
    
    componentDidMount() {
        this.updateMedias();
        if(!MiscFunctions.isNull(this.props.clickedComment.commentSettings)){
            this.setState({commentSettingAutoPlay: this.props.clickedComment.commentSettings.autoplay})
        }
    }

    handleClickPauseAndPlay = (status) => {
        this.setState({clickPauseAndPlay: status})
    }

    renderSlide = (slide) => {
        switch (slide.type) {
            case 'picture':
                return <img src={slide.url} alt={slide.description} className="comment-full-slider-block" />;
            case 'video':
                // return <video src={slide.url} controls className="w-full h-auto" />;
                return <VideoPlayer
                    url={slide.url}
                    showProgressBar={true}
                    setVideoPause={this.setVideoPause}
                    pause={this.state.pause}
                    commentSettingAutoPlay={this.state.commentSettingAutoPlay}
                    slide={slide}
                    index={this.state.currentSlideIndex}
                    fileIndex={this.props.fileIndex}
                    clickPauseAndPlay={this.state.clickPauseAndPlay}
                    handleClickPauseAndPlay={this.handleClickPauseAndPlay}
                />
            case 'document':
                // return <PDFViewer fileUrl={slide.url} />;
                return <PDFViewer fileUrl={slide.url} />;
            case 'audio':
                return <VideoPlayer
                    url={slide.url}
                    showProgressBar={true}
                    setVideoPause={this.setVideoPause}
                    pause={this.state.pause}
                    commentSettingAutoPlay={this.state.commentSettingAutoPlay}
                    slide={slide}
                    index={this.state.currentSlideIndex}
                    fileIndex={this.props.fileIndex}
                    clickPauseAndPlay={this.state.clickPauseAndPlay}
                    handleClickPauseAndPlay={this.handleClickPauseAndPlay}
                />
            default:
                return null;
        }
    };

    render() {
        const { clickedComment } = this.props;
        const { fullSliderPopup, currentSlideIndex } = this.state;
        const currentSlide = this.state.medias[currentSlideIndex];
        return (
            <div>
                <Modal
                    show={fullSliderPopup}
                    onClose={this.handleClose}
                    className="comment-full-slider-popup"
                >
                    <Modal.Body
                        className=""
                        style={{ overflow: 'inherit', flex: 'none' }}
                        onClick={(e) => e.stopPropagation()}
                        key={currentSlide.id}
                    >
                        <div className="flex flex-col items-center justify-center">
                            <div className="comment-full-slider-block">
                                <span
                                    className="full-slider-close-icon"
                                    onClick={() => {
                                        this.handleClose();
                                        this.props.changeValue(!this.props.changeCommentHeight);
                                    }}
                                ></span>
                                <h4
                                    className="text-xl font-medium"
                                    id="comment-full-slider-heading"
                                >
                                    {!MiscFunctions.isNull(currentSlide.filename) ? currentSlide.filename : currentSlide.description.split("/").length > 1
                                    ? currentSlide.description.split("/")[1]
                                    : currentSlide.description }
                                </h4>
                                <div className="slider-content mb-4 relative">
                                    {this.renderSlide(currentSlide)}
                                    <button
                                        onClick={this.handlePrev}
                                        className="absolute left-0 top-1/2 rounded-full full-slider-prev-icon"
                                    >
                                    </button>
                                    <button
                                        onClick={this.handleNext}
                                        className="absolute right-0 top-1/2 rounded-full full-slider-next-icon"
                                    >
                                    </button>
                                </div>
                                <div className="flex overflow-x-auto full-slider-thumb-nail-block">
                                    <div className='slider-thumbs-block'>
                                        {this.state.medias.map((media, index) => (
                                                <img
                                                    key={media.id}
                                                    src={media.thumbnailUrl}
                                                    alt={media.description}
                                                    className={`full-slider-thumb-nail ${index === currentSlideIndex ? 'border-2 border-blue-500' : ''}`}
                                                    onClick={() => this.handleThumbnailClick(index)}
                                                />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(CommentFileFullSlider);
