/**
 *
 * @file TimeStampServices.js
 * @created_date Monday, November 13, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This class is for handling of the TimeStamp-Objects, it encapsulates various functions for handling and retrieving information related to single comment objects.
 * @module TimeStampServices
 *
 **/

import { MiscFunctions } from 'helper-functions-package';

 class TimeStamp {
   constructor(json) {
     Object.keys(json).map((key) => {
       this[key] = json[key];
     });
   }

   //
  /**
   * @description Getter-Function to retrieve the ID of the object.
   * @return {string} ID of the comment
   **/
  get getID() {
    return this.id;
  }

  //
  /**
   * @description Getter-Function to retrieve the timestamp. Returns the actual timestamp of the object as a string (format: HH:MM:SS).
   * @return {string}.
   */
   get getTimeStampString() {
    return this.starttime;
  }

  //
  /**
   * @description Getter-Function to retrieve the timestamp. Returns the actual timestamp of the object in seconds.
   * @return {number}.
   */
  get getTimeStampSeconds() {
    return MiscFunctions.DateString2Seconds(this.getTimeStampString);
  }



}

export default TimeStamp;
