// VideoPlayer.js
import React, { Component } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      progress: 0,
      volume: 1,
      setVerticalVolume: false,
      setPlayBtn: false,
      setGripPoint: false,
      audioActive: false,
      commentSettingAutoPlay: this.props.commentSettingAutoPlay,
      clickPauseAndPlay: this.props.clickPauseAndPlay,
      url: this.props.url
    };
    this.progressBarRef = React.createRef();
  }

  componentDidMount() {
    this.player = videojs(this.videoNode, {
      controls: false,
      autoplay: this.props.commentSettingAutoPlay,
      loop: this.props.commentSettingAutoPlay,
      responsive: true,
      fluid: true,
      preload: "auto",
      sources: [
        {
          src: this.state.url,
          type: "video/mp4",
        },
      ],
    });

    this.player.on("play", () => {
      this.setState({ playing: true });
    });

    this.player.on("pause", () => {
      this.setState({ playing: false });
    });

    this.player.on("timeupdate", () => {
      const progress =
        (this.player.currentTime() / this.player.duration()) * 100;
      this.setState({ progress });
    });

    this.player.on("volumechange", () => {
      this.setState({ volume: this.player.volume() });
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  componentDidUpdate() {
    if(this.props.url !== this.state.url){
      this.setState({url: this.props.url})
    }
    if(this.props.clickPauseAndPlay !== this.state.clickPauseAndPlay){
      this.setState({clickPauseAndPlay: this.props.clickPauseAndPlay})
    }
    if (
      this.props.commentSettingAutoPlay &&
      this.props.index === this.props.fileIndex &&
      this.props.clickPauseAndPlay === false
    ) {
      if (this.player.paused()) {
        this.player.play();
      }
    } else {
      if(this.props.pause){
        if (!this.player.paused()) {
          this.player.pause();
        }  
      }
    }
  }

  handlePlayPause = () => {
    this.props.handleClickPauseAndPlay(true);
    if (this.player.paused()) {
      this.player.play();
    } else {
      this.player.pause();
    }
  };

  handleProgressBarClick = (event) => {
    const progressBar = this.progressBarRef.current;
    const rect = progressBar.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const newTime =
      (offsetX / progressBar.offsetWidth) * this.player.duration();
    this.player.currentTime(newTime);
  };

  handleIndicatorDrag = (event) => {
    const progressBar = this.progressBarRef.current;
    const rect = progressBar.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const newTime =
      (offsetX / progressBar.offsetWidth) * this.player.duration();
    this.player.currentTime(newTime);
  };

  handleVolume = (event) => {
    event.preventDefault();
    this.player.volume(event.target.value);
    this.setState({ volume: event.target.value });
  };

  render() {
    const { playing, progress } = this.state;
    return (
      <div className="video-player-uploaded">
        <div data-vjs-player>
          <video
            ref={(node) => (this.videoNode = node)}
            className="video-js vjs-default-skin"
          ></video>
        </div>

        <div className="custom-controls">
          <div className="custom-video-player" onClick={this.handlePlayPause}>
            {!playing && (
              <div
                className="play-button"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.setVideoPause(false);
                }}
              >
                {this.props.size === "small" ? (
                  <span className="play-button-icon-small"></span>
                ) : (
                  <span className="play-button-icon-big"></span>
                )}
              </div>
            )}
          </div>
          <div
            className="custom-controlbar"
            onMouseOver={() => {
              this.setState({ setPlayBtn: true });
            }}
            onMouseLeave={() => {
              this.setState({ setPlayBtn: false });
            }}
          >
            <div
              className="vertical-audio-volume"
              onMouseLeave={() => {
                this.setState({ setVerticalVolume: false, audioActive: false });
              }}
            >
              {this.state.setVerticalVolume && (
                <input
                  className="custom-audio-range"
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={`${this.state.volume}`}
                  orient="vertical"
                  onChange={this.handleVolume}
                />
              )}
            </div>
            {this.state.setPlayBtn && (
              <div
                className="comment_card_audio_play_wrapper"
                onClick={() => {
                  this.handlePlayPause();
                }}
              >
                <span className="comment_file_play_icon"></span>
              </div>
            )}
            <div
              className="controls-progree-audio"
              onMouseOver={() => {
                this.setState({ setGripPoint: true });
              }}
              onMouseLeave={() => {
                this.setState({ setGripPoint: false });
              }}
            >
              <div
                className="progress-bar"
                ref={this.progressBarRef}
                onClick={this.handleProgressBarClick}
                onMouseMove={(event) => {
                  if (event.buttons === 1) this.handleIndicatorDrag(event);
                }}
                onMouseDown={this.handleIndicatorDrag}
              >
                <div
                  className="progress"
                  style={{ width: `${progress}%` }}
                ></div>
                {this.state.setGripPoint && (
                  <div
                    className="progress-indicator"
                    style={{ left: `${progress}%` }}
                  ></div>
                )}
              </div>
            </div>
            <div className="comment_card_audio_wrapper">
              <span
                className={`${
                  this.state.audioActive
                    ? "comment_card_audio_icon_active"
                    : "comment_card_audio_icon"
                }`}
                onClick={() => {
                  this.setState({
                    setVerticalVolume: !this.state.setVerticalVolume,
                    audioActive: !this.state.audioActive,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
