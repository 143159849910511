/**
 * @file StringInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This component encapsulates a simple text input field. It receives properties such as a label, current value, and a function to handle value changes. The component employs state management for potential future expansions. It features an input field with responsive styling, ensuring a consistent look in both light and dark modes. The `StringInput` component is designed for reusability and serves as a foundational building block for capturing textual input within various contexts.
 *<br/>Breakdown of the code - {@tutorial StringInput}
 * @module StringInput
 *
 **/

import React from "react";

class StringInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
    };
  }

  onClickOutsideListener = () => {};

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.Reset !== this.props.Reset) {
      this.setState({ selectedElement: null });
    }
  }

  render() {
    return (
      <div className="input_group_item">
        <label
          className={
            "block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
          }
        >
          {this.props.label}
        </label>
        <input
          type="text"
          value={this.props.value}
          onChange={(e) => this.props.setValue(e.target.value)}
          id="ProcessName"
          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          required
        />
      </div>
    );
  }
}

export default StringInput;
