/**
 * @file ActionsMenuCustom.js
 * @created_date Thursday, Feb 12, 2024
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component represents a dropdown menu with an associated button. It manages the visibility of the menu, the selected option, and provides callbacks for handling option clicks. The component receives options and pre-selection information as props.
 *<br/>Breakdown of the code - {@tutorial ActionsMenuCustom}
 * @module ActionsMenuCustom
 *
 **/

import React, { Component } from "react";
import { MiscFunctions } from 'helper-functions-package';

class ActionsMenuCustom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavVisible: false,
      selectedOption: this.props.preSelection,
      state: false
    };

    this.dropdownRef = React.createRef();
  }

  toggleNav = () => {
    this.setState({ isNavVisible: !this.state.isNavVisible });
  };

  handleOptionClick = (option) => {
    this.setState({ selectedOption: option });
    this.props.onClick(option);
    this.toggleNav();
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownRef &&
      !this.dropdownRef.current.contains(event.target) &&
      this.state.isNavVisible
    ) {
      this.setState({ isNavVisible: false });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.options !== this.props.options ||
      prevProps.preSelection !== this.props.preSelection
    ) {
      this.setState({ selectedOption: this.props.preSelection });
    }
  }

  getColor(color) {
    return color === 'condenced-dark' ? '#4baaac' :
      color === 'condenced-light' ? '#aceeef' :
        color === 'primary' ? '#F2F2F2' :
          color === 'white' ? '#FFF' :
            color === 'black' ? '#000' :
              color === 'transparent' ? 'transparent' : '#FFF'
  }
  getSize(size) {
    return this.props.dropDownSize === 's' ? 22 : this.props.dropDownSize === 'm' ? 34 : this.props.dropDownSize === 'l' ? 48 : 34
  }

  customStyle = {
    size: this.getSize(this.props.dropDownSize),

    dropDownBackgroundColor: this.getColor(this.props.dropDownBG),
    dropDownRadius: this.props.dropDownRadius,
    iconBackgroundColor: this.getColor(this.props.dropDownIconBG),

    boxBackgroundColor: this.getColor(this.props.dropDownBoxBG),
    boxRadius: `0 0 ${this.props.dropDownBoxRadius}px ${this.props.dropDownBoxRadius}px`,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    staticIcon: this.props.dropDownStaticIcon !== false ? this.props.dropDownStaticIcon : false,
  }
  render() {
    return (
      <div className="drop-down-menu-with-icon" ref={this.dropdownRef}>
        <button className="drop-down-menu-btn" onClick={this.toggleNav} style={{
          height: this.customStyle.size,
          backgroundColor: this.state.hovered && this.props.hoverBoxBG ? this.getColor(this.props.hoverBoxBG) : this.customStyle.dropDownBackgroundColor,
          boxShadow: this.props.dropDownShadow === true ? this.customStyle.boxShadow : 'none',
          borderRadius: this.customStyle.dropDownRadius,
          padding: '5px 10px 5px 0',
          minWidth: '65px'
        }}
          onMouseOver={() => {
            this.setState({ hovered: true }); // Set a state variable to indicate mouse hover
          }}
          onMouseOut={() => {
            this.setState({ hovered: false }); // Set the state variable back to false when mouse leaves
          }}
        >
          {(this.props.optionIcon !== false || this.customStyle.staticIcon !== false) && <span className={`${this.props.optionIcon === true ? this.state.selectedOption.icon : this.customStyle.staticIcon} drop-down-menu-icon-bg`}
            style={{
              height: this.customStyle.size,
              width: this.customStyle.size,
              backgroundColor: this.customStyle.iconBackgroundColor,
            }}></span>}
          {this.props.optionLabel !== false && <span className="drop-down-menu-text" style={{ color: this.getColor(this.props.dropDownSelectedTextColor) }}>
            {this.state.selectedOption.label}
          </span>}
          {this.props.dropDownArrow && <span className="drop-down-menu-icon-arrow"></span>}
        </button>
        {
          this.state.isNavVisible && (
            <nav className="drop-down-options" style={{
              translate: `0 ${this.customStyle.size}px`,
              top: 'auto',
              boxShadow: this.props.dropDownBoxshadow ? this.customStyle.boxShadow : 'none',
              borderRadius: this.customStyle.boxRadius,
              backgroundColor: this.props.dropDownBoxBG === 'primary' || this.props.dropDownBoxBG === 'white' || this.props.dropDownBoxBG === 'transparent' ? this.customStyle.boxBackgroundColor : 'f3ffff',
              paddingBottom: '6px'
            }}
              onMouseOver={(e) => {
                // console.log(e)
              }}
            >
              {!MiscFunctions.isNull(this.props.options) &&
                this.props.options.map((option, index) => {
                  let selectedOption = option.label === this.state.selectedOption.label ? 'drop-down-menu-selected' : '';
                  return < li
                    className={this.props.dropDownBoxBG === "primary" || this.props.dropDownBoxBG === "white" ? "drop-down-option drop-down-option-primary" : "drop-down-option"}
                    key={index}
                    onClick={() => this.handleOptionClick(option)}
                  // style={{ justifyContent: this.props.justifyContent }}
                  >
                    {this.props.optionIcon !== false && <span className={'drop-down-menu-icon ' + option.icon}></span>} {this.props.optionLabel !== false && <span className={selectedOption}>{option.label}</span>}
                  </li>
                })}
            </nav>
          )
        }
      </ div >
    );
  }
}

export default ActionsMenuCustom;
