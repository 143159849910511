/**
 *
 * @file authAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to user authentication and registration for the application, These functions are organized within the authAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial authAPI}
 * @module authAPI
 *
 **/

import {
  getAuthAccessObject,
  removeAuthAccessObject,
  setAuthAccessObject,
} from "../utils/tokenStorage";
import { apiClient } from 'my-api-client-package';
const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * @description This function is used for user registration. It sends a POST request to the /user/registration endpoint with the user's details, including the reCAPTCHA value. If the registration is successful, it returns the response status code. Otherwise, it logs the response object.
 * <br />Here you can find some examples: - {@tutorial authAPI}
 * @function registerUser
 * @return {void}
 * */
const registerUser = async (
  captchaValue,
  signupName,
  signupEmail,
  signupPasswordConfirm,
  isSwitchRoleUser
) => {
  const bodyData = {
    firstname: signupName,
    lastname: "",
    email: signupEmail,
    group: "",
    password: signupPasswordConfirm,
    role: isSwitchRoleUser,
    payment: "",
  };

  try {
    const customHeaders = {
      recaptcha: captchaValue,
    };
    return await apiClient
      .post(API_BASE_URL, `/user/registration`, bodyData, customHeaders)
      .then(function (response) {
        if (response && response.status === 200) {
          return response.status;
        } else if (response && response.status !== 200) {
          console.log(response);
        }
      });
  } catch (err) {
    console.log("err: ", err);
    return err;
  }
};

/**
 * @description This function is used for user login. It takes the reCAPTCHA value, login email, and login password as parameters. It makes a `POST` request to the `/user/token` endpoint to authenticate the user and obtain an access token. The function returns a Promise that resolves to an object containing the access token and the response status.
 * <br />Here you can find some examples: - {@tutorial authAPI}
 * @function loginUser
 * @return {void}
 * */
const loginUser = async (captchaValue, loginEmail, loginPassword) => {
  const bodyData = {
    username: loginEmail,
    userpassword: loginPassword,
  };

  try {
    const customHeaders = {
      recaptcha: captchaValue,
    };
    return await apiClient
      .post(API_BASE_URL, `/user/token`, bodyData, customHeaders)
      .then(async (data) => {
        let resJson = await data.json();
        let result = {
          token: resJson.message,
          recaptcha: captchaValue,
        };
        return result;
      });
  } catch (err) {
    console.log(err)
  }
};

/**
 * @description This function sends a GET request to the /user/refresh endpoint with the access token in the Authorization header. It is used to refresh the access token when it expires. If the request is successful, it updates the access token in the token storage.
 * <br />Here you can find some examples: - {@tutorial authAPI}
 * @function refreshToken
 * @return {void}
 * */
const refreshToken = async (accessToken) => {
  const customHeaders = {
    Authorization: `Bearer ${accessToken}`,
  };
  try {
    return await apiClient
      .get(API_BASE_URL, `/user/refresh`, customHeaders)
      .then(async function (res) {
        let resJson = await res.json();
        if ("error" in resJson) {
          console.log(resJson)
          return []
        }
        else {
          setAuthAccessObject(
            JSON.stringify(Object.assign(getAuthAccessObject(), { token: resJson.message }))
          );
        }

      });
  } catch (err) {
    console.log("Error: ", err);
  }
};

/**
 * @description This function checks if an email already exists in the system. It sends a GET request to the /user/registration/:email endpoint with the email parameter. If the email exists, it returns the response status code. Otherwise, it logs the response object.
 * <br />Here you can find some examples: - {@tutorial authAPI}
 * @function checkIfEmailExist
 * @return {void}
 * */
const checkIfEmailExist = async (email) => {
  try {
    return await apiClient
      .get(API_BASE_URL, `/user/registration/${email}`)
      .then(function (response) {
        if (response && response.status === 200) {
          return response.status;
        } else if (response && response.status !== 200) {
          console.log(response);
        }
      });
  } catch (err) {
    return err;
  }
};

/**
 * @description This function sends a POST request to the /user/password/:email endpoint to request a password reset for a given email. It includes the reCAPTCHA value in the headers. It returns the response object.
 * <br />Here you can find some examples: - {@tutorial authAPI}
 * @function requestResetPassword
 * @return {void}
 * */
const requestResetPassword = async (email, captchaValue) => {
  console.log("captchaValue: ", captchaValue);
  try {
    const customHeaders = {
      recaptcha: captchaValue,
    };
    return await apiClient
      .post(API_BASE_URL, `/user/password/${email}`, "", customHeaders)
      .then((response) => {
        return response;
      });
  } catch (e) {
    console.error(e);
  }
};

/**
 * @description This function sends a GET request to the /user/password/:email/:newPassword endpoint to reset the user's password. It returns the response object.
 * <br />Here you can find some examples: - {@tutorial authAPI}
 * @function resetPassword
 * @return {void}
 * */
const resetPassword = async (token, newPassword) => {
  newPassword  = encodeURIComponent(newPassword)
  try {
    return await apiClient
      .get(API_BASE_URL, `/user/reset/password/${token}?newPassword=${newPassword}`)
      .then((response) => {
        return response;
      });
  } catch (e) {
    console.error(e);
  }
};

/**
 * @description This function removes the access token from the token storage and redirects the user to the landing page.
 * <br />Here you can find some examples: - {@tutorial authAPI}
 * @function logout
 * @return {void}
 * */
const logout = () => {
  removeAuthAccessObject();
  window.location.href = "/landing_page";
};

const authAPI = {
  registerUser,
  loginUser,
  refreshToken,
  checkIfEmailExist,
  requestResetPassword,
  resetPassword,
  logout,
};

export default authAPI;
