/**
 *
 * @file RegisterVerify.js
 * @created_date Thursday, December 15, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The RegisterVerify component renders a modal content for the email verification process during user registration.
 * <br />Here's what it does: - {@tutorial RegisterVerify}
 * @module RegisterVerify
 **/

import React from "react";
import horzintalLine from "../assets/img/normal_line.svg";
import { useTranslation } from "react-i18next";

function RegisterVerify(props) {
  const { t } = useTranslation();

  return (
    <>
      <h4
        className="text-xl font-medium text-gray-900 dark:text-white text-center"
        id="label_login_header"
      >
        {t('login_register_page.verification')}
      </h4>
      <h5
        className="text-xl font-medium text-gray-900 dark:text-white text-center"
        id="label_sign-up_description"
      >
        {t('login_register_page.verify_now_your')}{" "}medi
        <span className="boldColored">MESH</span>{" "}
        <span className="boldOnly">{t('general.account')}.</span>
      </h5>
      <div className="verify_modal_content">
        <p id="label_verfication_state-description">
          {t('login_register_page.you_have_entered')}{" "} <span className="boldColored">{props.signupEmail}</span>{" "}{t('login_register_page.as_the_email')}
        </p>

        <p id="label_verficate-user-account-call-to-action">
          {t('login_register_page.we_have_sent_you_email')}
        </p>
        <img src={horzintalLine} alt="" />
        <p id="label_resend-verification-email">
          {t('login_register_page.if_not_received_email')}
        </p>
        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="w-full text-white bg-hightlight-700 hover:bg-hightlight-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700 dark:focus:ring-hightlight-800 button_resend_verification_email"
          >
            {t('login_register_page.resend_email')}
          </button>
        </div>
      </div>
    </>
  );
}

export default RegisterVerify;
