import React from "react";
import ActionsMenuCustom from "../Misc/ActionsMenuCustom";
import { withTranslation } from "react-i18next";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { MiscFunctions } from 'helper-functions-package';
class Language extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOptions: [
        { label: "English", tag: "en", icon: "drop-down-menu-icon-eng" },
        { label: "German", tag: "de", icon: "drop-down-menu-icon-de" },
        { label: "Japanese", tag: "ja", icon: "drop-down-menu-icon-jp" },
      ],
    };
  }

  render() {
    return (
      <div className="settings-language-section">
        <div className="settings-feild-label">
          {this.props.t("general.selected_language")}
        </div>
        <ActionsMenuCustom
          options={this.state.sortOptions}
          preSelection={
            !MiscFunctions.isUndefined(localStorage.selected_language)
              ? JSON.parse(localStorage.selected_language)
              : this.state.sortOptions[0]
          }
          onClick={(e) => {
            MiscFunctionsLocal.changeLanguage(e);
            this.props.i18n.changeLanguage(e.tag);
            localStorage.setItem("selected_language", JSON.stringify(e));
          }}
          dropDownSize={"l"} //s, m, l
          optionIcon={true} // true will load icons from the options and override the dropDownStaticIcon.
          optionLabel={true}
          dropDownStaticIcon={false} //icon class e.g drop-down-menu-icon-sort, false to no icon.
          dropDownIconBG={"transparent"} //condence-dark, condenced-light, primary, white, transparent.
          dropDownShadow={false}
          dropDownBG={"transparent"} //condenced-dark, condenced-light, primary, white, transparent.
          dropDownRadius={2}
          dropDownBoxBG={"white"} //condenced-dark, condenced-light, primary, white, transparent.
          dropDownBoxshadow={true}
          dropDownBoxRadius={0}
          dropDownArrow={true}
          dropDownSelectedTextColor={"condenced-dark"} //condenced-dark, condenced-light, primary, white, transparent.
          hoverBoxBG={"primary"} //condenced-dark, condenced-light, primary, white, transparent.
        />

        <span className="settings-separator"></span>
      </div>
    );
  }
}
export default withTranslation()(Language);
