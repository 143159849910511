/**
 * @file RessourceObject_PersonGroupInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description The `RessourceObject_PersonGroupInput` component is a dynamic form facilitating the input and submission of person group details in the application. It includes fields for the group's name and description with character limits, and it communicates with the server via `persongroupAPI.postPersonGroup` for real-time submission feedback.
 *<br/>Breakdown of the code - {@tutorial RessourceObject_PersonGroupInput}
 * @module RessourceObject_PersonGroupInput
 *
 **/

import React from "react";
import persongroupAPI from "../../api/persongroupAPI";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";
import StringInput from "./StringInput";
import TextInput from "./TextInput";
import { withTranslation } from "react-i18next";

class RessourceObject_PersonGroupInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      name: "",
      description: "",
      sendSuccess: null,
      saveData: false,
    };
  }

  handleDescription = (string) => {
    console.log(string);
    this.setState({ description: string });
  };
  handleName = (string) => {
    this.setState({ name: string });
  };

  handleSendSuccess = (e) => {
    this.setState({ sendSuccess: e });
  };

  handleSubmit = async () => {
    this.setState({ saveData: true });
    let data = {
      label: this.state.name,
      description: this.state.description,
    };

    const res = persongroupAPI.postPersonGroup(this.props.captchaValue, data);
    res
      .then((data) => {
        console.log(data.status);
        if (data.status === 200 || data.status === 201) {
          this.setState({
            saveData: false,
          });
          this.handleDescription("");
          this.handleName("");
          this.handleSendSuccess(true);
        } else {
          this.handleSendSuccess(false);
        }
      })
      .then(async (response) => {
        if (response && response.status === 201) {
          // let resJson = await response.text();
          // let result = { response: JSON.parse(resJson), status: response.status }
          return response.status;
        } else if (response && response.status !== 201) {
          console.log(response);
        }
      });
  };

  onClickOutsideListener = () => { };

  componentWillUnmount() { }

  render() {
    return (
      <div>
        <StringInput
          label={this.props.t('general.name')}
          setValue={this.handleName}
          value={this.state.name}
        />
        {/* <ListInput 
                label="Room-Template"
                Elements={this.RoomTemplates.Elements}
                selectHandler={this.handleRoom}
            /> */}
        <TextInput
          label={this.props.t('general.description')}
          rows="9"
          setValue={this.handleDescription}
          value={this.state.description}
        />
        <button
          onClick={() => {
            this.handleSubmit();
          }}
          className="content-admin-save-btn"
          disabled={!this.state.name || !this.state.description ? true : false}
        >
          {this.props.t('general.enter')}
        </button>
        {this.state.sendSuccess === true && (
          <CheckBadgeIcon className="h-6 w-6" />
        )}
        {this.state.sendSuccess === false && (
          <XCircleIcon className="h-6 w-6" />
        )}
      </div>
    );
  }
}

export default withTranslation()(RessourceObject_PersonGroupInput);
