import { Modal, Spinner } from "flowbite-react";
import React from "react";
import { withTranslation } from "react-i18next";

import DeleteAccountForm from "./DeleteAccountForm";
class DeleteAccount extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            confirmPopup: false,
        }
    }

    handleSubmit = () => {
        this.setState({ confirmPopup: true })
    }
    handleClose = () => {
        this.setState({ confirmPopup: false })
    }
    render() {
        return (

            <div className="delete_account_section">
                <div className="delete_account_message_block">
                    <span className="delete_account_message">{this.props.t('messages.if_delete_account')}</span>
                </div>
                <div className="delete_account_delete_button_block">
                    <button
                        className="delete_account_delete_button"
                        disabled={false}
                        onClick={this.handleSubmit}
                    >
                        {this.props.t('general.delete_account')}
                    </button>
                </div>

                <Modal
                    show={this.state.confirmPopup}
                    className="register_main_modal"
                    onClick={() => this.setState({ confirmPopup: false })}
                    style={{ overflow: "inherit", flex: "none" }}
                    onClose={() => {
                        this.setState({ confirmPopup: false })
                    }}
                >
                    <Modal.Body
                        className="reset_password_popup_body"
                        style={{ overflow: "inherit", flex: "none" }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <DeleteAccountForm handleInputFeilds={this.handleInputFeilds} handleClose={this.handleClose} />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}
export default withTranslation()(DeleteAccount)