import React, { Component } from "react";
import userAPI from "../../api/userAPI";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";

class ToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: this.props.isActive,
      value: this.props.value,
      userObject: this.props.userObject
    };
  }

  toggleButton = async () => {
    const data = await userAPI.setPreference(this.props.value);
    this.props.hotRefreshUserObject(data);
    this.checkValue(MiscFunctionsLocal.createUserObject(data));
  };

  checkValue = (data) => {
    if (data.preferences.includes(this.props.value)) {
      this.setState({
        isActive: true,
      })
    }else{
      this.setState({
        isActive: false,
      })
    }
  }
  render() {
    const { isActive } = this.state;
    return (
      <div
        className={`toggle-button ${isActive ? "active" : ""}`}
        onClick={this.toggleButton}
      >
        <div className="handler"></div>
      </div>
    );
  }
}

export default ToggleButton;
