import React, { useEffect, useState } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import { getAuthAccessObject } from "../../utils/tokenStorage";
import mediaAPI from "../../api/mediaAPI";
import DOClient from "../../utils/DOClient";

function AudioRecorder(props) {
  const recorderControls = useVoiceVisualizer({
    onEndAudioPlayback: () => {
      setAudioPlaying(false);
      setAudioDuration(true);
    },
  });

  const [audioDuration, setAudioDuration] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [edit, setEdit] = useState(false);
  const [record, setRecord] = useState(false);
  const [duration, setDuration] = useState("");
  let time = [];
  const [description, setDescription] = useState("");
  const [formatTime, setFormatTime] = useState("");

  const {
    recordedBlob,
    error,
    startRecording,
    stopRecording,
    togglePauseResume,
    clearCanvas,
  } = recorderControls;

  const uploadAudio = async () => {
    // Specify the file name and options for the File
    // const fileName = "example.webm";
    const options = { type: 'audio/webm;codecs=opus', lastModified: new Date() };
    const timestamp = Date.now();
    const fileName = `${getAuthAccessObject().ID}-${timestamp}${'.webm'}`;
    const privateFileName = `${getAuthAccessObject().ID}/${getAuthAccessObject().ID}-${timestamp}${'.webm'}`;
    const file = new File([recordedBlob], fileName, options);

    const res = await mediaAPI.getUploadLink(
      fileName,
      description
    );
    const media = await DOClient.put(res.message, file, description);
    const mediaObject = await mediaAPI.getMediaObject(
      privateFileName,
      res.description
    )
    if (mediaObject !== undefined) {
      props.handleRecordedAudio(mediaObject.id)
    }
  }
  useEffect(() => {
    if (!recordedBlob) return;
    uploadAudio();
    // console.log(recordedBlob);
  }, [recordedBlob, error]);

  // Get the error when it occurs
  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  const handleRecordClick = () => {
    setRecord(!record);
    startRecording();
  };

  const timer = process.env.REACT_APP_RECORDED_AUDIO_LIMIT;
  useEffect(() => {
    stopRecording();
  }, [recorderControls.recordingTime > timer])

  const handleStopClick = () => {
    setRecord(!record);
    setEdit(!edit);
    stopRecording();
  };

  const handleFormatTime = () => {
    if (recorderControls.bufferFromRecordedBlob !== null) {
      const duration = parseInt(recorderControls.bufferFromRecordedBlob.duration);
      const minutes = Math.floor(duration / 60);
      const seconds = duration % 60;
      const format = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      setFormatTime(format);
    }
  };

  const handlePlayClick = () => {
    setAudioPlaying(!audioPlaying);
    setAudioDuration(false);
    togglePauseResume();
  };

  const cancelCanvas = () => {
    setEdit(!edit);
    clearCanvas();
  };

  useEffect(() => {
    if (recorderControls.formattedDuration.includes("s")) {
      time = recorderControls.formattedDuration.split("s");
    } else if (recorderControls.formattedDuration.includes("m")) {
      time = recorderControls.formattedDuration.split("m");
    }
    setDuration(time[0]);
    handleFormatTime();
  }, [recorderControls]);

  const r = record ? "record" : " ";
  const e = edit ? "edit" : " ";

  return (
    <>
      <div className={r + e}>
        {recorderControls.audioRef.current === null &&
          !recorderControls.isRecordingInProgress ? (
          <span
            className="icon_comment_audio"
            onClick={handleRecordClick}
          ></span>
        ) : null}
        {recorderControls.audioRef.current !== null ? (
          <span
            className={`${audioPlaying
              ? "icon_comment_audio_pause"
              : "icon_comment_audio_play"
              }`}
            onClick={() => {
              handlePlayClick();
            }}
          ></span>
        ) : null}
        {recorderControls.isRecordingInProgress ? (
          <span
            className="icon_comment_audio_stop"
            onClick={handleStopClick}
          ></span>
        ) : null}
        {recorderControls.isAvailableRecordedAudio ||
          recorderControls.audioRef.current !== null ? (
          <span className="icon_pause"></span>
        ) : null}
        <VoiceVisualizer
          controls={recorderControls}
          isDefaultUIShown={false}
          isControlPanelShown={false}
          ref={recorderControls.audioRef}
          height={25}
          width={300}
          barWidth={1}
          rounded={2}
          fullscreen={false}
          mainBarColor="#4BAAAC"
          secondaryBarColor="#ADB5BD"
          defaultAudioWaveIconColor="#ADB5BD"
          defaultMicrophoneIconColor="#ADB5BD"
          isProgressIndicatorShown={true}
          isProgressIndicatorTimeShown={false}
          isProgressIndicatorOnHoverShown={true}
          isProgressIndicatorTimeOnHoverShown={true}
        />
        {recorderControls.isRecordingInProgress ? (
          <div className="audio_recording">
            <span className="comment_audio_timer">{recorderControls.formattedRecordingTime}</span>
            <span className="comment_audio_close" onClick={cancelCanvas}></span>
          </div>
        ) : null}
        {recorderControls.audioRef.current !== null ? (
          <div className="audio_playing">
            <div className="audio_duration">
              <span className="comment_audio_timer">
                {formatTime}
              </span>
              <span
                className="comment_audio_close"
                onClick={cancelCanvas}
              ></span>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default AudioRecorder;
